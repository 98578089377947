import img1 from './imgs/img1.jpg'

export const homedata = [
    {
        desc: "Kid’s Bouncy House Rentals -Downtown Serving All Areas",
        city: "Los Angeles ",
        img:  img1,
    },
    {
        desc: "Kid’s Bouncy House Rentals -Downtown Serving All Areas",
        city: "Los Angeles ",
        img:  img1,
    },
    {
        desc: "Kid’s Bouncy House Rentals -Downtown Serving All Areas",
        city: "Los Angeles ",
        img:  img1,
    },
]