import "../../App.css";
import { React, useEffect, useRef } from "react";

import srvc1 from './svg/serviceslider/1.svg'
import srvc2 from './svg/serviceslider/2.svg'
import srvc3 from './svg/serviceslider/3.svg'
import srvc4 from './svg/serviceslider/4.svg'
import srvc5 from './svg/serviceslider/5.svg'
import srvc6 from './svg/serviceslider/6.svg'
import srvc7 from './svg/serviceslider/7.svg'
import srvc8 from './svg/serviceslider/8.svg'
import srvc9 from './svg/serviceslider/9.svg'
import srvc10 from './svg/serviceslider/10.svg'
import srvc11 from './svg/serviceslider/11.svg'
import srvc12 from './svg/serviceslider/12.svg'
import srvc13 from './svg/serviceslider/13.svg'
import srvc14 from './svg/serviceslider/14.svg'

import evnt1 from './svg/eventslider/1.svg'
import evnt2 from './svg/eventslider/2.svg'
import evnt3 from './svg/eventslider/3.svg'
import evnt4 from './svg/eventslider/4.svg'
import evnt5 from './svg/eventslider/5.svg'
import evnt6 from './svg/eventslider/6.svg'
import evnt7 from './svg/eventslider/7.svg'
import evnt8 from './svg/eventslider/8.svg'
import evnt9 from './svg/eventslider/9.svg'
import evnt10 from './svg/eventslider/10.svg'
import evnt11 from './svg/eventslider/11.svg'
import evnt12 from './svg/eventslider/12.svg'
import evnt13 from './svg/eventslider/13.svg'
import evnt14 from './svg/eventslider/14.svg'
import evnt15 from './svg/eventslider/15.svg'
import evnt16 from './svg/eventslider/16.svg'
import evnt17 from './svg/eventslider/17.svg'
import evnt18 from './svg/eventslider/18.svg'
import evnt19 from './svg/eventslider/19.svg'
import evnt20 from './svg/eventslider/20.svg'
import evnt21 from './svg/eventslider/21.svg'

import vnue1 from './svg/venuesliders/1.svg'
import vnue2 from './svg/venuesliders/2.svg'
import vnue3 from './svg/venuesliders/3.svg'
import vnue4 from './svg/venuesliders/4.svg'
import vnue5 from './svg/venuesliders/5.svg'
import vnue6 from './svg/venuesliders/6.svg'
import vnue7 from './svg/venuesliders/7.svg'
import vnue8 from './svg/venuesliders/8.svg'
import vnue9 from './svg/venuesliders/9.svg'
import vnue10 from './svg/venuesliders/10.svg'
import vnue11 from './svg/venuesliders/11.svg'
import vnue12 from './svg/venuesliders/12.svg'
import vnue13 from './svg/venuesliders/13.svg'
import vnue14 from './svg/venuesliders/14.svg'
import vnue15 from './svg/venuesliders/15.svg'
import vnue16 from './svg/venuesliders/16.svg'
import vnue17 from './svg/venuesliders/17.svg'
import vnue18 from './svg/venuesliders/18.svg'

import { register } from "swiper/element/bundle";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import '../landingpagecomponent/carousel.css';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

register();

const dataevent = [
  {
    ind: 1,
    icon: evnt1,
    name: "Social  Gatherings",
  },

  {
    ind: 2,
    icon: evnt2,
    name: "Networking",
  },
  {
    ind: 3,
    icon: evnt3,
    name: "Concerts",
  },
  {
    ind: 4,
    icon: evnt4,
    name: "Wellness",
  },
  {
    ind: 5,
    icon: evnt5,
    name: "Pop-Ups",
  },
  { ind: 6, icon: evnt6, name: "Live Entertainment" },
  {
    ind: 7,
    icon: evnt7,
    name: "Conferences",
  },
  {
    ind: 8,
    icon: evnt8,
    name: "Flea Markets",
  },

  {
    ind: 9,
    icon: evnt9,
    name: "Classes and lessons",
  },
  {
    ind: 10,
    icon: evnt10,
    name: "Festivals",
  },
  {
    ind: 11,
    icon: evnt11,
    name: "Comedy Show",
  },
  {
    ind: 12,
    icon: evnt12,
    name: "Water Activities",
  },
  {
    ind: 13,
    icon: evnt13,
    name: "Local Fairs",
  },
  {
    ind: 14,
    icon: evnt14,
    name: "Spiritual",
  },
  {
    ind: 15,
    icon: evnt15,
    name: "Family Fun ",
  },
  {
    ind: 16,
    icon: evnt16,
    name: "For Babies",
  },
  {
    ind: 17,
    icon: evnt17,
    name: "Food ",
  },
  {
    ind: 18,
    icon: evnt18,
    name: "Fashion Shows",
  },
  {
    ind: 19,
    icon: evnt19,
    name: "Book Clubs",
  },

  {
    ind: 20,
    icon: evnt20,
    name: "Animals",
  },

  {
    ind: 21,
    icon: evnt21,
    name: "LGBTQ",
  },
];

const datavenue = [
  { ind: 1, name: "Nightclubs", icon: vnue1 },
  { ind: 2, name: "Pools", icon: vnue2 },
  { ind: 3, name: "Beach Front", icon: vnue3  },
  { ind: 4, name: "Commerical", icon: vnue4  },
  { ind: 5, name: "Unique", icon: vnue5  },
  { ind: 6, name: "Outdoor ", icon: vnue6 },
  { ind: 7, name: "Mansions", icon: vnue7 },
  { ind: 8, name: "Farms", icon: vnue8 },
  { ind: 8, name: "Roof Tops", icon: vnue9 },
  { ind: 9, name: "Lake Front  ", icon: vnue10 },
  { ind: 10, name: "Amazing Views", icon: vnue11 },
  { ind: 11, name: "Historical  ", icon: vnue12 },
  { ind: 12, name: "Yachts", icon: vnue13 },
  { ind: 13, name: "Hotels", icon: vnue14 },
  { ind: 14, name: "Trending", icon: vnue15 },
  { ind: 15, name: "Churches", icon: vnue16 },
  { ind: 16, name: "Off Grid", icon: vnue17 },
  { ind: 17, name: "Castles", icon: vnue18 },
];
const dataservice = [
  { ind: 1, name: "Entertainment", icon: srvc1 },
  { ind: 2, name: "Transportation", icon: srvc2 },
  { ind: 3, name: "Cleaning", icon: srvc3  },
  { ind: 4, name: "Photography and Video", icon: srvc4  },
  { ind: 5, name: "Dj’s", icon: srvc5  },
  { ind: 6, name: "Security", icon: srvc6 },
  { ind: 7, name: "Rentals", icon: srvc7 },
  { ind: 8, name: "For Kids", icon: srvc8 },
  { ind: 8, name: "Games and Activities", icon: srvc9 },
  { ind: 9, name: "For Babies ", icon: srvc10 },
  { ind: 10, name: "Audio and Visual equipment", icon: srvc11 },
  { ind: 11, name: "Bar Tenders", icon: srvc12 },
  { ind: 12, name: "+21 age", icon: srvc13 },
  { ind: 13, name: "Exotic", icon: srvc14 },
];

export const EventCarousel = ({ eventType }) => {
  const swiperElRef = useRef(null);

  let data = [];
  if (eventType === "events") {
    data = dataevent;
  } else if (eventType === "venues") {
    data = datavenue;
  } else if (eventType === "services") {
    data = dataservice;
  }

  // MEDIA QUERIES FOR DIFFERENT SCREENS;
  const slideLeft = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById('slider');
    slider.scrollLeft = slider.scrollLeft + 500;
  };
  return (
    <div className="relative flex items-center">
      <MdChevronLeft className='opacity-50 cursor-pointer hover:opacity-100 slider-btns' onClick={slideLeft} />
      <div id='slider' className="w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide media-scroller" >
        {data.map((item) => (
          <div className="media-element">
            <img src={item.icon} className="slick-slider-imgs" />
            <p>{item.name}</p>
          </div>
        ))}
      </div>
      <MdChevronRight className='opacity-50 cursor-pointer hover:opacity-100 slider-btns' onClick={slideRight} />
    </div>
  );
};