import React, { useState, useEffect } from 'react';
import entrlogo from "../img/entrlogoblack.svg";
import avatar from "../img/Avatar.svg";
import { useStateValue } from "../context/StateProvider";
import { CalendarToday, Chat, Person, PersonAdd } from '@mui/icons-material';
import { actionType } from "../context/reducer";
import { useNavigate } from "react-router-dom";

const NavbarServiceDashboard = () => {

    const [isMenu, setIsMenu] = useState(false);
    const [isUserMenu, setIsUserMenu] = useState(false);
    const [isNav, setIsNav] = useState(false);
    const navigate = useNavigate();
    const [{ user }, dispatch] = useStateValue();

    const [serviceProvider, setServiceProvider] = useState([]);

    const menu = () => {
        if (window.innerWidth < 800) {
            setIsNav(!isNav);
        }
    }
    const userlogin = () => {
        setIsUserMenu(!isUserMenu);
        // }
    };
    const login = () => {
        setIsMenu(!isMenu);
        // }
    };

    const logout = () => {
        setIsUserMenu(false);
        localStorage.clear();
        navigate("/servicelandingpage");
        dispatch({
            type: actionType.SET_USER,
            user: null,
        });
        //window.location.reload(true);
    };

    const [isevent, setisevent] = useState(true);

    const event = () => {
        setisevent(!isevent)
    }

    useEffect(() => {
        const sP = localStorage.getItem("serviceprovider");
        if (sP) {
            setServiceProvider(JSON.parse(sP));
        }
        //console.log("USER MAN: ", user);
    }, []);



    return (
        <div className='pb-5'>

            <nav class="text-gray-900 border-gray-200 bg-white  z-50 w-full fixed">
                {isevent &&
                    <div id="sticky-banner" tabindex="-1" class=" left-0  flex justify-between w-full px-4 py-3  bg-[#FF8743]">
                        <div class="flex items-center mx-auto">
                            <p class="flex items-center text-md text-white font-semibold ">

                                <span>This is the Demo -    <a href="#" class="inline font-medium text-blue-600 underline dark:text-blue-500 underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline italic">We are launching soon</a></span>
                            </p>
                        </div>
                        <div class="flex items-center">
                            <button data-dismiss-target="#sticky-banner" onClick={() => event()} type="button" class="flex-shrink-0 inline-flex justify-center items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Close banner</span>
                            </button>
                        </div>
                    </div>
                }
                <div class="w-full flex flex-row items-center justify-between px-10">
                    <div className='flex flex-row'>
                        <a href="/servicelandingpage" class="flex items-center pb-1">
                            <img src={entrlogo} class="h-8 mr-3" alt="Flowbite Logo" />
                            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Entr</span>
                        </a>

                        <div class="items-center justify-between md:hidden sm:hidden w-full flex flex-row md:w-auto " id="mobile-menu-2">
                            {
                                serviceProvider && serviceProvider.email ?
                                    <ul class="flex md:flex-col font-medium p-4 md:p-0 ml-4  dark:border-gray-100 rounded-lg dark:bg-gray-50 flex-row md:space-x-8 md:mt-0 md:border-0 md:dark:bg-white ">
                                        <li>
                                            <a href="#" class="block py-2 pl-3 pr-4 text-gray-900
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] rounded md:bg-transparent  md:p-0 md:dark:text-gray-200" aria-current="page">Dashboard</a>
                                        </li>
                                        <li>
                                            <a href="#" class="block py-2 pl-3 pr-4 text-gray-900
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] rounded md:bg-transparent  md:p-0 md:dark:text-gray-200">Booking</a>
                                        </li>
                                        <li>
                                            <a href="#" class="block py-2 pl-3 pr-4 text-gray-900
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] rounded md:bg-transparent  md:p-0 md:dark:text-gray-200">My Service</a>
                                        </li>
                                        <li>
                                            <a href="#" class="block py-2 pl-3 pr-4 text-gray-900
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] rounded md:bg-transparent  md:p-0 md:dark:text-gray-200">Earnings</a>
                                        </li>
                                    </ul>
                                    :
                                    <ul class="flex md:flex-col font-medium p-4 md:p-0 ml-4  dark:border-gray-100 rounded-lg dark:bg-gray-50 flex-row md:space-x-8 md:mt-0 md:border-0 md:dark:bg-white ">
                                        <li>
                                            <a href="/findevent" class="block py-2 pl-4 pr-4 text-white hover:bg-gray-800 rounded md:bg-transparent  md:p-0 md:dark:text-gray-200" aria-current="page">Browse</a>
                                        </li>
                                        <li>
                                            <a href="/usersignin" class="block py-2 pl-4 pr-4 text-white rounded hover:bg-gray-800 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Host</a>
                                        </li>
                                        <li>
                                            <a href="/userlandingpage" class="block py-2 pl-4 pr-4 text-white rounded hover:bg-gray-800 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Features</a>
                                        </li>
                                        <li>
                                            <a href="/gopro" class="block py-2 pl-4 pr-4 text-white rounded hover:bg-gray-800 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Premium</a>
                                        </li>

                                    </ul>


                            }
                        </div>
                    </div>
                    <div class=" flex flex-row items-center py-2">
                        {serviceProvider && serviceProvider.email ? <div></div> :
                            <a href="/servicelandingpage" class=" px-4 py-2 sm:hidden md:hidden text-sm hover:text-gray-100 hover:underline dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Join as Service Provider</a>
                        }
                        {serviceProvider && serviceProvider.email ?
                            <div class=" flex flex-row gap-6 items-center ">
                                <div class=" flex flex-row gap-6 items-center border-r  border-gray-700">
                                    <div className='flex flex-row gap-6 sm:hidden'>
                                        <CalendarToday className='text-[#002FA8]' />
                                        <Chat className='text-[#002FA8] ' />
                                        <span className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                                <path d="M18 8.5C18 6.9087 17.3679 5.38258 16.2426 4.25736C15.1174 3.13214 13.5913 2.5 12 2.5C10.4087 2.5 8.88258 3.13214 7.75736 4.25736C6.63214 5.38258 6 6.9087 6 8.5C6 15.5 3 17.5 3 17.5H21C21 17.5 18 15.5 18 8.5Z" stroke="#5C5E60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M13.7295 21.5C13.5537 21.8031 13.3014 22.0547 12.9978 22.2295C12.6941 22.4044 12.3499 22.4965 11.9995 22.4965C11.6492 22.4965 11.3049 22.4044 11.0013 22.2295C10.6977 22.0547 10.4453 21.8031 10.2695 21.5" stroke="#5C5E60" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </div>
                                    <button type="button" onClick={userlogin} class="flex mr-5 sm:mr-5 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                                        <span class="sr-only">Open user menu</span>
                                        <img class="w-8 h-8 rounded-full" src={serviceProvider?.photoURL ? serviceProvider.photoURL :
                                            avatar} alt="user photo" />
                                    </button>
                                </div>
                                <p onClick={logout} className='text-gray-400 cursor-pointer font-inter'>Logout</p>
                            </div>
                            :
                            <button type="button" onClick={login} class="flex flex-row gap-2 p-2  justify-center text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">

                                <span class="sr-only">Open user menu</span>
                                <svg class="w-6 h-6  " aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>


                                <Person class="w-6 h-6  rounded-full text-white" fill="currentColor" alt="user photo" />

                            </button>
                        }

                        {isMenu && (
                            <div class={`absolute  ${isevent ? "top-28 sm:top-24" : "top-12 sm:top-9"}  right-2  sm:mr-9  mr-10 my-4 text-base list-none bg-gray-700 divide-y-0 sm:divide-y rounded-lg shadow dark:bg-gray-700 divide-gray-600" id="user-dropdown`}>
                                <ul class="py-2 hidden sm:flex sm:flex-col" aria-labelledby="user-menu-button">
                                    <li>
                                        <a href="/usersignin" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Host</a>
                                    </li>
                                    <li>
                                        <a href="/findevent" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Find Event</a>
                                    </li>
                                    <li>
                                        <a href="/userlandingpage" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Features</a>
                                    </li>
                                    <li>
                                        <a href="/gopro" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Premium</a>
                                    </li>
                                </ul>
                                <ul class="py-2" aria-labelledby="user-menu-button">
                                   
                                    <li>
                                        <a href="/usersignin" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign-up / Login</a>
                                    </li>

                                    <li>
                                        <a href="/servicelandingpage" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Become a Service Provider</a>
                                    </li>
                                    <li>
                                        <a href="/userfaq" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">FAQ</a>
                                    </li>
                                </ul>

                            </div>
                        )}


                        {
                            isUserMenu && (
                                <div className={`  absolute  ${isevent ? "top-28 sm:top-24" : "top-12 sm:top-9"}  right-2  mr-10 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600`} id="user-dropdown" style={{ minWidth: '230px' }}>
                                    <div className=" px-4 py-3 flex justify-between items-center ">
                                        <div className='mr-2'>
                                            <img class="w-8 h-8 rounded-full" src={serviceProvider?.photoURL ? serviceProvider.photoURL :
                                                avatar} alt="user photo" />
                                        </div>
                                        <div>
                                            {
                                                serviceProvider?.displayName ?
                                                    <>
                                                        <span class="block text-sm text-gray-900 ">{serviceProvider?.fname} {" "} {serviceProvider?.lname}</span>
                                                        <span class="block text-sm   truncatetext-gray-400">{serviceProvider?.email}</span>
                                                    </>

                                                    :

                                                    serviceProvider?.fname ?
                                                        <>
                                                            <span class="block text-sm text-gray-900 ">{serviceProvider?.fname} {" "} {serviceProvider?.lname}</span>
                                                            <span class="block text-sm   truncatetext-gray-400">{serviceProvider?.email}</span>

                                                        </>
                                                        :
                                                        <></>


                                            }
                                        </div>
                                    </div>
                                    <ul class="pl-2 pr-12 py-3 py-2" aria-labelledby="user-menu-button">
                                        <li>
                                            <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Edit Profile</p></a>
                                        </li>
                                        <li>
                                            <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Reviews</p></a>
                                        </li>
                                        <li>
                                            <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Manage Staff</p></a>
                                        </li>
                                        <li>
                                            <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Account Settings</p></a>
                                        </li>
                                        <li>
                                            <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"> <p> Support</p></a>
                                        </li>
                                    </ul>

                                    <ul class="py-2" aria-labelledby="user-menu-button">
                                        <li>
                                            <p onClick={logout} className="cursor-pointer flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                                            >Sign Out</p>
                                        </li>
                                    </ul>
                                </div>
                            )

                        }
                        <button data-collapse-toggle="mobile-menu-2" type="button" onClick={menu} class="md:hidden sm:hidden  items-center p-2 ml-1 text-sm text-gray-500 rounded-lg hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                            <span class="sr-only">Open main menu</span>
                            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                        </button>
                    </div>

                </div>

            </nav>
            {isNav && (
                <div class="items-center justify-between flex flex-col  w-full md:w-auto" id="mobile-menu-2">
                    <ul class="flex md:flex-col md:gap-4 text-center font-medium mb-5 p-4 md:p-0 ml-4  dark:border-gray-100 rounded-lg dark:bg-gray-50 flex-row  md:mt-0 md:border-0 md:dark:bg-white ">
                        <li>
                            <a href="/usersignin" class="py-2 pl-3 pr-4 text-white rounded border-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" aria-current="page">Host</a>
                        </li>
                        <li>
                            <a href="/findevent" class=" py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Find Events</a>
                        </li>
                        <li>
                            <a href="/userlandingpage" class=" py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Features</a>
                        </li>
                        <li>
                            <a href="/gopro" class=" py-2  pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Premium</a>
                        </li>

                    </ul>
                </div>
            )}
        </div>
    )
}

export default NavbarServiceDashboard;