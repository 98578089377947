import React, { useState, useEffect } from 'react';

const TimerComponent = ({ duration, onTimeout }) => {
  const [seconds, setSeconds] = useState(duration);

  useEffect(() => {
    // Decrease the seconds every second
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Trigger the onTimeout callback when the seconds reach 0
    if (seconds == 0) {
      onTimeout();
    }
  }, [seconds, onTimeout]);

  // Format the remaining seconds as mm:ss
  const formattedTime = `${Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`;

  return <div>{formattedTime}</div>;
};

export default TimerComponent;
