import React from 'react'
import Servicesignupcomponent from './Servicesignupcomponent'
import Stepper from "../components/multistep/Stepper";
import Servicesignup1 from './Servicesignup1';
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom"
const Servicesignupconfirmation = () => {
  const [currentStep, setCurrentStep] = useState(3);
  const navigate = useNavigate();
  const steps = [
    "Personal Info",
    "Account Info",
    "Confirmation",
  ];

  const signin = () => {
    navigate("/servicelandingpage/servicedashboard", { replace: true });
  }


  return (

    <div className=' w-full h-screen'>

      <section className="bg-gray-50  dark:bg-gray-900     ">
        <div className="lg:flex flex flex-row lg:flex-row md:flex-col h-screen   sm:flex-col ">
          <div className='flex flex-col   justify-center w-[50%]  xl:w-[50%] lg:w-[50%] md:hidden sm:hidden'>
            <Servicesignupcomponent />
          </div>
          <div className='w-full h-screen justify-center   flex flex-col'>
            <div className=" mx-4 sm:mx-0 w-[70%] sm:w-full sm:p-0 p-4 flex justify-between items-center">
              <Stepper steps={steps} currentStep={currentStep} />
              {/* {stepsDisplay} */}
            </div>
            <div className=' mx-4 w-[70%]  sm:w-full p-4  flex flex-col justify-between items-center'>
              <svg class="w-12 h-12 mb-4 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
              <h1 class="mb-2 text-2xl font-extrabold tracking-tight text-gray-900 leding-tight dark:text-white">Verified</h1>
              <p class="mb-4 font-light text-gray-500 dark:text-gray-400 md:mb-6">You have successfully verified your account.</p>
              <button onClick={() => signin()} class="block w-[60%] text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:py-3.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Log in to your Account</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Servicesignupconfirmation