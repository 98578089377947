import React from 'react'
import { FaFire } from 'react-icons/fa'
import img from "../../img/people.png"
import entrlogo from "../../img/Entrlogo.svg"
import img2 from "../../img/landingpageimages/img1.png"

const Section1 = () => {
    return (
        <div>
            <section class=" sm:w-full sm:pt-20 sm:pr-0 pb-[3rem] sm:pt-0">
                <div class="  mx-auto sm:w-full flex items-center flex-row sm:flex-col md:flex-col">
                    <div class="  w-[60%] justify-center items-center sm:w-full font-inter lg:py-16 m-24 sm:m-5 sm:py-6  ">
                        <h1 class="mb-4 text-4xl w-[70%] font-extrabold tracking-tight leading-none md:text-5xl lg:text-6xl text-white flex gap-3 sm:justify-center sm:w-[100%] sm:pr-5 mt-20" ><img src={entrlogo} alt="" /> Entr</h1>
                        <h3 className="text-5xl 2xl:text-5xl text-white w-[55%] font-bold sm:text-2xl sm:w-[100%] sm:text-center">Create Memorable
                            Moments</h3>
                        <p class="mb-6 font-light w-[90%] lg:mb-8 md:text-lg text-md sm:pl-4 text-gray-400 mt-2 sm:text-justify">With our intuitive tools and vendor network, you can effortlessly craft unforgettable experiences
                        </p>
                        <div class="mt-5 sm:ml-[6rem]">
                            <a href="/usersignin" class="text-white flex-row gap-2 bg-gray-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 sm:ml-20">
                                <p> Join for Free </p>
                            </a>
                        </div>
                    </div>
                    <div class=" top-0 right-0 w-[48%] block sm:w-full sm:pl-0">
                        <img class="object-cover w-full h-full" src={img2} alt="Conference speaker" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Section1