import img1 from '../svg/hostpopup/img1.jpg'
import img2 from '../svg/hostpopup/img2.jpg'

export const hpopupdata = [
    {
        img: img1,
        name: "Event Preparation"
    },
    {
        img: img2,
        name: "A.I and event planning"
    }
]