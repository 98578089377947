import React from 'react'

import img1 from './serviceiamges/img2.png'
const Servicelandingsec2 = () => {
  return (
    <div className='w-full'>
      <section class="bg-white  ">
        <div class="simplebookingmain gap-8 py-24 sm:my-5 flex flex-row md:flex-col sm:flex-col items-center px-4 mx-auto max-w-screen-xl  sm:py-16 lg:px-6">

          <div class=" w-[50%] md:w-full sm:w-full justify-center items-center  mx-10 md:mt-0 simgplebooking ">
            <h2 class="w-[85%] bookingheading sm:w-full mb-5 text-5xl font-['Inter'] tracking-tight font-extrabold text-gray-900  sm:text-3xl">Simple booking management</h2>
            <p class="bookingpara w-[84%] sm:w-full font-normal text-[#6B7280] md:text-lg  leading-6 sm:text-md">Our intuitive tools allow you to easily view, manage and coordinate booked events and gigs in one place. Track your availability calendar, billing, payouts, and client communication all on our platform</p>
          </div>
            <div className='w-[35%] flex flex-col md:w-full  sm:w-full simplebookingimg '>
              <img class=" w-[100%] sm:w-full ml-4 md:ml-2  justify-center " src={img1} alt="dashboard image" />
            </div>

        </div>
      </section>
    </div>
  )
}

export default Servicelandingsec2