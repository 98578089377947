import img1 from '../landingpagecomponent/svg/thingstodo/img1.jpg'
import img2 from '../landingpagecomponent/svg/thingstodo/img2.jpg'
import img3 from '../landingpagecomponent/svg/thingstodo/img3.jpg'
import img4 from '../landingpagecomponent/svg/thingstodo/img4.jpg'
import img5 from '../landingpagecomponent/svg/thingstodo/img5.jpg'
import img6 from '../landingpagecomponent/svg/thingstodo/img6.jpg'
import img7 from '../landingpagecomponent/svg/thingstodo/img7.jpg'
import img8 from '../landingpagecomponent/svg/thingstodo/img8.jpg'
import img9 from '../landingpagecomponent/svg/thingstodo/img9.jpg'
import img10 from '../landingpagecomponent/svg/thingstodo/img10.jpg'
import img11 from '../landingpagecomponent/svg/thingstodo/img11.jpg'
import img12 from '../landingpagecomponent/svg/thingstodo/img12.jpg'
export const tododata = [
    {
        img: img1,
        desc: "Live Entertainment"
    },
    {
        img: img2,
        desc: "Fitness and Wellness "
    },
    {
        img: img3 ,
        desc: "Family Fun",
    },
    {
        img: img4,
        desc: "Foodie",
    },
    {
        img: img5,
        desc: "Festivals",
    },
    {
        img: img6,
        desc: "Conferences ",
    },
    {
        img: img7,
        desc: "Comedy",
    },
    {
        img: img8,
        desc: "Networking",
    },
    {
        img: img9,
        desc: "Nature",
    },
    {
        img: img10,
        desc: "Water Activities  ",
    },
    {
        img: img11,
        desc: "Fashion Shows ",
    },
    {
        img: img12,
        desc: "LGBTQ+",
    },
    
]