import img1 from '../landingpagecomponent/svg/sec4/img1.jpg'
import img2 from '../landingpagecomponent/svg/sec4/img2.jpg'
import img3 from '../landingpagecomponent/svg/sec4/img3.jpg'
import img4 from '../landingpagecomponent/svg/sec4/img4.jpg'
import img5 from '../landingpagecomponent/svg/sec4/img5.jpg'
import img6 from '../landingpagecomponent/svg/sec4/img6.jpg'
import img7 from '../landingpagecomponent/svg/sec4/img7.jpg'
import img8 from '../landingpagecomponent/svg/sec4/img8.jpg'
import img9 from '../landingpagecomponent/svg/sec4/img9.jpg'
import img10 from '../landingpagecomponent/svg/sec4/img10.jpg'
import img11 from '../landingpagecomponent/svg/sec4/img11.jpg'
import img12 from '../landingpagecomponent/svg/sec4/img12.jpg'
import img13 from '../landingpagecomponent/svg/sec4/img13.jpg'
import img14 from '../landingpagecomponent/svg/sec4/img14.jpg'
export const sec5data = [
    {
        img: img1,
        desc: "Entertainment "
    },
    {
        img: img14,
        desc: "Bar Tenders"
    },
    {
        img: img13,
        desc: "Transportation "
    },
    {
        img: img9,
        desc: "Dj"
    },
    {
        img: img11,
        desc: "Games and Activities "
    },
    {
        img: img10,
        desc: "Food Catering"
    },
    {
        img: img12,
        desc: "Security "
    },
    {
        img: img8,
        desc: "Audio and Visual Equipment"
    },
    {
        img: img7,
        desc: "Photography and Video"
    },

    {
        img: img6,
        desc: "Beauty"
    },
    {
        img: img5,
        desc: "For Kids "
    },
    {
        img: img4,
        desc: "Cleaning"
    },
    {
        img: img3,
        desc: "Recovery"
    },
    {
        img: img2,
        desc: "Adults Only"
    },













]