import React from 'react'
import entrlogo from "../img/entrlogoblack.svg"
import Servicelogincomponent from '../components/Servicelogincomponent'
import { useEffect, useState } from 'react'
import EnterComponent from './EnterComponent'
import { useNavigate } from 'react-router-dom'
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { motion } from "framer-motion";
import bcrypt from 'bcryptjs'
import Swal from 'sweetalert2';
import NavbarService from '../components/NavbarService'
import { Formik, Form, Field, ErrorMessage } from 'formik';
const ServiceForgetpassword = () => {
  const navigate = useNavigate()
  const [passwoord, setPasswoord] = useState("");
  const [id, setid] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState()
  const [passwords, setPasswords] = useState("");
  const [getuser, setgetuser] = useState();
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [alert, setAlert] = useState("");
  const [fieldss, setFieldss] = useState(false);
  const [msg, setMsg] = useState("");
  const [emaill, setEmaill] = useState("");
  const [email, setEmail] = useState("");
  const [field, setField] = useState(false);
  const getemails = [];
  const getid = [];
  const storedValue = window.localStorage.getItem('emailForpassword');
  //console.log(storedValue)
  const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
  const getAllUser = async () => {
    const user = await getDocs(
      query(collection(firestore, "Serviceprovider"), orderBy("id", "desc"))
    );

    setgetuser(user.docs.map((doc) => doc.data()));
    //console.log(getuser)
  };
  const cemail = (e) => {
    setEmail(e.target.value)
    // const { name, value } = e.target;
    // setUserData({ ...userData, [name]: value });
    var data = getuser?.filter(val => val.email === e.target.value)
    //console.log(data);
    getemails.push(data[0]?.email)
    getid.push(data[0]?.id)

    setid(data[0]?.id)

    //console.log(getemails, getid[0], id);
    if (getemails[0] !== e.target.value) {
      //   setMsg("Email Already Exist");
      setEmaill("Email you entered do not exist")
      setField(true);
      setAlertStatus("danger");

      //console.log("email exist")
      setTimeout(() => {
        setField(false);
      }, 10000);

    }
    else {
      
    }



  }

  const passwordvalidate = (e) => {
    setPassword(e.target.value);
    var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    var test = reg.test(e.target.value);

    
    if (test) {
      //console.log("pass")
      setPasswords("Strong")
      setAlertStatus("success");
      setFieldss(true);

    } else {
      setPasswords("Weak")
      setAlertStatus("danger");
      setFieldss(true);

    
    }
  }



  const cpassword = (e) => {
    setconfirmpassword(e.target.value)

    if (e.target.value !== password) {
      setPasswoord("Password did not matched")
      setFields(true);
      setAlert("danger");

    }
    else {
      setPasswoord("Password  matched")
      setAlert("success");
      setFields(true);

      setTimeout(() => {
        setFields(false);
      }, 8000);
    }

  }
  const initialValues = {
    fname: '',
    lname: '',
    buisness: '',
    password: '',
    confirmpassword: '',
    country: '',
    phone: '',
    terms: '',
    newsletter: '',
  };
  const updatepassword = async (uid) => {
    //console.log(uid)
    try {
      const q = doc(firestore, "Serviceprovider", uid);
      // const qrf = query(q, where("email", "==", email));

      // //console.log(qrf)
      await updateDoc(q, {
        password: hashedPassword,

      })
        .then(() => {
          //
          Swal.fire({
            title: ' Password reset succesfully ',
            confirmButtonText: 'ok',
            confirmButtonColor: '#374151',
          })
          // 
          navigate("/servicelogin")
        })
        .catch((error) => {
          alert(error)
          //console.log(error)
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    }
    catch (e) {
      //console.log(e)
    }
  }

  useEffect(() => {

    // fetchuserData();
    getAllUser();


  }, []);
  return (
    <div className=' w-full  bg-gray-50  '>
      <NavbarService />
      <section class="p-20 sm:p-5 pt-40 sm:pt-20 pb-40 lg:pb-36 dark:bg-gray-900 justify-center items-center flex flex-row lg:flex-row md:flex-col max-w-full   sm:flex-col     ">
        <div className="w-[65%] flex-col sm:hidden justify-center items-center  col-span-6 mr-auto lg:flex xl:mb-0">
          <Servicelogincomponent />
        </div>
        <div class="flex flex-col w-[40%] sm:w-full items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
         
          <div class="w-full p-6 bg-white rounded-lg shadow border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h2 class="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Change Password
            </h2>
            
            <Formik
              initialValues={initialValues}
              validate={(values) => {
                const errors = {};
                if (!password) {
                  errors.password = "Required";
                }

                if (!confirmpassword) {

                  errors.confirmpassword = "invalid phone numbr"
                }

                return errors;
              }}
            >
              <Form>
                <div>
                  <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                  <input type="password" name="password" id="password" value={password} onChange={e => passwordvalidate(e)} placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${fieldss && alertStatus === "danger"
                    ? "  bg-red-50 border border-red-500 text-red-900 placeholder-red-700"
                    : "border-gray-300 "
                    }`} required="" />
                  {fieldss && (alertStatus === "danger" ? <div className=' mt-2 w-full h-1 bg-transparent border-red-800'> <div className='w-[20%] h-1 bg-red-800'></div></div> : <div className=' mt-2 w-full h-1 bg-green-800'></div>)}
                  {fieldss && (

                    <p class={`mt-2 text-sm ${alertStatus === "danger"
                      ? " text-red-800 "
                      : " text-emerald-800"
                      }`}>{passwords}</p>)}
                  <ErrorMessage name="password" className='text-red-600' component="div" />
                </div>

                <div className='mb-4'>
                  <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                  <input type="password" name="confirmpassword" onChange={e => cpassword(e)} value={confirmpassword} id="confirm-password" placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${fields && alert === "danger"
                    ? "  bg-red-50 border border-red-500 text-red-900 placeholder-red-700"
                    : "border-gray-300 "
                    }`} required="" />
                  {fields && (<p class={`mt-2 text-sm ${alert === "danger"
                    ? " text-red-800"
                    : " text-emerald-800"
                    }`}>
                    {passwoord}
                  </p>)}
                  <ErrorMessage name="confirmpassword" className='text-red-600' component="div" />
                </div>

                {getuser?.map((item) => (
                  item.email === storedValue && (
                    <button type="submit" onClick={() => updatepassword(item?.id)} className="w-full text-white bg-[#374151] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Reset Password</button>
                  )))}

              </Form>
            </Formik>
            {/* </form> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default ServiceForgetpassword