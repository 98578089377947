import React from 'react'
import NavbarService from '../components/NavbarService'
import ServiceLandingsignin from '../components/ServiceLandingsignin'
import Servicelandingsec1 from '../components/Servicelandingsec1'
import Servicelandingsec2 from '../components/Servicelandingsec2'
import Servicelandingfeature from '../components/Servicelandingfeature'
import Servicelandingfaq from '../components/Servicelandingfaq'
import ServiceFooter from '../components/ServiceFooter'
import './servicelanding.css'
import ServiceSearch from './Servicesearch'
import ServiceCompo1 from '../components/ServiceCompo1'
import ServicePricingCompo from '../components/ServicePricingCompo'
import ServiceHelp from '../components/ServiceHelp'

const ServiceLandingPage = () => {
  return (
    <div className='w-full bg-white h-full   '>
      <NavbarService />

      <section className="sericelanding pt-40  p-20 md:p-10 sm:py-10 sm:px-5 w-full justify-between items-center flex flex-row lg:flex-row md:flex-col max-w-full   sm:flex-col md:gap-8 sm:gap-14  ">

        <div className="w-[45%] servicetextmain md:w-full sm:w-full flex-col justify-center   col-span-6  lg:flex xl:mb-0">
          <ServiceLandingsignin />
        </div>
        <div className="w-[40%] serivesearchmain  lg:w-[50%] md:w-[100%] sm:w-[100%] col-span-6  bg-white rounded-lg shadow md:mt-0 sm:max-w-lg xl:p-0">
          <ServiceSearch />
        </div>

      </section>

      <ServiceCompo1 />

      <Servicelandingsec1 />


      <Servicelandingsec2 />


      <Servicelandingfeature />
      <ServicePricingCompo />

      {/* <ServiceHelp  /> */}

      <Servicelandingfaq />

      <ServiceFooter />

    </div>
  )
}

export default ServiceLandingPage