import React from 'react'
import NavbarMain from '../components/NavbarMain'
import Section1 from '../components/userlandingcomponents/Section1'
import Section2 from '../components/userlandingcomponents/Section2'
import Section3 from '../components/userlandingcomponents/Section3'
import Section4 from '../components/userlandingcomponents/Section4'
import Section5 from '../components/userlandingcomponents/Section5'
import Section8 from '../components/landingpagecomponent/Section8'
import Footer from '../components/landingpagecomponent/Footer'
import '../pages/userlanding.css'

const UserLandingPage = () => {
  return (

    <>
      <NavbarMain />
    <div className=' userlanding '>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      {/* <Section5/>
 <Section8/>
 <Footer/> */}
    </div>
    </>
  )
}

export default UserLandingPage