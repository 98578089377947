import React from 'react';
import './custom-error-style.css';
const CustomError = ({ message }) => {
  return (
    <div className='tool_tip'>
      {message}
    </div>
  )
}

export default CustomError;