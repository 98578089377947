import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./context/auth";
import { StateProvider } from "./context/StateProvider"
import { initialState } from "./context/initialState"
import reducer from "./context/reducer"
import { useStateValue } from "./context/StateProvider";
import RequireAuth from "./components/RequireAuth";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <AuthProvider>
      <Router>
        <StateProvider initialState={initialState} reducer={reducer}>
          <Routes>
            <Route path="/*" element={<App />} />

          </Routes>
        </StateProvider>
      </Router>
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
