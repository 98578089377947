import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

import img1 from '../landingpagecomponent/svg/sec5/img1.jpg'
import img2 from '../landingpagecomponent/svg/sec5/img2.jpg'
import img3 from '../landingpagecomponent/svg/sec5/img3.mov'
import img4 from '../landingpagecomponent/svg/sec5/img4.jpg'
import img5 from '../landingpagecomponent/svg/sec5/img5.jpg'
import img6 from '../landingpagecomponent/svg/sec5/img6.mov'
import img7 from '../landingpagecomponent/svg/sec5/img7.jpg'
import img8 from '../landingpagecomponent/svg/sec5/img8.jpg'

import './Section4.css'
import Section4Popup from './Section4Popup';
import LazyLoad from 'react-lazyload';
function Section4() {
  const column1Ref = useRef(null);
  const column3Ref = useRef(null);
  const column2Ref = useRef(null);
  const column4Ref = useRef(null);

  const [popup, setPopup] = useState(false);


  useEffect(() => {
    const column1 = column1Ref.current;
    const column3 = column3Ref.current;

    const tl = gsap.timeline({ paused: true });

    tl.to(column1, { y: '-=30', duration: 0.7, })
      .to(column3, { y: '-=30', duration: 0.7, }, '<');

    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollDirection = scrollTop > lastScrollTop ? 'down' : 'up';

      if (scrollDirection === 'down') {
        tl.play();
      } else if (scrollDirection === "up") {
        tl.reverse();
      }

      lastScrollTop = scrollTop;
    };

    const mediaQuery = window.matchMedia('(min-width: 768px)');

    if (mediaQuery.matches) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mediaQuery.matches) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  useEffect(() => {
    const column2 = column2Ref.current;
    const column4 = column4Ref.current;

    const tl = gsap.timeline({ paused: true });

    tl.to(column2, { y: '+30', duration: 0.7, })
      .to(column4, { y: '+30', duration: 0.7, }, '');

    let lastScrollTop = 0;

    const handleScroll2 = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollDirection = scrollTop > lastScrollTop ? 'down' : 'up';

      if (scrollDirection === 'down') {
        tl.play();
      } else if (scrollDirection === "up") {
        tl.reverse();
      }

      lastScrollTop = scrollTop;
    };

    const mediaQuery = window.matchMedia('(min-width: 768px)');

    if (mediaQuery.matches) {
      window.addEventListener('scroll', handleScroll2);
    }

    return () => {
      if (mediaQuery.matches) {
        window.removeEventListener('scroll', handleScroll2);
      }
    };
  }, []);



  return (
    <div className='mb-[5rem] section4 relative'>
      <div className='flex flex-col  items-center mb-[5rem] sm:mt-[3rem] sm:mb-[3rem] '>

        <p className='text-[#9FD9FF] sm:px-6 mt-[3rem] sm:text-center'>Host better with top-rated venues and services</p>
        <h5 className='text-4xl text-white w-[25%] leading-12  sm:w-[90%] sm:text-2xl  text-center sm:mt-2 sm:px-2'>Everything you need,
          In one place</h5>
      </div>

      <div className="sec4columns flex justify-center gap-4 w-[80%] sm:w-[90%] mx-auto" >
        <div id="column1" className="card1 flex flex-col gap-4" ref={column1Ref}>

          <LazyLoad height={200} className=' object-contain sm:w-full sec4div '>
            <img src={img1} className=' object-cover  ' />
            <div className="sec4desc">Private Estates</div>
          </LazyLoad>

          <LazyLoad height={200} className=' object-contain sm:w-full sec4div '>
            <img src={img5} className=' object-contain ' />

            <div className="sec4desc">
              Recovery
            </div>
            <div className="recovery-desc">
              <div>The aftermath
              </div>
            </div>
          </LazyLoad>
        </div>

        <div id="column2" className="card2 flex flex-col gap-4 " ref={column2Ref} >
          <div className=' sm:w-full sec4div ' >
            <video loop autoPlay playsinline="true" disablePictureInPicture="true" muted src={img3} className='w-full' ></video>
            <div className="sec4desc">
              Outdoor
              Spaces
            </div>
          </div>
          <LazyLoad height={200} className='  sm:w-full sec4div'>
            <img src={img4} className='w-full' />

            <div className="photo-desc">
              <div>Capture your greatness
              </div>
              <div> moments</div>
            </div>
            <div className="sec4desc">
              Photo
              and
              Video
            </div>
          </LazyLoad>
        </div>

        <div className="column3 flex flex-col gap-4" id="sec5" ref={column3Ref}>

          <LazyLoad height={200} className=' sm:w-full sec4div'>
            <img src={img8} className='w-full' />

            <div className="sec4desc">
              Beauty
            </div>
            <div className="beauty-desc">
              <div>Glam</div>
              <div>  day or night✨</div>
            </div>
          </LazyLoad>
          <div className=' sm:w-full sec4div' >
            <video loop autoPlay playsinline="true" disablePictureInPicture="true" muted src={img6} className='w-full' ></video>
            <div className="sec4desc">
              Boats and
              Yachts
            </div>
          </div>
        </div>
        <div className="column4 flex flex-col gap-4 " id="card4" ref={column4Ref} >

          <LazyLoad height={200} className=' sm:w-full sec4div'>
            <img src={img7} className='w-full' />
            <div className="sec4desc">
              Food
              Catering
            </div>
          </LazyLoad>
          <LazyLoad height={200} className=' sm:w-full sec4div'>
            <img src={img2} className='w-full' />
            <div className="dj-desc">
              <div> Turn up the</div>
              <div> jams</div>
            </div>


            <div className="sec4desc">
              DJ’s
            </div>
          </LazyLoad>
        </div>
      </div>
      <div className="flex justify-center items-center mt-16 sec4button">
        <button className='flex justify-center items-center' onClick={() => setPopup(!popup)}>View more</button>
      </div>

      <div className="div  ">
        {
          popup && (
            <Section4Popup onClose={() => setPopup(!popup)} />
          )
        }
      </div>
    </div>



  );
}

export default Section4;
