import { fetchCart, fetchCategory, fetchUser,fetchfruitCategory,fetchServiceprovider } from "../utils/fetchLocalStorageData";

const userInfo = fetchUser();
const serviceInfo = fetchServiceprovider();
const catInfo = fetchCategory();
const fruitcatInfo = fetchfruitCategory();
export const initialState = {
  user: userInfo,
  foodItems: null,
  freshfoodItems:null,
  foodCategory: catInfo,
  foodfreshcategory:fruitcatInfo,
  cartShow: false,
  serviceprovider: serviceInfo,
};
