import React, { useState, useEffect } from 'react'
import entrlogo from "../img/Entrlogo.svg"
import avatar from "../img/Avatar.svg"
import { useStateValue } from "../context/StateProvider";
import { CalendarToday, Chat, Person } from '@mui/icons-material';
import { actionType } from "../context/reducer";
import { useNavigate } from "react-router-dom";
import LanguagePoup from '../components/landingpagecomponent/LanguagePopup';
import Thingpopup from './landingpagecomponent/HoverComponents/Thingpopup';
import Hostpopup from './landingpagecomponent/HoverComponents/Hostpopup';
import { AiFillHome } from 'react-icons/ai'
import { FaCalendarAlt } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import { BsLightningChargeFill } from 'react-icons/bs'
import { FaBars } from 'react-icons/fa'
import { BsFillTicketFill, BsFillCollectionFill } from 'react-icons/bs'
import { BiBriefcase } from 'react-icons/bi'
import { AiFillMessage } from 'react-icons/ai'
import { RiNotification2Fill } from 'react-icons/ri'
import { BsFire } from 'react-icons/bs'
import { BiSolidChevronRight } from 'react-icons/bi'
import Marketplace from './landingpagecomponent/HoverComponents/Marketplace';


const NavbarMain = ({ childState, updateChildState }) => {
  const [isMenu, setIsMenu] = useState(false);
  const [isUserMenu, setIsUserMenu] = useState(false);
  const [isNav, setIsNav] = useState(false);
  const navigate = useNavigate();
  const [{ user }, dispatch] = useStateValue();
  const [isHovered, setIsHovered] = useState(false);
  const [isMarketplace, setIsMarketplace] = useState(false);
  const [isHost, setIsHost] = useState(false);



  const handleMarketEnter = () => {
    setIsMarketplace(true);
  };
  const handleMarketLeave = () => {
    setIsMarketplace(false);
  };
  const handleThingsEnter = () => {
    setIsHovered(true);
  };

  const handleThingsLeave = () => {
    setIsHovered(false);
  };
  const handleHostEnter = () => {
    setIsHost(true);
  };

  const handleHostLeave = () => {
    setIsHost(false);
  };
  const menu = async () => {
    if (window.innerWidth < 800) {
      setIsNav(!isNav);
    }
  }
  const userlogin = async () => {
    setIsUserMenu(!isUserMenu);
  };
  const login = async () => {
    setIsMenu(!isMenu);
  };

  const [activeItem, setActiveItem] = useState("");

  // Event handler function to set the active item
  const handleItemClick = (item) => () => {
    setActiveItem(item);
  };

  const logout = () => {
    setIsUserMenu(false);
    localStorage.clear();
    navigate("/")
    dispatch({
      type: actionType.SET_USER,
      user: null,
    });
    window.location.reload(true);
  };
  const [isevent, setisevent] = useState(true);
  const event = async () => {
    setisevent(!isevent)
  }


  useEffect(() => {
    setIsHovered(childState);
  }, [childState]);
  return (
    <>
      <div className='bg-gray-900  pb-5'>

        <nav class="text-white border-gray-200 bg-gray-900  z-50 w-full fixed  mb-5">
          {isevent &&
            <div id="sticky-banner" tabindex="-1" class=" left-0  flex justify-between w-full p-4 border-b  bg-gray-700 border-gray-600">
              <div class="flex items-center mx-auto">
                <p class="flex items-center text-sm font-normal text-gray-400">
                  <span class="inline-flex p-1 mr-3  rounded-full bg-gray-600">
                    <svg class="w-4 h-4 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                      <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path>
                    </svg>
                  </span>
                  <span>This is our Demo   <a href="/usersignin" class="inline font-medium text-blue-600 underline dark:text-blue-500 underline-offset-2 decoration-600 dark:decoration-500 decoration-solid hover:no-underline">Get Access</a></span>
                </p>
              </div>
              <div class="flex items-center">
                <button data-dismiss-target="#sticky-banner" onClick={() => event()} type="button" class="flex-shrink-0 inline-flex justify-center items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white">
                  <svg aria-hidden="true" class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                  <span class="sr-only">Close banner</span>
                </button>
              </div>
            </div>
          }
          <div class="w-full flex flex-row items-center justify-between  px-10 sm:px-5" onClick={() => { handleThingsLeave(); handleHostLeave(); handleMarketLeave() }}>
            <div className='flex flex-row   '>
              <a href="/*" class="flex items-center" onMouseEnter={() => { handleThingsLeave(); handleHostLeave(); handleMarketLeave() }}>
                <img src={entrlogo} className="h-8 mr-3 " alt="Flowbite Logo" />
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white "  >Entr</span>
              </a>

              <div class="items-center justify-between md:hidden sm:hidden w-full flex flex-row md:w-auto " id="mobile-menu-2">
                {user ? (
                  <>
                    <ul class="flex md:flex-col font-medium p-4 md:p-0 ml-4  dark:border-gray-100 rounded-lg dark:bg-gray-50 flex-row md:space-x-8 md:mt-0 md:border-0 md:dark:bg-white ">
                      <li>
                        <a href="#" class="block py-2 pl-3 pr-4 text-gray-400 hover:bg-gray-800 rounded md:bg-transparent  md:p-0 md:dark:text-gray-200" aria-current="page">Dashboard</a>
                      </li>
                      <li>
                        <a href="#" class="block py-2 pl-3 pr-4 text-gray-400 rounded hover:bg-gray-800 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">browse</a>
                      </li>
                    </ul>


                  </>

                )

                  : (
                    <>
                      <ul class="flex md:flex-col font-medium  p-4 md:p-0 ml-4  dark:border-gray-100 rounded-lg dark:bg-gray-50 flex-row md:space-x-8 md:mt-0 md:border-0 md:dark:bg-white ">
                        <li className='relative thingstodoli py-2 pl-4 pr-4 text-white' onMouseEnter={handleThingsEnter} onMouseLeave={() => { handleHostLeave(); handleMarketLeave() }} >
                          <a href="/" class="block   md:bg-transparent  md:p-0 md:dark:text-gray-200" aria-current="page">Things to do</a>
                        </li>

                        <li className='relative py-2 pl-4 pr-4 text-white' onMouseEnter={handleHostEnter} onMouseLeave={() => { handleThingsLeave(); handleMarketLeave() }} >
                          <a href="/userlandingpage" class="block   md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Host</a>
                        </li>
                        <li className=' py-2 pl-4 pr-4 text-white' onMouseEnter={handleMarketEnter} onMouseLeave={() => { handleThingsLeave(); handleHostLeave(); }} >
                          <a href="/findevent" class="block  md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Marketplace</a>
                        </li>
                        <li className='py-2 pl-4 pr-4 text-white' onMouseEnter={() => { handleThingsLeave(); handleHostLeave(); handleMarketLeave() }}>
                          <a href="/gopro" class="block   md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white  dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Premium</a>
                        </li>

                      </ul>
                    </>
                  )
                }
              </div>
            </div>

            <div>


              {user ? (
                // mobile navbar
                <div className="flex gap-4 mx-auto justify-center w-[40%] font-[500] sm:hidden">
                  <span>Events</span>
                  <span className='text-[#6B7280]'>Venues</span>
                  <span className='text-[#6B7280]'>Services</span>
                </div>
              ) : <></>}
            </div>
            <div class=" flex flex-row items-center py-2">
              {user ? <div></div> :
                <a href="/servicelandingpage" class=" px-4 py-2 sm:hidden md:hidden text-sm hover:text-gray-100 hover:underline dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" >Join as service provider</a>
              }
              {user ?
                <div class=" flex flex-row gap-6 items-center sm:hidden">
                  <div class=" flex flex-row gap-6 items-center border-r  border-gray-700">
                    <div className='flex flex-row gap-6 sm:hidden'>
                      <a href="" className='text-[#66A1D8]'>+ Create</a>
                      <CalendarToday className='text-gray-400 ' />
                      <Chat className='text-gray-400 ' />
                    </div>
                    <button type="button" onClick={userlogin} class="flex mr-5 sm:mr-5 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                      <span class="sr-only">Open user menu</span>
                      <img className="w-8 h-8 rounded-full " src={avatar} alt="user photo" />
                    </button>
                  </div>
                  <p onClick={logout} className='text-gray-400 cursor-pointer font-inter'>Logout</p>
                </div>
                :
                <>
                  <button type="button" onClick={login} class="flex flex-row gap-2 p-2  justify-center text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">

                    <span class="sr-only">Open user menu</span>
                    <svg class="w-6 h-6  " aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>


                    <Person class="w-6 h-6  rounded-full text-white" fill="currentColor" alt="user photo" />

                  </button>
                  <div  className="sm:d-none">
                    <LanguagePoup />
                  </div>
                </>
              }

              {isMenu && (
                <div class={`absolute  ${isevent ? "top-28 sm:top-24" : "top-12 sm:top-9"}  right-2  sm:mr-4  mr-10 my-4 text-base list-none bg-gray-700 divide-y-0 sm:divide-y rounded-lg shadow dark:bg-gray-700 divide-gray-600" id="user-dropdown`}>
                  <ul class="py-2 hidden sm:flex sm:flex-col" aria-labelledby="user-menu-button">
                    <li>
                      <a href="/usersignin" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Host</a>
                    </li>
                    <li>
                      <a href="/findevent" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Find Event</a>
                    </li>
                    <li>
                      <a href="/userlandingpage" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Features</a>
                    </li>
                    <li>
                      <a href="/gopro" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Premium</a>
                    </li>
                  </ul>
                  <ul class="py-2" aria-labelledby="user-menu-button">

                    <li>
                      <a href="/usersignin" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign-up / Login</a>
                    </li>

                    <li>
                      <a href="/servicelandingpage" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Become a Service Provider</a>
                    </li>
                    <li>
                      <a href="/userfaq" class="block px-4 py-2 text-sm text-gray-400 hover:text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">FAQ</a>
                    </li>
                  </ul>

                </div>
              )}
              {isUserMenu && (
                <div class={` absolute  ${isevent ? "top-28 sm:top-24" : "top-12  sm:top-[0rem] sm:mr-2"}  right-2  mr-10 my-4 text-base list-none bg-gray-900 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600`} id="user-dropdown">
                  <div class="px-12 py-3">
                    <span class="block text-sm text-white">{user?.name}</span>
                    <span class="block text-sm   truncatetext-gray-400">{user?.email}</span>
                  </div>
                  <ul class="py-2" aria-labelledby="user-menu-button">
                    <li>
                      <a href="/usersignin" class="flex flex-row gap-4 px-4 py-2 text-gray-400 text-sm  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><AiFillHome /> <p>Dashboard</p></a>
                    </li>
                    <li>
                      <a href="/usersignin" class="flex flex-row gap-4 px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><BsFillTicketFill /> <p>Tickets</p></a>
                    </li>
                    <li>
                      <a href="/gopro" class="flex flex-row gap-4 px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><BsFillCollectionFill /> <p>Hosting</p></a>
                    </li>
                    <li>
                      <a href="/usersignin" class="flex flex-row gap-4 px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><BiBriefcase /> <p>My Bookings</p></a>
                    </li>
                    <li>
                      <a href="/usersignin" class="flex flex-row gap-4 px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><AiFillMessage /> <p className='flex items-center'>Messages <p className=' ml-2 bg-blue-600 flex justify-center items-center text-white  w-5 rounded-full text-sm'>1</p></p></a>
                    </li>
                    <li>
                      <a href="/usersignin" class="flex flex-row gap-4 px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><RiNotification2Fill /> <p className='flex items-center' >Notifications <p className=' ml-2 bg-blue-600 flex justify-center items-center text-white  w-5 rounded-full text-sm'>1</p></p></a>
                    </li>
                    <li>
                      <a href="/usersignin" class="flex flex-row gap-4 px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><FaCalendarAlt /> <p>Calendar</p></a>
                    </li>
                    <li>
                      <a href="/usersignin" class="flex flex-row gap-4 px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><BsFire /> <p className='flex justify-between'>Go Pro </p> <BiSolidChevronRight className='ml-24' /></a>
                    </li>
                  </ul>
                  <ul class="py-2" aria-labelledby="user-menu-button">
                    <li>
                      <a href="#" class="block px-4 py-2 text-sm text-gray-400  hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">My Profile</a>
                    </li>
                    <li>
                      <a href="#" class="block px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Account Settings</a>
                    </li>
                  </ul>
                  <ul class="py-2" aria-labelledby="user-menu-button">
                    <li className='cursor-pointer'>
                      <p onClick={logout} class="block px-4 py-2 text-sm text-gray-400  hover:bg-gray-600 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Sign Out</p>
                    </li>
                  </ul>
                </div>
              )}
              <button data-collapse-toggle="mobile-menu-2" type="button" onClick={menu} class="md:hidden sm:hidden  items-center p-2 ml-1 text-sm text-gray-500 rounded-lg hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
              </button>
            </div>

          </div>

        </nav>

        {
          user ? (
            <>
            <div className='lg:hidden xl:hidden sm:block bottom-0 fixed w-[100%] z-40 px-3 bg-gray-900 pb-4'>
              <ul className='sm:flex justify-between text-white'>
                <li className={`flex flex-col items-center text-xl cursor-pointer border-t-4  pt-3 ${activeItem === "home" ? 'border-t-4 border-[#539DF3] pt-3' : 'border-gray-900 '}`} onClick={handleItemClick("home")}>
                  <AiFillHome className={`text-2xl ${activeItem === "home" ? 'text-[#539DF3]' : ''}`} />
                  <span className={` text-sm ${activeItem === "home" ? 'text-[#539DF3]' : ''}`}>Home</span>
                </li>
                <li className={`flex flex-col items-center text-xl cursor-pointer border-t-4 border-gray-900 pt-3 ${activeItem === "events" ? 'border-t-4 border-[#539DF3] pt-3' : 'border-gray-900 '} `} onClick={handleItemClick("events")}>
                  <FaCalendarAlt className={`text-2xl  ${activeItem === "events" ? 'text-[#539DF3]' : ''}`} />
                  <span className={`text-sm  ${activeItem === "events" ? 'text-[#539DF3]' : ''}`}>Events</span>
                </li>
                <li className={`flex flex-col items-center text-xl  cursor-pointer border-t-4 border-gray-900 pt-3 ${activeItem === "venues" ? 'border-t-4 border-[#539DF3] pt-3' : 'border-gray-900 '}`} onClick={handleItemClick("venues")}>
                  <FaLocationDot className={`text-2xl ${activeItem === "venues" ? 'text-[#539DF3]' : ''}`} />
                  <span className={`text-sm  ${activeItem === "venues" ? 'text-[#539DF3]' : ''}`}>Venues</span>
                </li>
                <li className={`flex flex-col items-center text-xl cursor-pointer border-t-4 border-gray-900 pt-3 ${activeItem === "services" ? 'border-t-4 border-[#539DF3] pt-3' : 'border-gray-900 '}`} onClick={handleItemClick("services")}>
                  <BsLightningChargeFill className={`text-2xl ${activeItem === "services" ? 'text-[#539DF3]' : ''}`} />
                  <span className={`text-sm ${activeItem === "services" ? 'text-[#539DF3]' : ''} `}>Services</span>
                </li>
                <li className={`flex flex-col items-center text-xl cursor-pointer border-t-4 border-gray-900  pt-3 ${activeItem === "menu" ? 'border-t-4 border-[#539DF3] pt-3' : 'border-gray-900 '}`} onClick={() => { handleItemClick("menu")(); userlogin(); }}>
                  <FaBars className={`text-2xl ${activeItem === "menu" ? 'text-[#539DF3]' : ''}`} />
                  <span className={`text-sm  ${activeItem === "menu" ? 'text-[#539DF3]' : ''}`}>Menu</span>
                </li>
              </ul>
            </div>
            </>
          ) : <></>
        }

        {isNav && (
          <div class="items-center justify-between flex flex-col  w-full md:w-auto" id="mobile-menu-2">
            <ul class="flex md:flex-col md:gap-4 text-center font-medium mb-5 p-4 md:p-0 ml-4  dark:border-gray-100 rounded-lg dark:bg-gray-50 flex-row  md:mt-0 md:border-0 md:dark:bg-white ">
              <li>
                <a href="/usersignin" class="py-2 pl-3 pr-4 text-white rounded border-gray-800 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" aria-current="page">Host</a>
              </li>
              <li>
                <a href="/findevent" class=" py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Find Events</a>
              </li>
              <li>
                <a href="/userlandingpage" class=" py-2 pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Features</a>
              </li>
              <li>
                <a href="/gopro" class=" py-2  pl-3 pr-4 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-200 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Premium</a>
              </li>

            </ul>
          </div>
        )}
        {isHovered && (
          <Thingpopup className="absolute left-0 top-[2rem] z-50 transition-transform transform scale-100 " handleThingsLeave={handleThingsLeave}  />
        )}
        {isMarketplace && (
          <Marketplace className="absolute left-0 top-[2rem] z-50 transition-all" handleMarketLeave={handleMarketLeave} />
        )}
        {isHost && (
          <Hostpopup className="absolute left-0 top-[2rem] z-50 transition-all" handleHostLeave={handleHostLeave} />
        )}
      </div>


    </>
  )
}

export default NavbarMain