import React from 'react'
import styles from "./service-provider.module.css";

const ServiceProviderBottom = () => {
    return (
        <div className='container-xl'>
            <div className='pt-4 pb-10' >
                <div className=' py-4 grid grid-cols-2
                    place-content-center md:place-content-between sm:grid-cols-1 text-black '>

                    <div className="flex md:justify-center items-center">
                        <h3 className={`${styles.servideProviderMidHeading}`}>My Booking, <span className={`${styles.servideProviderMidHeadingP2}`}> This week </span> </h3>
                    </div>
                    <div className='flex justify-end md:justify-center pt-1 items-center md:mt-2'>
                        <p className={` cursor-pointer ${styles.servideProviderTopTxt1}`}>See More</p>
                    </div>

                </div>

                <div className='py-2 grid grid-cols-12 gap-4 md:grid-cols-1'>
                    <div className='col-span-12 '>
                        <ul className='sm:block flex justify-start md:justify-between border-b-2 border-b-[#9788A633]'>

                            <li className={`pb-1 px-1 mr-5 border-b-2 border-b-[#0062FF] text-[#0062FF] text-center cursor-pointer ${styles.attendingSecondaryMenuText}`}>Bookings
                                <span style={{ width: "16px", height: "16px" }} className='inline-block rounded-full text-white mx-2 text-xs bg-[#0062FF]'>
                                    8
                                </span>
                            </li>


                            <li className={`pb-2 px-1 mr-5 border-b-2 border-b-transparent hover:border-b-[#0062FF] hover:text-[#0062FF] text-center cursor-pointer ${styles.attendingSecondaryMenuText}`}>Pending
                                <span style={{ width: "16px", height: "16px" }} className='inline-block rounded-full text-white mx-2 text-xs bg-[#0062FF]'>
                                    1
                                </span>
                            </li>
                            <li className={`pb-1 px-1 mr-5 border-b-2 border-b-transparent hover:border-b-[#0062FF] hover:text-[#0062FF] text-center cursor-pointer ${styles.attendingSecondaryMenuText}`}>Completed
                                <span style={{ width: "16px", height: "16px" }} className=' inline-block rounded-full text-white mx-2 text-xs bg-[#0062FF]'>
                                    4
                                </span>
                            </li>
                            <li className={`pb-1 px-1 mr-5 border-b-2 border-b-transparent hover:border-b-[#0062FF] hover:text-[#0062FF] text-center cursor-pointer ${styles.attendingSecondaryMenuText}`}>Cancelled
                                <span style={{ width: "16px", height: "16px" }} className=' inline-block rounded-full text-white mx-2 text-xs bg-[#0062FF]'>
                                    1
                                </span>
                            </li>
                            <li className={`pb-1 px-1 mr-5 border-b-2 border-b-transparent hover:border-b-[#0062FF] hover:text-[#0062FF] text-center cursor-pointer ${styles.attendingSecondaryMenuText}`}>Declined
                                <span style={{ width: "16px", height: "16px" }} className='inline-block rounded-full text-white mx-2  text-xs bg-[#0062FF]'>
                                    0
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div className='col-span-12 '>
                    </div>
                </div>
                <div className='py-3 overflow-x-auto'>

                    <table className=' min-w-[700px] w-full'>

                        <thead className={` min-w-[700px] ${styles.serviceProviderBottomTableHead}`}>
                            <tr className='min-w-[700px]'>
                                <th className='mr-2 min-w-[30px]'>
                                    Host
                                </th>
                                <th className='mr-2 min-w-[30px]'>
                                    Book service
                                </th>
                                <th className='mr-2 min-w-[30px]'>
                                    Service Date
                                </th>
                                <th className='mr-2 min-w-[30px]'>
                                    Service Time
                                </th>
                                <th className='mr-2 min-w-[30px]'>
                                    {"Total($)"}
                                </th>
                                <th className='mr-2 min-w-[30px]'>
                                    Notes
                                </th>
                                <th className=' min-w-[30px]'>
                                    Status
                                </th>
                            </tr>

                        </thead>

                        <tbody>
                        </tbody>
                    </table>

                </div>

                <div className='h-40'>

                </div>

            </div>
        </div>
    )
}

export default ServiceProviderBottom;