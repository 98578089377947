import React from 'react'
import { useNavigate } from 'react-router-dom'
import EnterComponent from './EnterComponent'
const VerifyingUser = () => {
const navigate = useNavigate()
    const signup = ()=>{
navigate("/usersignup")
    }
  return (
    <div>
         <section className=" flex flex-row bg-img bg-no-repeat h-screen bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-30 ">
  
    <div className='flex flex-col ml-[72px]  justify-center w-[50%]  xl:w-[50%] lg:w-[50%] md:hidden sm:hidden'>
   <EnterComponent/>
   </div>
   <div className="w-[50%] xl:w-[50%] lg:w-[50%] md:w-[100%] sm:w-[100%]">
    <div className=" flex flex-col  px-6 py-8 mx-auto md:h-screen pt:mt-0 ">
    <p className="text-sm sm:hidden font-light mb-[150px] sm:mb-[130px] ml-[68%] xl:ml-[75%] lg:ml-[65%] md:ml-[60%] sm:ml-[45%] text-gray-500 dark:text-gray-300">
                    <label for="newsletter" className="font-medium text-sm text-[#F9FAFB] font-['Inter'] dark:text-gray-300">Become a  <a className="font-medium text-primary-600 underline hover:underline dark:text-primary-500" href="/servicelandingpage">Service Provider</a></label>
                    </p>
            <div>        
        <div className="w-[70%] md:w-[100%]  sm:w-[100%] ml-32 sm:ml-0 justify-center align-center  bg-white rounded-lg shadow xl:max-w-full md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
            <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                <h2 className="text-xl font-bold justify-center align-center leading-tight tracking-tight  text-gray-900 md:text-2xl dark:text-white">
                    Verify Your Number
                </h2>
                <p className='text-gray-500 text-center font-["Inter"] text-sm font-semibold'>We texted you a six-digit code to <b>+1111111</b>.  Enter the code below to confirm your Contact No.</p>
        
                <form className="space-y-4 md:space-y-6" action="#">
                    
                    <div className='flex flex-row gap-2'>
                       
                        <input type="text" name="otp" id="otp" placeholder="1" className="bg-gray-50  text-center border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full px-2 py-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required=""/>
                        <input type="text" name="otp" id="otp" placeholder="2" className="bg-gray-50 border border-gray-300 text-center text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full px-2 py-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required=""/>
                        <input type="text" name="otp" id="otp" placeholder="3" className="bg-gray-50 border border-gray-300 text-center text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full px-2 py-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required=""/>
                        <input type="text" name="otp" id="otp" placeholder="4" className="bg-gray-50 border border-gray-300 text-center text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full px-2 py-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required=""/>
                        <input type="text" name="otp" id="otp" placeholder="5" className="bg-gray-50 border border-gray-300 text-center text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full px-2 py-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required=""/>
                        <input type="text" name="otp" id="otp" placeholder="6" className="bg-gray-50 border border-gray-300 text-center text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full px-2 py-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required=""/>
                    </div>
                    <div>
                       
                        
                    </div>
                   
                    <p className='text-gray-500  font-["Inter"] text-sm font-normal'>Make sure to keep this window open while check your inbox</p>
        
                    <button type="submit" onClick={signup} className="w-full text-white bg-[#374151] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Verify</button>
                    <div className="flex items-start">
                        
                       
                    </div>
                    
                </form>
            </div>
        </div>
        </div>
    </div>
    </div>
</section>
    </div>
  )
}

export default VerifyingUser