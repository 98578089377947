import React from 'react'
import sec7 from '../landingpagecomponent/svg/sec7.png'
import { FiTrendingUp } from 'react-icons/fi'
import './Section7.css'

const Section7 = () => {
    return (
        <div>
            <section class=" ml-5  m-20 my-20 sm:m-5 sm:my-5 ">
                <div class="gap-12 items-center py-8 sm:py-0 mx-auto flex flex-row sm:flex-col justify-center text-center md:flex-col lg:py-16 lg:px-6 sm:px-0 mt-3">
                    <div class="font-light text-gray-500 mx-6 sm:mx-0 sm:text-lg dark:text-gray-400 sm:text-center">
                        <p class="mb-4 text-lg font-normal font-['Inter'] text-[#9FD9FF] sm:text-center sm:mt-[3rem]"> Find the experience your looking for</p>
                        <h2 class="mb-4 text-4xl  leading-[56px] tracking-tight font-['Inter'] font-medium text-white w-[55%] mx-auto sm:text-2xl sm:w-[100%] sm:text-center"> Discover things to do around the world</h2>
                    </div>
                </div>

                <div class="flex mt-4 justify-center sec7img ml-10 sm:ml-2">
                    <img class="w-[90%] rounded-md sm:w-[100%]" src={sec7} alt="office content 1" />
                </div>

                <div className=' pt-7 text-2xl sm:pl-1 '>
                    <h3 className='text-[32px] flex gap-2 items-center justify-left text-white sec7text ml-7 sm:ml-2'>Trending <FiTrendingUp /></h3>

                    <div className=' sm:ml-[5px] flex flex-col sm:flex-row justify-start sm:flex-wrap items-start sec7subtext s w-[95%] sm:w-[100%  ] ml-[6rem] mt-[2rem]'>
                        <ul className='sec7one text-white flex justify-between w-full sm:flex-col sm:gap-3 pt-7 text-[20px]    '>
                            <li className='  flex-1 lis pb-2 '>1. Taylor Swift</li>
                            <li className=' pb-2 flex-1 lis text-left'>2. FIFA</li>
                            <li className=' pb-2 flex-1 lis text-left'>3. Formula 1</li>
                            <li className=' pb-2 flex-1 lis text-left'>4. Super Bowl</li>
                            <li className=' pb-2 flex-1 lis text-left'>5. Halloween</li>
                        </ul>
                        <ul className='sec7two text-white flex justify-between w-full sm:flex-col sm:gap-3 pt-7 text-[20px] mt-[4rem]  sm:mt-5 '>
                            <li className=' pb-2 flex-1  lis text-left'>6. World Cup</li>
                            <li className=' pb-2 flex-1  lis text-left'>7. NASA</li>
                            <li className=' pb-2 flex-1  lis text-left'>8. UFC</li>
                            <li className=' pb-2 flex-1  lis text-left'>9. Clubs</li>
                            <li className=' pb-2 flex-1  lis text-left'>10. Festivals</li>
                        </ul>

                    </div>

                </div>

            </section>
        </div>
    )
}

export default Section7