import { Tabs } from 'flowbite-react';
import React, { useState } from 'react'
import sec3 from '../landingpagecomponent/svg/sec3/img1.png'
import sec4 from '../landingpagecomponent/svg/sec3/img2.png'
import sec5 from '../landingpagecomponent/svg/sec3/img3.png'
import { CreditCard } from '@mui/icons-material';

const Section3 = () => {
  const [ios, setios] = useState(true)
  const [android, setandroid] = useState(false)
  const iosset = () => {
    setios(true);
    setandroid(false)
  }
  const androidset = () => {
    setios(false);
    setandroid(true)
  }
  return (
    <div>
      <section class="  m-20 my-20 sm:m-5  ">
        <div class=" px-4 py-8 mx-auto sm:px-0 sm:mx-0 sm:py-2 md:px-0 md:mx-0 md:py-2 lg:py-24">
          <div class=" ">

            <div className='w-full'>

              <div id="deviceTabContent" class="mt-8 flex justify-between sm:flex-col sm:pt-10   ">
                {ios ? (
                  <div class="w-[45%] sm:w-full sec3first" id="ios" role="tabpanel" aria-labelledby="ios-tab">
                    <div class="space-y-4 font-['Inter']  sm:space-y-6 lg:space-y-8">
                      <div>
                        <h2 class=" sec2heading w-[100%] text-5xl font-medium font-['Inter'] leading-tight text-white sm:text-3xl dark:text-white  sm:font-medium md:text-4xl xl:text-5xl sm:text-left">
                          Save time event planning,
                          spend more time having fun
                        </h2>
                        <p class="mt-4 text-md font-['Inter'] font-normal text-gray-400 dark:text-gray-[500] sm:text-sm w-[100%] sm:text-left">
                          Use our tools to seamlessly manage your event so
                          you can stay focused on throwing an unforgettable bash
                        </p>
                      </div>

                      <div class="pt-4 sm:pt-6 lg:pt-8 border-gray-800">
                        <p class="mb-5 text-md font-['Inter'] font-normal text-white sm:text-xl w-[40%] sm:w-full" >Some features include:</p>
                        <ul class="space-y-4 font-['Inter']">
                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-sm font-medium text-[#9CA3AF]">
                              Ticket Sales and Authentication
                            </span>
                          </li>
                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-sm font-medium text-[#9CA3AF]">
                              Guest list managment
                            </span>
                          </li> <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-sm font-medium text-[#9CA3AF]">
                              Tables and VIP Packages
                            </span>
                          </li> <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-sm font-medium text-[#9CA3AF]">
                              Simplified fundraising
                            </span>
                          </li>
                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-base font-medium text-[#9CA3AF]">
                              Guest doc-sign
                            </span>
                          </li>

                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-base font-medium text-[#9CA3AF]">
                              Polls
                            </span>
                          </li>
                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-base font-medium text-[#9CA3AF]">
                              SMS alerts
                            </span>
                          </li>
                        </ul>

                        <h3 class="mt-6 text-md font-normal font-['Inter'] text-gray-500 dark:text-gray-400 sm:text-sm">
                          Elevate your events to new heights and leave a lasting impression  </h3>
                      </div>
                      <a href="/usersignin" class="inline-flex sm:w-full bg-gray-500 px-3 items-center justify-center py-3 mr-3 text-base font-medium text-center text-white rounded-lg sm:p-2 sm:text-sm">
                        Create an event
                        <svg class="w-5 h-5 ml-2 sm:w-4 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                      </a>
                      <div class="flex sm:flex-row sm:w-[90%] items-center">
                        <svg aria-hidden="true" class="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Rating star</title>
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path>
                        </svg>
                        <p class="ml-2 sm:ml-0 text-sm font-bold text-white">1456+ <span className='text-gray-500'> Reviews</span></p>
                        <hr class="h-5 w-[2px] mx-3 sm:ml-1 sm:mr-1  vertical sm:mr-2  bg-gray-500  dark:bg-gray-400" />
                        <p class="text-sm font-medium text-white  hover:no-underline dark:text-white  sm:text-xs">
                          <CreditCard className='text-white mx-2 sm:mx-0' />
                          No Credit card required</p>
                      </div>

                    </div>
                  </div>
                ) : (<div></div>)}
                {android ? (
                  <div class="w-[60%]" id="android" role="tabpanel" aria-labelledby="android-tab">
                    <div class="space-y-4 sm:space-y-6 lg:space-y-8">
                      <div>
                        <h2 class="text-3xl font-extrabold font-['Inter'] leading-tight text-white sm:text-4xl dark:text-white">
                          Monetize with Ease, Effortlessly stay in control
                        </h2>
                        <p class="mt-4 text-base font-normal font-['Inter'] text-gray-400 dark:text-gray-400 sm:text-xl">
                          Our app helps users easily track their expenses and create a budget. With a user-friendly interface,
                          the
                          app
                          allows
                          users to quickly input their income and expenses, and then automatically categorizes them for easy
                          tracking.
                        </p>
                      </div>

                      <div class="pt-4 border-t font-['Inter'] sm:pt-6 lg:pt-8 border-gray-800">
                        <ul class="space-y-4 font-['Inter']">
                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-base font-medium text-white">
                              Seamless integration with Android Studio
                            </span>
                          </li>

                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-base font-medium text-white">
                              Deployments with a click of a button
                            </span>
                          </li>

                          <li class="flex items-center gap-2.5">
                            <div
                              class="inline-flex items-center justify-center w-5 h-5 rounded-full bg-[#fff] text-primary-600 shrink-0 dark:bg-primary-900 dark:text-primary-500">
                              <svg aria-hidden="true" class="w-3.5 h-3.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="#6B7280">
                                <path fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd" />
                              </svg>
                            </div>
                            <span class="text-base font-medium text-white">
                              Lightning fast performance
                            </span>
                          </li>
                        </ul>

                        <h3 class="mt-6 text-xl font-normal font-['Inter'] text-gray-500 dark:text-gray-400">
                          Flowbite takes the hassle out of budgeting and empowers users to take control of their finances.
                        </h3>
                      </div>

                      <div>
                        <a href="#" title=""
                          class="inline-flex items-center font-['Inter'] text-base font-medium text-[#6B7280] hover:underline dark:text-primary-500">
                          Check out the Android app features
                          <svg aria-hidden="true" class="w-5 h-5 ml-1.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="#6B7280">
                            <path fill-rule="evenodd"
                              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                              clip-rule="evenodd" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (<div></div>)}
                <div class=" lg:mt-0sm:m-4 w-[45%] mt-5  sm:w-full sec3imagecontainer">
                  <img src={sec3} className='sm:w-[100%] w-full' alt="mockup img3" />
                  <div className="sec3main ">
                    <div className="sec3child">
                      <img src={sec4} alt="" className="secimg4" />
                      <img src={sec5} alt="" className=" handanimation secimg5 " />
                    </div>
                  </div>
                </div>

              </div>

            </div>


          </div>


        </div>
      </section>
    </div>
  )
}

export default Section3