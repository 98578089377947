import React, { useEffect, useState } from 'react'
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import Swal from 'sweetalert2';
import { passwordreset, app } from "../firebase";
import {
  collection,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { firestore } from "../firebase";
//import { motion } from "framer-motion";
//import bcrypt from 'bcryptjs'
import NavbarService from '../components/NavbarService';
import Servicelogincomponent from '../components/Servicelogincomponent';
import CustomError from '../components/CustomError/CustomError';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Serviceforgetpass = () => {

  const [id, setid] = useState("");

  const [getuser, setgetuser] = useState();

  const [email, setEmail] = useState("");

  const [err, setErr] = useState("");

  const getemails = [];
  const getid = [];
  const auth = getAuth();

  // const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
  const getAllUser = async () => {
    const user = await getDocs(
      query(collection(firestore, "Serviceprovider"), orderBy("id", "desc"))
    );

    setgetuser(user.docs.map((doc) => doc.data()));
    // //console.log(getuser)
  };


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };


  const cemail = (e) => {
    setEmail(e.target.value)
  }
  const signup = async () => {
    setErr("");
    setOpen(true);

    var data = getuser?.filter(val => val.email === email);
    //console.log(data); 
    getemails.push(data[0]?.email);
    //console.log(getemails); 
    if (getemails[0] === email) {
      try {
        await sendPasswordResetEmail(auth, email, passwordreset)
          // await sendEmailVerification(auth, email,passwordreset)
          .then(() => {
            // Password reset email sent!
            setOpen(false);
            Swal.fire({
              title: 'Password reset link has been sent to your email.',
              confirmButtonText: 'ok',
            })

            window.localStorage.setItem('emailForpassword', email);
            cleardata();
            // ..
          })
          .catch((error) => {
            setOpen(false);
            setErr(error.message);
            //alert(error)
            //console.log(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
          });

      }
      catch (e) {
        setErr(e);
        setOpen(false);
        //console.log(e)
        // alert(error)
      }

    }
    else {
      setOpen(false);
      // Swal.fire({
      //   title: ' User Not Found',
      //   confirmButtonText: 'ok',
      // })
      setErr("User not found");
    }


  }
  const storedValue = window.localStorage.getItem('emailForpassword');
  //console.log(storedValue)
  const cleardata = () => {
    setEmail("");
  }

  useEffect(() => {
    // fetchuserData();
    getAllUser();
  }, []);



  return (
    <div className=' w-full  bg-gray-50  '>
      <NavbarService />
      <section class="p-20 sm:p-5  sm:pt-20 pb-40 lg:pb-36 dark:bg-gray-900 justify-center items-center flex flex-row lg:flex-row md:flex-col max-w-full   sm:flex-col     ">
        <div className="w-[65%] flex-col sm:hidden justify-center items-center  col-span-6 mr-auto lg:flex xl:mb-0">
          <Servicelogincomponent />
        </div>
        <div className="w-[50%] xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
          <div
            id="defaultModal"

          >
            <div className=" flex flex-col  px-6 py-8 mx-auto md:h-screen pt:mt-0">
              <p className="text-sm font-light mb-[170px] sm:hidden sm:mb-[130px] ml-[68%] xl:ml-[68%] lg:ml-[55%] md:ml-[50%] sm:ml-[40%] text-gray-500 dark:text-gray-300">
                <label for="newsletter" className="font-medium text-sm text-[#F9FAFB] font-['Inter'] dark:text-gray-300">Become a  <a className="font-medium text-primary-600 underline hover:underline dark:text-primary-500" href="/servicelandingpage">Service Provider</a></label>
              </p>
              <div>
                <div className="w-[75%] md:w-[100%] ml-28 sm:ml-0 sm:w-[100%] justify-center align-center  bg-white rounded-lg shadow xl:max-w-full md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                  <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                    <h2 className="text-xl font-bold justify-center align-center leading-tight tracking-tight  text-gray-900 md:text-2xl dark:text-white">
                      Forgot Your Password?
                    </h2>
                    <p className='text-gray-500 text-justify font-["Inter"] text-sm font-semibold'>We’ll email you instructions to reset your password. If you don’t have access to your email anymore, you can try account recovery</p>

                    {/* <form className="space-y-4 md:space-y-6" action="#"> */}

                    <div>
                      <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Enter email</label>

                      <input type="email" name="email" id="email" value={email} onChange={e => cemail(e)}
                        onFocus={() => setErr("")}
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email" required="" />

                    </div>

                    {
                      err.length > 0 &&
                      <div className='w-full flex justify-center items-center'>
                        <CustomError message={err} />
                      </div>
                    }

                    <button type="submit" onClick={signup} className="w-full text-white bg-[#374151] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Reset Password</button>


                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </section>
    </div>
  )
}

export default Serviceforgetpass