import React from 'react'
import './DemoPopup.css'
import {AiOutlineClose} from 'react-icons/ai'

const DemoPopup = ({onClose}) => {
    return (
        <div className="demo-main relative">
            <AiOutlineClose className='absolute top-2 right-2 text-white text-3xl cursor-pointer' onClick={onClose}/>
            <div className='w-[40%] h-64 absolute top-[40%] left-[30%] text-center flex flex-col gap-5 text-white '>
                <h4 className="text-4xl">We're launching soon</h4>
                <p className='text-xl'>Features are currently limited. We will notify you once the party starts 🎉...</p>
                <p className="underline text-lg">Thank you</p>
            </div>
        </div>
    )
}

export default DemoPopup