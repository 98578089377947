import React from 'react'
import NavbarMain from '../NavbarMain'
import Footer from './Footer'

const Investinentr = () => {
  return (
    <div className='bg-gray-900 w-full '>
    <NavbarMain/>
        <section class=" bg-gray-900 pt-20 my-32 w-full ">
    <div class="py-8 px-4 mx-auto w-[40%]  sm:py-16 lg:px-6 ">
        <div class="mx-auto max-w-screen-md text-center justify-center items-center">
            <h2 class="mb-4 text-4xl leading-tight font-extrabold text-white">Would you like to invest in our company?</h2>
            <p class="mb-6 font-light text-xl  md:text-lg text-gray-400">Please enter your email below to receive more information 
about our investment opportunities</p>
            <form action="#" class="mx-auto w-[70%]">
                <div class="flex items-center mb-3">
                    <div class="relative mr-3 w-full">
                        <label for="member_email" class="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email address</label>
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                        </div>
                        <input class="block p-3 pl-10 w-full text-sm  rounded-lg border focus:ring-primary-500 focus:border-primary-500 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-primary-500 focus:border-primary-500" placeholder="Enter your email" type="email" name="member[email]" id="member_email" required=""/>
                    </div>
                    <div>
                        <input type="submit" value="Subscribe" class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg cursor-pointer  focus:ring-4 ring-blue-300 bg-blue-600 hover:bg-blue-700 focus:ring-blue-800" name="member_submit" id="member_submit"/>
                    </div>
                </div>
                  </form>
        </div>
    </div>
</section>
<Footer/>
    </div>
  )
}

export default Investinentr