import React from 'react';
import styles from "./services-and-venues-section-style.module.css";

const ServiceAndVenuesBottom = () => {
    return (
        <div className='w-[80%] mx-auto text-white'>
            <div className="grid grid-cols-12 md:grid-cols-1 place-content-between gap-4 my-12 pb-40">
                <div className='col-span-12 '>
                    <ul className='flex justify-start md:justify-between'>

                        <li className={`pb-1 pl-1 mr-5 border-b-2 border-b-[#0062FF] text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`}>Upcoming
                            <span style={{ width: "16px", height: "16px" }} className='inline-block rounded-full text-[#111928] mx-2 px-1 text-xs bg-[#0062FF]'>
                                0
                            </span>
                        </li>


                        <li className={`pb-1 pl-1 mr-5 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`}>Pending
                            <span style={{ width: "16px", height: "16px" }} className='inline-block rounded-full text-[#111928] mx-2 px-1 text-xs bg-[#0062FF]'>
                                1
                            </span>
                        </li>
                        <li className={`pb-1 pl-1 mr-5 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`}>Cancelled
                            <span style={{ width: "16px", height: "16px" }} className=' inline-block rounded-full text-[#111928] mx-2 px-1 text-xs bg-[#0062FF]'>
                                1
                            </span>
                        </li>
                        <li className={`pb-1 pl-1 mr-5 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`}>Past
                            <span style={{ width: "16px", height: "16px" }} className='inline-block rounded-full text-[#111928] mx-2 px-1 text-xs bg-[#0062FF]'>
                                1
                            </span>
                        </li>


                    </ul>
                </div>



               
            </div>
        </div>
    )
}

export default ServiceAndVenuesBottom;