import React from 'react'
import NavbarMain from '../components/NavbarMain'
import { useState } from 'react'
import { Button } from 'flowbite-react';
import '../pages/userlanding.css'

const GoProPage = () => {
  const [isopen, setisopen] = useState(true);
  const [monthly, setmonthly] = useState(true);
  const [yearly, setyearly] = useState(false);
  const month = () => {
    setmonthly(true)
    setyearly(false)
  }
  const year = () => {
    setmonthly(false)
    setyearly(true)
  }
  const menuopen = async () => {
    setisopen(!isopen);
  }

  const recommandstyle = {
    display: "inline-flex",
    padding: '2px 10px',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '43px',
    background: 'linear-gradient(92deg, #FF5678 -6.79%, #4757EC 109.1%)',
    width: "132px",
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '14px'
  }


  const cardstyles = {
    borderRadius: '32px',
    border: '1px solid var(--gray-700, #374151)',
    background: 'linear-gradient(180deg, rgba(29, 85, 136, 0.35) 0%, rgba(8, 70, 142, 0.13) 100%)',
    boxShadow: '0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
    backdropFilter: 'blur(5px)'
  }
  return (
    <div className='w-full '>
      <NavbarMain />
      <section class=" sm:mx-0 sm:px-4 pt-40 sm:pt-20 userlanding ">
        <div class="py-8 px-24 sm:px-0 sm:mx-0 mx-auto lg:py-16 lg:px-6 ">
          <div class="mx-auto sm:mx-0 max-w-screen-md text-center mb-8 lg:mb-12">
            <h2 class="mb-4 text-4xl tracking-tight font-['Inter'] font-[500] text-white">Choose the right plan for you</h2>
            <p class="mb-5 font-normal  text-xl text-gray-400">Discover the perfect subscription plan tailored to your needs </p>
            <div className='w-wrap flex flex-row  justify-center text-center mb-8 lg:mb-12 py-3'>
              <div className='bg-[#283A57] flex rounded-full'>
                <Button onClick={month} className={`${monthly ? "bg-[#045BD8]" : "bg-[#283A57]"} rounded-full px-7 border-none focus:border-none focus:ring-0 py-2 text-sm font-inter font-normal  text-white`}>Monthly</Button>
                <Button onClick={year} className={`${yearly ? "bg-[#045BD8]" : "bg-[#283A57"}  rounded-full px-7 border-none py-2 focus:border-none focus:ring-0 text-sm font-inter font-normal  text-white`}>Yearly</Button>
              </div></div>
          </div>
          <div className=" grid grid-cols-3 gap-10 font-inter md:grid-cols-1 sm:grid-cols-1 items-center sm:gap-6 xl:gap-10 ">

            <div class="flex flex-col px-12 py-6 sm:px-0  max-w-lg text-center  rounded-lg border  xl:p-8 text-white " style={cardstyles}>
              <h3 class="mb-4 text-2xl font-semibold">Free</h3>
              <p class=" text-light sm:text-lg text-[#6CA2FF]">Limited to 1 events per month</p>
              {monthly && <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$0</span>
                <span class="text-gray-500">/month</span>
              </div>}
              {yearly && <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$0</span>
                <span class="text-gray-500">/Year</span>
              </div>}

              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Create and Manage Free or Paid Events </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Guest list management </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Ticket sales and authentication </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Text invitations  </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Live Alerts </span>
                </li>

              </ul>
              <a href="/usersignin" class="text-white bg-[#4B5563] hover:bg-gray-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">Get started</a>
            </div>

            <div class="flex flex-col px-12 py-6  max-w-lg text-center  rounded-lg border  xl:p-8 text-white " style={cardstyles}>
              <h3 class="mb-4 text-2xl font-semibold">Pro</h3>
              <button style={recommandstyle}>Recommended</button>
              <p class=" text-light sm:text-lg text-[#6CA2FF]">Limited to 4 event per month </p>
              {monthly && <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$22</span>
                <span class="text-gray-500">/month</span>
              </div>}
              {yearly && <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$224.40</span>
                <span class="text-gray-500">/year</span>
              </div>}

              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>All Starter features</span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Access to all premium add-ons </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Priority Customer Service </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Enhanced Analytics Reporting </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Utilize Doc-Upload from your guest </span>
                </li>

              </ul>
              <a href="/usersignin" class="text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">Get started</a>
            </div>

            <div class="flex flex-col px-12 py-6  max-w-lg text-center  rounded-lg border  xl:p-8 text-white " style={cardstyles}>
              <h3 class="mb-4 text-2xl font-semibold">Enterprise</h3>
              <p class="text-light sm:text-lg text-[#6CA2FF]">Embrace event hosting excellence</p>
              {monthly && <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$77</span>
                <span class="text-gray-500">/month</span>
              </div>}
              {yearly && <div class="flex justify-center items-baseline my-8">
                <span class="mr-2 text-5xl font-extrabold">$785.40</span>
                <span class="text-gray-500">/year</span>
              </div>}

              <ul role="list" class="mb-8 space-y-4 text-left">
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span>All Pro features</span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Advanced Marketing Tools</span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Access to Exclusive Event Themes</span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Priority Access to New Features </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-[14px]'>Special boost pricing</span>
                </li>

              </ul>
              <a href="/usersignin" class="text-white bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900">Get started</a>
            </div>
          </div>
          <button type="button" onClick={menuopen} class="flex mt-12 justify-center items-center mx-auto font-medium text-gray-600 dark-text-gray-400 hover:text-gray-700" >
            Show Detailed Plan Comparison
            <svg class="ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
          {isopen ?
            <div class=" pt-8 p-12 sm:pt-8 sm:p-0 w-full pricebg ">
              <div class=" w-full">
                <div class="grid grid-cols-4 gap-x-16 sm:gap-x-8 p-4 sm:p-0 text-sm font-medium  border-t border-b  bg-gray-800 border-gray-700 text-white">
                  <div class="flex items-center">Features</div>
                  <div>Free</div>
                  <div>Pro</div>
                  <div>Enterprise</div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 sm:gap-x-8 py-5 sm:px-0 sm:pt-4 px-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-400">Create and Manage Events </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4 sm:gap-x-8 sm:px-0 sm:pt-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-400">Guest List Management </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-400">Ticket Sales and Authentication </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-400">Text Invitations  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>

                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm text-gray-700 border-gray-700">
                  <div class="text-gray-400">Send Live Alerts </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>

                </div>
                <div class="grid grid-cols-4 gap-x-16 p-4 text-sm font-medium  sm:gap-x-8  sm:pt-4  bg-gray-800 border-gray-700 text-white">
                  <div class="flex items-center">Premium</div>
                  {/* <div>Community Edition</div>
                <div>Developer Edition</div>
                <div>Designer Edition</div> */}
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5  sm:gap-x-8 sm:px-0 sm:pt-4 px-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-400">Enhanced Analytics and Reporting</div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm  border-b  border-gray-700">
                  <div class="text-gray-400">Access to All Add-Ons  </div>
                  <div>
                    <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm  border-b border-gray-700">
                  <div class="text-gray-400">Attendee Doc-upload  </div>
                  <div>
                    <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4 text-sm  sm:gap-x-8 sm:px-0 sm:pt-4  border-b border-gray-700">
                  <div class="text-gray-400">Marketing Suite</div>
                  <div>
                    <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 p-4 text-sm font-medium  sm:gap-x-8  sm:pt-4  border-b bg-gray-800 border-gray-700 text-white">
                  <div class="flex items-center">Support</div>
                  {/* <div>Community Edition</div>
                <div>Developer Edition</div>
                <div>Designer Edition</div> */}
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm  border-b border-gray-700">
                  <div class="text-gray-500 dark:text-gray-400">Web and Mobile Support</div>
                  <div>
                    <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-500 dark:text-gray-400">Priority Customer Service</div>
                  <div>
                    <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                    <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4  sm:gap-x-8 sm:px-0 sm:pt-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-500 dark:text-gray-400">Priority Support </div>
                  <div>
                    <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                  <svg class="w-6 h-6 text-red-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path>
                    </svg>
                  </div>
                  <div>
                   1 Year
                  </div>
                </div>
                <div class="grid grid-cols-4 gap-x-16 py-5 px-4 sm:px-0 sm:gap-x-8 sm:px-0 sm:pt-4 text-sm text-gray-700 border-b border-gray-700">
                  <div class="text-gray-400"></div>
                  <div>
                    <a href="#" class="text-white block w-full bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm sm:text-xs px-5 py-2.5 text-center dark:focus:ring-primary-900 sm:px-0">Get Started</a>
                  </div>
                  <div>
                    <a href="#" class="text-white block w-full bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm sm:text-xs px-5 py-2.5 text-center dark:focus:ring-primary-900 sm:px-0">Get Started</a>
                  </div>
                  <div>
                    <a href="#" class="text-white block w-full bg-gray-600 hover:bg-gray-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm sm:text-xs px-5 py-2.5 text-center dark:focus:ring-primary-900 sm:px-0">Get Started</a>
                  </div>
                </div>
              </div>
            </div>
            : <div></div>}
        </div>
      </section>
    </div>
  )
}

export default GoProPage