import React, { useEffect, useState } from 'react'
import EnterComponent from './EnterComponent'
import { useNavigate } from 'react-router-dom'
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { motion } from "framer-motion";
import bcrypt from 'bcryptjs'
import Swal from 'sweetalert2';
const UserPasswordreset = () => {
  const navigate = useNavigate()
  const [passwoord, setPasswoord] = useState("");
  const [id, setid] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState()
  const [passwords, setPasswords] = useState("");
  const [getuser, setgetuser] = useState();
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [alert, setAlert] = useState("");
  const [fieldss, setFieldss] = useState(false);
  const [msg, setMsg] = useState("");
  const [emaill, setEmaill] = useState("");
  const [email, setEmail] = useState("");
  const [field, setField] = useState(false);
  const getemails = [];
  const getid = [];
  const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
  const storedValue = window.localStorage.getItem('emailForuserpassword');
  //console.log(storedValue)
  const getAllUser = async () => {
    const user = await getDocs(
      query(collection(firestore, "User"), orderBy("id", "desc"))
    );

    setgetuser(user.docs.map((doc) => doc.data()));
    // //console.log(getuser)
  };
  const cemail = (e) => {
    setEmail(e.target.value)
    // const { name, value } = e.target;
    // setUserData({ ...userData, [name]: value });
    var data = getuser?.filter(val => val.email === e.target.value)
    //console.log(data);
    getemails.push(data[0]?.email)
    getid.push(data[0]?.id)

    setid(data[0]?.id)

    //console.log(getemails, getid[0], id);
    if (getemails[0] !== e.target.value) {
      //   setMsg("Email Already Exist");
      setEmaill("email you entered is not exist")
      setField(true);
      setAlertStatus("danger");

      //console.log("email exist")
      setTimeout(() => {
        setField(false);
      }, 10000);

    }
    else {
      // setEmaill("email")
      //console.log("email did not exist")
    }



  }

  const passwordvalidate = (e) => {

    setPassword(e.target.value);

    var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    var test = reg.test(e.target.value);

    if (test) {
      //console.log("pass")
      setPasswords("Strong")
      setAlertStatus("success");
      setFieldss(true);

      //   setTimeout(() => {
      //       setFieldss(false);
      //     }, 4000);
    } else {
      setPasswords("Weak")
      setAlertStatus("danger");
      setFieldss(true);

      //   setTimeout(() => {
      //       setFieldss(false);
      //     }, 8000);
    }
  }

  const cpassword = (e) => {
    setconfirmpassword(e.target.value)

    if (e.target.value !== password) {
      setPasswoord("Password did not matched")
      setFields(true);
      setAlert("danger");

    }
    else {
      setPasswoord("Password  matched")
      setAlert("success");
      setFields(true);

      setTimeout(() => {
        setFields(false);
      }, 8000);
    }

  }

  const updatepassword = async (uid) => {
    //console.log(uid)
    try {
      const q = doc(firestore, "User", uid);
      // const qrf = query(q, where("email", "==", email));

      // //console.log(qrf)
      await updateDoc(q, {
        password: hashedPassword,

      })

        //
        .then(() => {
          Swal.fire({
            title: ' Password reset Succesfully ',


            confirmButtonText: 'ok',
            confirmButtonColor: '#374151',
          })
          // 
          navigate("/usersignin")
        })
        .catch((error) => {
          alert(error)
          //console.log(error)
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });
    }
    catch (e) {
      //console.log(e)
    }
  }

  useEffect(() => {

    // fetchuserData();
    getAllUser();


  }, []);
  return (
    <div>
      <section className=" flex flex-row bg-img bg-no-repeat h-screen bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-30 ">

        <div className='flex flex-col ml-[72px]  justify-center w-[50%]  xl:w-[60%] lg:w-[60%] md:hidden sm:hidden'>
          <EnterComponent />
        </div>
        <div className="w-[50%] xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
          <div
            id="defaultpasswordModal"

          >
            <div className=" flex flex-col  px-6 py-8 mx-auto md:h-screen pt:mt-0">
              <p className="text-sm font-light mb-[130px] md:mb-[100px] sm:hidden sm:mb-[110px] ml-[68%] xl:ml-[65%] lg:ml-[40%] md:ml-[70%] sm:ml-[30%] text-gray-500 dark:text-gray-300">
                <label for="newsletter" className="font-medium text-sm text-[#F9FAFB] font-['Inter'] dark:text-gray-300">Become a  <a className="font-medium text-primary-600 underline hover:underline dark:text-primary-500" href="/servicelandingpage">Service Provider</a></label>
              </p>
              <div>

                <div className="w-[70%] md:w-[100%] ml-20 sm:ml-0 sm:w-[100%] justify-center align-center  bg-white rounded-lg shadow xl:max-w-full md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                  <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                    <h2 className="text-xl font-bold justify-center align-center leading-tight tracking-tight  text-gray-900 md:text-2xl dark:text-white">
                      Forgot Your Password?
                    </h2>
                    <div>
                      <label for="newpassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
                      {/* <input type="password" onChange={e => passwordvalidate(e)} value={password} name="new-password" id="new-password" placeholder="Password" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required=""/> */}
                      <input type="password" name="password" id="password" value={password} onChange={e => passwordvalidate(e)} placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${fieldss && alertStatus === "danger"
                        ? "  bg-red-50 border border-red-500 text-red-900 placeholder-red-700"
                        : "border-gray-300 "
                        }`} required="" />
                      {fieldss && (alertStatus === "danger" ? <div className=' mt-2 w-full h-2 bg-transparent border-red-800'> <div className='w-[20%] h-2 bg-red-800'></div></div> : <div className=' mt-2 w-full h-2 bg-green-800'></div>)}
                      {fieldss && (

                        <p class={`mt-2 text-sm ${alertStatus === "danger"
                          ? " text-red-800 "
                          : " text-emerald-800"
                          }`}>{passwords}</p>)}
                     
                    </div>
                    <div>
                      <label for="confirmpassword" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm Password</label>
                      <input type="password" name="confirm-password" onChange={e => cpassword(e)} value={confirmpassword} id="confirm-password" placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${fields && alert === "danger"
                        ? "  bg-red-50 border border-red-500 text-red-900 placeholder-red-700"
                        : "border-gray-300 "
                        }`} required="" />
                      {fields && (<p class={`mt-2 text-sm ${alert === "danger"
                        ? " text-red-800"
                        : " text-emerald-800"
                        }`}>
                        {passwoord}
                      </p>)}
                    </div>


                    {getuser?.map((item) => (
                      item.email === storedValue && (
                        <button type="submit" onClick={() => updatepassword(item.id)} className="w-full text-white bg-[#374151] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Reset Password</button>
                      )))}

                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default UserPasswordreset