import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";


export const saveUser = async (data) => {
  await setDoc(doc(firestore, "User", `${Date.now()}`), data, {
    merge: true,
  });

  const emailData = {
    email: data.email,
    firstName: data.name,
    lastName: '',
    phone: data.phone,
    tag: "User"
  }
  await setDoc(doc(firestore, "EmailMarketing", `${Date.now()}`), emailData, {
    merge: true,
  });
};

export const saveServiceprovider = async (data) => {
  //console.log("Serviceprovider Data", data);

  await setDoc(doc(firestore, "Serviceprovider", `${Date.now()}`), data, {
    merge: true,
  });

  const emailData = {
    email: data.email,
    firstName: data.fname,
    lastName: data.fname,
    phone: data.phone ? data.phone : "",
    tag: "ServiceProvider"
  }
  await setDoc(doc(firestore, "EmailMarketing", `${Date.now()}`), emailData, {
    merge: true,
  });

};






