import React, { useEffect, useState } from 'react'
//import { motion } from "framer-motion";
import entrlogo from "../img/entrlogoblack.svg"
import EnterComponent from './EnterComponent'
//import { sendEmailVerification, onAuthStateChanged, signInWithEmailAndPassword, signInWithCredential, PhoneAuthProvider, createUserWithEmailAndPassword } from "firebase/auth";
import { firestore, actionCodeSetting } from "../firebase";
import Swal from 'sweetalert2';
import { sendSignInLinkToEmail } from 'firebase/auth';
//import { Link, Navigate } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { useNavigate } from "react-router-dom";
import bcrypt from 'bcryptjs'
import { Formik, Form } from 'formik';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { useAuth } from "../context/auth";
import {
  collection,
  getDocs,
  orderBy,
  query,

} from "firebase/firestore";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import TimerComponent from '../components/TimerComponent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import CustomError from '../components/CustomError/CustomError';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const UserLogin = () => {
  const auth = getAuth();
  const [concatenatedString, setConcatenatedString] = useState('');
  const [useropen, setuseropen] = useState(true);
  const [verifyuser, setverifyuser] = useState(false)
  // const [opensetpassword, setopenAddsetpassword] = useState(true);
  // const [verificationCode, setVerificationCode] = useState('');
  const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signinpassword, setsigninPassword] = useState(false);
  const [signuppassword, setsignupPassword] = useState(false);
  //const firebaseAuth = getAuth(app);
  //const provider = new GoogleAuthProvider();
  const [getuser, setgetuser] = useState();
  const [passwords, setPasswords] = useState("");
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  //const [getemailuser, setgetemailuser] = useState([]);
  const [confirmation, setConfirmation] = useState();
  const { login } = useAuth();
  const [{ user }, dispatch] = useStateValue();
  //const [isMenu, setIsMenu] = useState(false);
  const [err, setErr] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [otpError, setOtpError] = useState("");

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };


  const getAllUser = async () => {
    const user = await getDocs(
      query(collection(firestore, "User"), orderBy("id", "desc"))
    );

    setgetuser(user.docs.map((doc) => doc.data()));
    // //console.log(getuser)
  };


  // //console.log(getuser)

  const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')

  const getemail = [];

  const sendVerificationCode = () => {

    try {

      setOpen(true);

      const auth = getAuth();
      // const phoneNumberWithCountryCode = '+1' + phoneNumber; // Add the country code prefix

      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier('submit-phone-no', {
          'size': 'invisible',
          'callback': (response) => {
            onSignInSubmit();
          }
        }, auth);


      }

      const appVerifier = window.recaptchaVerifier;

      const onSignInSubmit = signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          window.localStorage.setItem('phoneno', phone);
          window.localStorage.setItem('password', hashedPassword);
         
          window.confirmationResult = confirmationResult;
          setConfirmation(confirmationResult);

          setverifyuser(!verifyuser)
          setuseropen(!useropen)
          // ...
          setOpen(false);
        })
        .catch((error) => {
          setOpen(false);
          setPhoneNumberError(error.message);
        });
      // The verification code has been sent to the user's phone number
    } catch (error) {

    }
  };

  const signIn = (e) => {

    setOpen(true);

    var data = getuser?.filter(val => val.phone === phone)
    // //console.log(data); 
    getemail.push(data[0])
    // //console.log(getemail); 
    {
      getuser?.map((val, ind) => {
        if (
          phone === val.phone &&
          hashedPassword === val.password

        ) {
          //console.log("true")
          dispatch({
            type: actionType.SET_USER,
            user: getemail[0],
          });
          login("user");
          localStorage.setItem("user", JSON.stringify(getemail[0]));
          //console.log(getemail[0]);

          setErr(false);
          //console.log("login");

          setOpen(false);

          navigate("/userdashboardmain", { replace: true });

        }
        else
          if (phone !== val.phone &&
            hashedPassword !== val.password) {
            //console.log("false")
            setOpen(false);


            setPasswordError("Password incorrect");

          }
      })
    }
  };


  const handleResendOtp = () => {
    navigate(0);
  }


  const passwordvalidate = (e) => {
    setPassword(e.target.value);


    var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    var test = reg.test(e.target.value);

    {
      getuser?.map((val, ind) => {

        if (
          email === val.email &&
          hashedPassword === val.password

        ) {
          //console.log("abcdef")
          setPasswords("")
          setAlertStatus("");
          setFields(false);
        }
        else {
          if (test) {
            //console.log("pass")
            setPasswords("Strong")
            setAlertStatus("success");
            setFields(true);

          
          } else {
            setPasswords("Weak")
            setAlertStatus("danger");
            setFields(true);
          }
        }
      })
    }
  }
  const verifyphone = async () => {
    setOpen(true);
    setConcatenatedString(otp);
    try {

      await confirmation
        .confirm(otp)
      // .then(() => {
      // Phone number successfully verified

      navigate("/usersignup", { replace: true });
      setOpen(false);
    } catch (e) {
      console.error(e);
      setOpen(false);
      setOtpError(e.message);
    }
  }
  const initialValues = {
    phone: '',
  };


  const phoneexist = () => {

    const phoneRegex = /^\+[1-9]\d{1,14}$/;

    var phonetest = phoneRegex.test(phone);

    if (phone && !phonetest) {
      setPhoneNumberError("Invalid phone number");
      return;
    }

    if (phone.length < 7) {
      setPhoneNumberError("Phone number too short");
      return;
    }

    var data = getuser?.filter(val => val.phone === phone)
    // //console.log(data); 
    getemail.push(data[0])

    if (
      getemail[0]
    ) {
      //console.log("phone exist")
      setsigninPassword(true)
      setsignupPassword(false)
    }
    else {
      //console.log("phone did not exist")
      sendVerificationCode();
    }

  }


  const signup = async () => {

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSetting)
        .then(() => {
          Swal.fire({
            title: '  Email is sent to your email account. ',
            confirmButtonText: 'ok',
            confirmButtonColor: '#374151',
          })
          clearData();
          window.localStorage.setItem('userSignIn', email);
          window.localStorage.setItem('password', hashedPassword);
          const storedValue = window.localStorage.getItem('password');

          //console.log(storedValue);
          // ...
        })
        .catch((error) => {
          // alert(error)
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });


    }
    catch (e) {
    }
  };
  const clearData = () => {

    setEmail("");
    setPassword("");

  };

  const storedValue = window.localStorage.getItem('confirmationResult');
 
  const handlePhoneChange = (value) => {
    //console.log("Phone Value: ", value);
    setPhone(value);
  };

  useEffect(() => {
    getAllUser();
  }, []);

  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""])
  const handleInputChange = async (e, index) => {
    const { value } = e.target;
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    // Shift focus to the next input box
    if (value && index < otpValues.length - 1) {
      const nextInput = e.target.nextSibling;
      if (nextInput) {
        nextInput.focus();
      }
    }
    else if (value.length === 6) {
      // else{
      const otp = otpValues.join("");

      try {
        //  const otp = otpValues.join("");
        //  //console.log(otp)
        await confirmation
          .confirm(otp)

        navigate("/usersignup", { replace: true });
      } catch (e) {
        console.error(e);
        Swal.fire({
          title: "Invalid Code",
          confirmButtonText: 'ok',
          confirmButtonColor: '#374151',

        })

      }

    }
  };

  const otp = otpValues.join("");

  //console.log(otp);
  const [timeout, settimeout] = useState(true)
  const handleTimeout = async () => {
    settimeout(false);


  };


  const handlePhoneNoFocus = () => {
    setPhoneNumberError("");
  }

  const handlePasswordFocus = () => {
    setPasswordError("");
  }



  return (
    <section className=" flex flex-row lg:flex-row md:flex-col h-screen max-w-full   sm:flex-col  bg-img bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-30 ">
      <div className='flex flex-col ml-[80px]  justify-center w-[50%]  xl:w-[55%] lg:w-[60%] md:hidden sm:hidden '>
        <EnterComponent />
      </div>
      {useropen &&
        <div className="w-[50%] xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">

          <div className=" flex flex-col  px-6 py-8 mx-auto md:h-screen pt:mt-0">

            <p className="text-sm sm:hidden font-light mb-[140px] sm:mb-[130px] ml-[68%] xl:ml-[60%] lg:ml-[60%] md:ml-[55%] sm:ml-[45%] text-gray-500 dark:text-gray-300">
              <label for="newsletter" className="font-medium text-sm text-[#F9FAFB] font-['Inter'] dark:text-gray-300">Become a  <a className="font-medium text-primary-600 underline hover:underline dark:text-primary-500" href="/servicelandingpage">Service Provider</a></label>
            </p>
            <div>
              <div className="w-[80%] md:w-[100%]  sm:w-[100%] ml-[12%] sm:ml-0 bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                <div className="px-8 py-12 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                  <a href="/*" class=" items-center justify-center hidden sm:flex">
                    <img src={entrlogo} class="h-8 mr-3" alt="Flowbite Logo" />
                    <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Entr</span>
                  </a>
                  <h2 className="text-xl font-bold leading-tight tracking-tight  text-gray-900 md:text-2xl dark:text-white">
                    Login / Sign up
                  </h2>
                  {/* <form className="space-y-4 md:space-y-6" action="#"> */}
                  <Formik
                    initialValues={initialValues}
                    validate={(values) => {
                      ////console.log(values);
                      const errors = {};
                      if (!phone) {
                        errors.phone = "Required";
                      }
                      const phoneRegex = /^\+[1-9]\d{1,14}$/;
                      var phonetest = phoneRegex.test(phone);
                      if (phone && !phonetest) {
                        errors.phone = "invalid phone number"
                      }
                      return errors;
                    }}
                    onSubmit={signIn} >
                    <Form>
                      <div className="grid gap-5 my-3 grid-cols-1 sm:grid-cols-1">
                        <div className=''>
                          <label for="phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white c-label">Email / Phone No</label>
                          <div className='bg-gray-50 border border-gray-300 flex flex-row rounded-lg'>
                            <PhoneInput className="bg-gray-50  text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
                            c-error
                            "
                              defaultCountry="US"
                              id="phone"
                              name="phone"
                              value={phone}
                              onChange={(e) => handlePhoneChange(e)}
                              placeholder="Enter phone number"
                              international
                              onFocus={() => handlePhoneNoFocus()}
                            />
                            <button className='flex justify-cntent-center' onClick={phoneexist} type="button" id='submit-phone-no'>
                              <svg className="cursor-pointer w-10 h-5 mt-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </button>
                          </div>
                          {/* <ErrorMessage name="phone" className='text-red-600' component="div" /> */}
                          {
                            phoneNumberError.length > 0 &&
                            <div className='w-full flex justify-center items-center'>
                              <CustomError className="flex justify-center items-center" message={phoneNumberError} />
                            </div>
                          }
                        </div>

                        {signinpassword &&
                          <>
                            <div>

                              <input type="password" name="password" id="password" value={password} onChange={e => passwordvalidate(e)} onFocus={() => handlePasswordFocus()} placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white border-gray-300 
                              `} required="" />

                              {
                                passwordError.length > 0 &&
                                <div className='w-full flex justify-center items-center'>
                                  <CustomError message={passwordError} />
                                </div>
                              }


                            </div>

                            <button type="submit" className="w-full text-white bg-[#374151] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            //  onClick={signIn}
                            >Sign In</button>
                          </>
                        }
                        {signuppassword &&
                          <>
                            <div>

                              <input type="password" name="password" id="password" value={password} onChange={e => passwordvalidate(e)} placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${fields && alertStatus === "danger"
                                ? "  bg-red-50 border border-red-500 text-red-900 placeholder-red-700"
                                : "border-gray-300 "
                                }`} required="" />
                              {fields && (alertStatus === "danger" ? <div className=' mt-2 w-full h-2 bg-transparent border-red-800'> <div className='w-[20%] h-2 bg-red-800'></div></div> : <div className=' mt-2 w-full h-2 bg-green-800'></div>)}
                              {fields && (

                                <p class={`mt-2 text-sm ${alertStatus === "danger"
                                  ? " text-red-800 "
                                  : " text-emerald-800"
                                  }`}>{passwords}</p>)}

                            </div>

                            <button type="submit" className="w-full text-white bg-[#374151] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                            //  onClick={signIn}
                            >Sign Up</button>
                          </>
                        }
                      </div>
                    </Form>
                  </Formik>


                  <div className="flex items-start">

                    <div className=" text-sm">
                      <label for="newsletter" className="font-light text-gray-500 dark:text-gray-300">By Signing in you accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="/termandcondition">Terms and Conditions</a></label>
                    </div>
                  </div>
                  <p className="text-sm font-light  text-gray-500 dark:text-gray-300">
                    <label for="newsletter" className="font-light text-gray-500 dark:text-gray-300">Forget your Password? <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="/userpassres">Reset</a></label>
                  </p>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {verifyuser &&
        <div className="w-[50%] xl:w-[50%] lg:w-[50%] md:w-[100%] sm:w-[100%]">
          <div className=" flex flex-col  px-6 py-8 mx-auto md:h-screen pt:mt-0 ">
            <p className="text-sm sm:hidden font-light mb-[150px] sm:mb-[130px] ml-[68%] xl:ml-[75%] lg:ml-[65%] md:ml-[60%] sm:ml-[45%] text-gray-500 dark:text-gray-300">
              <label for="newsletter" className="font-medium text-sm text-[#F9FAFB] font-['Inter'] dark:text-gray-300">Become a  <a className="font-medium text-primary-600 underline hover:underline dark:text-primary-500" href="/servicelandingpage">Service Provider</a></label>
            </p>
            <div>
              <div className="w-[70%] md:w-[100%]  sm:w-[100%] ml-32 sm:ml-0 justify-center align-center  bg-white rounded-lg shadow xl:max-w-full md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800">
                <div className="p-6 space-y-4 md:space-y-6 lg:space-y-8 sm:p-8">
                  <h2 className="text-xl font-bold justify-center align-center leading-tight tracking-tight  text-gray-900 md:text-2xl dark:text-white">
                    Verify Your Number
                  </h2>

                  {
                    timeout ?

                      <p className='text-gray-500 text-center font-["Inter"] text-sm font-semibold'>We texted you a six-digit code to <b>{phone}</b>.  Enter the code below to confirm your Contact No.</p>
                      :
                      <p className='text-gray-500 text-center font-["Inter"] text-sm font-semibold'>Otp time limit exceeded, Please Try Again</p>
                  }
                  {
                    timeout ?
                      <TimerComponent duration={120} onTimeout={handleTimeout} className='text-gray-500 text-center font-["Inter"] text-sm font-semibold' />
                      :
                      <div className='text-gray-500 text-center font-["Inter"] text-sm font-semibold cursor-pointer' onClick={() => handleResendOtp()}>Re-send OTP</div>
                  }
                  <div className='flex flex-row gap-2'>
                    {timeout && otpValues.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        value={value}
                        onChange={(e) => handleInputChange(e, index)}
                        maxLength={1}
                        autoFocus={index === 0} // Set autoFocus to true for the first input box
                        className="bg-gray-50  text-center border border-gray-300 text-gray-900 sm:text-lg rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full px-2 py-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      />
                    ))}
                  </div>

                  {
                    timeout && otpError.length > 0 &&
                    <div className='w-full flex justify-center items-center'>
                      <CustomError message={otpError} />
                    </div>
                  }

                  {
                    timeout &&
                    <>
                      <p className='text-gray-500  font-["Inter"] text-sm font-normal'>Make sure to keep this window open while check your inbox</p>

                      <button type="submit" onClick={verifyphone} className="w-full text-white bg-[#374151] hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Verify</button>

                    </>

                  }





                  <div className="flex items-start">


                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </section>
  );
}

export default UserLogin