import React from 'react'
import img1 from "../../img/restaurant.svg"
import img2 from "../../img/landingpageimages/img3.png"

const Section4 = () => {
  const btnstyle = {
    borderRadius: '90px',
    border: ' 1px solid rgba(128, 149, 204, 0.65)',
    boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25);',
    width: ' 161px',
    height: '41px',
    padding: ' 14px 16px',
    color: '#F9FAFB',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
  return (
    <div>
      <section class="  py-20 px-10 sm:py-10 sm:px-10 sm:px-4">
        <div class="gap-16 items-center font-inter py-8 px-4 sm:py-0 sm:px-0 mx-auto 1 lg:py-16 lg:px-6 flex flex-col items-center">

          <div class="font-light text-gray-500 mx-10 sm:mx-0 sm:text-lg dark:text-gray-400">
            <h2 class="mb-4 text-4xl font-[500] leading-[56px] tracking-tight font-['Inter'] font-extrabold text-white w-[55%] mx-auto sm:w-[100%] sm:text-2xl text-center sm:text-center">Give your guests the
              best experience with Entr</h2>
            <p class="mb-4 text-md font-normal font-['Inter'] text-center sm:text-justify text-white sm:mb-2"> Perfect for adding personalized touches like live entertainment, photo booths, cocktail crafting stations and more</p>
          </div>
          <div class="flex justify-center ">
            <img class="w-[70%] sm:w-[100%] object-fill" src={img2} alt="office content 1" />
          </div>
          <a href="#" style={btnstyle}>
            Get Started
          </a>
        </div>
      </section>
    </div>
  )
}

export default Section4