import React, { useState } from 'react'
import { thingspdata } from './Thingsdata'
import './hover.css'
import { Link } from 'react-router-dom'

const Thingpopup = ({handleThingsLeave }) => {
  return (
    <div className='flex bg-white py-20 pt-[10rem] fixed  z-[40]  overflow-x-0 w-[100%] transition-transform transform scale-100' onMouseLeave={handleThingsLeave}>
      <ul className='text-[16px] w-[35%] ml-14 flex flex-col gap-2'>
        <li className='text-[25px] text-black font-[500]'>Find things to do
          
        </li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Concerts</Link></li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Pop-ups</Link></li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Conferences</Link> </li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Sports</Link> </li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'> <Link to="/usersignin">Classes and Lessons</Link> </li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'> <Link to="/usersignin">Foodie</Link></li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Wellness and fitness</Link> </li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Flea Markets </Link></li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Live Entertainment</Link> </li>
        <li className='text-[16px] font-[500] pl-3 text-[#0062FF] cursor-pointer'> <Link to="/usersignin">View all</Link></li>
      </ul>

      <div className="tpopup6main w-[65%]">
        <h5 className='text-black text-[20px] font-[400 ] pb-3 mt-5'>Popular cities</h5>
        <div className='tpopup-container  overflow-x-scroll  whitespace-nowrap scroll-smooth scrollbar-hide tpopup-scroller5' >
          {
            thingspdata.map((data, index) => (
              <div className="tpopup6" key={index}>
                <img src={data.img} alt="" />
                <div className="t-description">
                  <h5 className='desc'>{data.name}</h5>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Thingpopup