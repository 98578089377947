import React, { useState, useEffect } from "react";
import entrlogo from "../../img/Entrlogo.svg";
import { EventCarousel } from "./Carousel";
import Searchbar1 from "./Searchbar1";
import Searchbar2 from "./Searchbar2";
import Searchbar3 from "./searchbar3";
import Section1Popup from "./Section1Popup";

const Section1 = () => {
  const [open, setopen] = useState(true);
  const [venues, setvenues] = useState(false);
  const [service, setservices] = useState(false);
  const [postsPerPage, setPostPerPage] = useState(8);
  const [findEvent, setFindEvent] = useState(false)
  const handleEvent = () => {
    setFindEvent(true)
  }
  const closeEvent = () => {
    setFindEvent(null)
  }

  const menuopen = async () => {
    setopen(true);
    setvenues(false);
    setservices(false);
  };
  const venuesopen = async () => {
    setopen(false);
    setvenues(true);
    setservices(false);
  };
  const serviceopen = async () => {
    setopen(false);
    setvenues(false);
    setservices(true);
  };

  if (window.matchMedia("max-width:800").matches) {
  }
  useEffect(() => {
    handleResize();

  }, []);
  const handleResize = () => {
    if (window.innerWidth < 800) {
      setPostPerPage(4);
    } else if (window.innerWidth > 1300) {
      setPostPerPage(12);
    } else {
      setPostPerPage(10);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>

      <section class="p-10 pt-28 sm:p-3 sm:py-7 sm:pt-28 w-full justify-center">
        <div class="justify-center items-center text-center">
          <a href="/" className="inline-block mb-6 my-4">
            <span className="flex  justify-center  items-center">
              <img src={entrlogo} class="h-16 mr-3" alt="Flowbite Logo" />
              <span class="self-center text-6xl sm:text-5xl font-semibold whitespace-nowrap text-white">
                Entr
              </span>
            </span>
          </a>
          <h2 class="mb-6 text-6xl lg:text-5xl sm:text-4xl font-['Inter'] leading-none  tracking-tight font-extrabold text-white dark:text-gray-700">
            Igniting Unforgettable Experiences
          </h2>
          <p class="mb-[22px] font-normal font-['Inter'] text-xl sm:text-xs text-gray-300 md:text-lg dark:text-gray-400">
            We make attending and hosting easy
          </p>
          <div
            class="items-center h-12  mb-4 font-['Inter'] justify-center w-full flex flex-row md:w-auto "
            id="mobile-menu-2"
          >
            <ul class="flex md:flex-row sm:flex-row justify-center text-xl font-medium font-['Inter'] p-4 md:p-0 ml-4  dark:border-gray-100 rounded-lg dark:bg-gray-50 flex-row md:space-x-8 md:mt-0 md:border-0 md:dark:bg-white ">
              <li>
                <button
                  onClick={menuopen}
                  class={`block my-2 ml-3 mr-4 sm:m-0 text-[#DEDEDE] ${open === true ? "border-b border-[#65A5FF]" : ""
                    }   focus:border-b focus:border-[#65A5FF] md:bg-transparent  md:p-0 md:dark:text-gray-200`}
                  aria-current="page"
                >
                  Event
                </button>
              </li>
              <li>
                <button
                  onClick={venuesopen}
                  class={`block my-2 ml-3 mr-4 sm:m-0 text-[#DEDEDE] ${venues === true ? "border-b border-[#65A5FF]" : ""
                    }   focus:border-b focus:border-[#65A5FF] md:bg-transparent  md:p-0 md:dark:text-gray-200`}
                >
                  Venues
                </button>
              </li>
              <li>
                <button
                  onClick={serviceopen}
                  class={`block my-2 ml-3 mr-4 sm:m-0 text-[#DEDEDE] ${service === true ? "border-b border-[#65A5FF]" : ""
                    }   focus:border-b focus:border-[#65A5FF] md:bg-transparent  md:p-0 md:dark:text-gray-200`}
                >
                  Services
                </button>
              </li>
            </ul>
          </div>

          <div class="items-center justify-center w-full sm:w-full px-7 sm:px-0  md:w-full flex flex-row">
            {open && (
              <Searchbar1 handleEvent={handleEvent} />
            )}
            {venues && (
              <Searchbar2 handleEvent={handleEvent} />
            )}
            {service && (
              <Searchbar3 handleEvent={handleEvent} />
            )}
          </div>
          {open || venues || service ? (
            <div className="w-full h-40 mt-12 sm:mx-3 flex flex-row justify-center items-center">
              <div id="container" className=" w-[100%] sm:w-full">
                <div className="slider-visibility w-full" >
                  <EventCarousel
                    eventType={open ? "events" : venues ? "venues" : "services"}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

      </section>

      {
        findEvent && (
          <Section1Popup closeEvent={closeEvent} className="absolute top-0" />
        )
      }
    </>
  );
};

export default Section1;
