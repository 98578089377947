import React from 'react'
import NavbarMain from '../NavbarMain'
import Footer from './Footer'

const Termsandconditions = () => {
  return (
    <div>
    <NavbarMain/>
       <section class="bg-gray-900 py-10 pt-20 w-full sm:px-5">
    <div class="px-4 py-8 mx-auto sm:mx-0  text-center sm:px-0 sm:py-0 lg:py-16 lg:px-12">
        <h1 class="mb-4 text-4xl sm:text-4xl font-extrabold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-6xl text-white ">Terms and Conditions</h1>
        <p class="mb-8 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-16 xl:px-48 text-gray-400">Last updated on January 29, 2022</p>
        <div class=" mb-8 lg:mb-16  sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <a href="/usersignin" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-900">
                Get Started
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          
            </a>
            
        </div>
        </div>
        <div class="mx-40 sm:mx-5 px-4 py-8 sm:px-0 sm:py-0 max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-2xl font-extrabold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-6xl text-white ">Introduction</h1>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Entr Inc. ("App", “we”, “us”, or “our”), concerning your access to and use of the https://entr.events website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “App”). </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason.  </p>
        <p class="mb-8 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.</p>
       
     
      </div>
      <div class="mx-40 sm:mx-5 px-4 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-6 text-2xl font-extrabold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-6xl text-white ">Terms and Conditions</h1>
           <p class="  font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">These Terms and Conditions ("Terms") constitute a legal agreement between you ("User") and Entr Inc. ("Entr," "we," "us," or "our") governing your use of the Entr Inc. event management app ("App") and related services. By using the App, you agree to be bound by these Terms.</p>
      
     
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-8 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">Definitions:</h1>
       <ol style={{listStyle: "loweralpha"}} className='ml-6'>
        <li class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">"Hosts": Users who create and host events, use the platform for event management, and generate revenue through the App.</li>
        <li class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">"Attendees": Users who purchase tickets or RSVP for events and receive information from Hosts.</li>
        <li class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">"Service Providers": Users who list and provide event-related services or venues and earn revenue through the App.</li>
       </ol>
     
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">Services and Role:</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">2.1 What We Do:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Entr Inc. provides an event management app that enables Hosts to create, post, and manage online or in-person events, sell tickets, solicit donations, and connect with Attendees and Service Providers through a suite of marketing tools.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">2.2 How We Fit In:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Entr Inc. is not the creator, organizer, or owner of the events listed on the App, nor the seller of tickets, registrations, or any merchandise. We provide the App as a platform for Hosts to manage ticketing, registrations, and event promotion. Hosts are solely responsible for ensuring compliance with applicable laws, rules, and regulations and delivering the goods and services described on event pages accurately and satisfactorily.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">User Communication and Opt-In for Text Message Alerts:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">3.1 All Users of Entr Inc. can communicate with each other through the App. Additionally, Users have the option to opt-in for text message alerts related to their activities on the App.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">Disclaimers and Assumption of Risks:</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 4.1 Disclaimer of Warranties:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Entr Inc. strives to provide the App and services to meet your requirements, but we cannot guarantee certain outcomes. The App is provided on an "as is" and "as available" basis. We expressly disclaim all warranties, including but not limited to merchantability, title, non-infringement, and fitness for a particular purpose. We do not guarantee that the App will meet your expectations, be uninterrupted, timely, secure, error-free, or that the results obtained from its use will be accurate or reliable.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">4.2 Assumption of Risks:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Users acknowledge that participating in events may carry inherent risks, including but not limited to illness, bodily injury, disability, or death. By choosing to participate in events listed on the App, Users voluntarily assume these risks.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Licenses and Permits:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">5.1 Hosts are responsible for obtaining all applicable licenses, permits, and authorizations required for their events.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Scraping or Commercial Use of App Content:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">6.1 Scraping or commercial use of content from the App, including event listings, is strictly prohibited without prior written authorization from Entr Inc.</p>
      </div>


      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">Fees and Refunds:</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 7.1 Fees:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">While creating an account is free, Entr Inc. charges fees for listing events and for the purchase or sale of paid tickets or registrations. The specific fees may vary based on individual agreements between Entr Inc. and certain Hosts. Fees charged to Attendees may include facility fees, royalties, taxes, processing fees, and fulfillment fees. Additional fees from banks or credit card companies are not controlled by Entr Inc.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">7.2 Ticket Transfers and Refunds:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Users may have the option to transfer tickets for an event. Refund requests should be directed to the respective Host. Invalid tickets should not be used by Attendees, and Hosts must not accept refunded tickets.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">User Accounts: </h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 8.1 Account Creation and Accuracy:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Users are required to create an account to access certain features of the App. By creating an account, you confirm that you are at least 18 years old or of legal age in your jurisdiction. You must provide accurate and up-to-date information about yourself or your entity. You are responsible for maintaining the confidentiality of your account information and must notify us immediately of any unauthorized use or security breach.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">8.2 Permissions and Sub-Users:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Users may grant permissions to third parties, including sub-users, to access their accounts. Users are solely responsible for the activities conducted under their accounts, including actions by sub-users.</p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Feedback:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 9.1 Users are encouraged to provide feedback, comments, and suggestions regarding the App and services. By submitting feedback, Users grant Entr Inc. a non-exclusive, worldwide, royalty-free license to use, publish, and exploit the ideas and materials for any purpose, with or without attribution.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Governing Law and Jurisdiction: </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 10.1 These Terms are governed by the laws of the State of Delaware, United States. Any legal action against Entr Inc. related to the App and services will take place in the courts of Delaware.</p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Entire Agreement: </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 11.1 These Terms constitute the entire agreement between Users and Entr Inc. and supersede any prior agreements, proposals, or communications regarding the subject matter. In the event of a conflict between these Terms and a separate written agreement for specific events or services, the separate agreement shall prevail.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Modifications: </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 12.1 Entr Inc. reserves the right to modify these Terms at any time. Users will be notified of any significant changes. Continued use of the App after such modifications indicates acceptance of the modified Terms. </p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Severability: </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 13.1 If any provision of these Terms is deemed invalid or unenforceable, that provision shall be limited or eliminated to the minimum extent necessary, and the remaining provisions shall remain in full force and effect.</p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Contact Information: </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 14.1 For any questions or concerns regarding these Terms and Conditions, please contact Entr Inc. at [contact information]. </p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Accuracy of Postings:  </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 15.1 Users are responsible for ensuring the accuracy and completeness of any information, content, or materials they post on the Entr Inc. app. Entr Inc. does not guarantee the accuracy, reliability, or legality of any postings by Users. Users must ensure that their postings comply with all applicable laws, regulations, and contractual obligations.</p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">Fees: </h1>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.1 Entr Inc. charges fees for certain users through the App. These fees may include listing an event, buying/selling paid tickets or registrations, and other applicable charges. The specific fees may vary based on individual agreements between Entr Inc. and certain Organizers. Organizers have the discretion to determine whether these fees will be passed along to Consumers and shown as "Fees" on the event page or absorbed into the ticket/registration price paid by the Organizer. The fees charged to Consumers may include facility fees, royalties, taxes, processing fees, fulfillment fees, or other applicable charges. Please note that fees charged by banks or credit card companies, including fees for purchasing tickets/registrations in foreign currencies or from foreign entities, are not controlled by Entr Inc.</p>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.2 Service Fees: Entr Inc. charges service fees for the use of its event management app and related services. These fees may vary based on the specific services utilized and the agreements between Entr Inc. and the respective Users. The applicable fees will be clearly communicated to Users prior to utilizing the services.</p>
         <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.3 Payment Processing Fees:</p>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> In addition to service fees, Users may be subject to payment processing fees imposed by third-party payment service providers. These fees are not controlled by Entr Inc. and may vary based on the payment method and currency used. </p>
         <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.4 Taxes: </p>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> Users are responsible for any applicable taxes associated with the services provided by Entr Inc. or transactions conducted through the app. Users must comply with all tax obligations as per the relevant laws and regulations of their jurisdiction. </p>
         <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.5 Fee Modifications: </p>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> Entr Inc. reserves the right to modify its fees and fee structure at any time. Updated fees will be communicated to Users, and continued use of the app after such modifications indicates acceptance of the revised fees.  </p>
         <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.6 Ticket Transfers: </p>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> Users may have the option to transfer their purchased tickets to an event. In such cases, Users should contact the Organizer of the event to arrange for the ticket transfer. If Users are unable to reach the Organizer or encounter difficulties in arranging the transfer, they can reach out to Entr Inc. for possible assistance. </p>
         <p class="font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.7 Refunds:  </p>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> Consumers requesting a refund for tickets or registrations should contact the Organizer directly. Organizers are responsible for handling refund requests in accordance with their refund policies. Consumers must not use a ticket that has been refunded, and Organizers must not accept invalid tickets.  </p>
         <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">16.8 Income Tax Reporting:  </p>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> Users are solely responsible for reporting and paying any applicable taxes on the earnings they generate through the use of the Entr Inc. app. Entr Inc. does not provide tax advice or act as a tax agent for Users. Users should consult with their own tax advisors to understand and fulfill their tax obligations. </p>
    
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 "> Release and Limitation of Liability:</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">17.1 Release:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Users agree to release and hold Entr Inc., its officers, directors, employees, and agents harmless from any claims, liabilities, damages, losses, costs, or expenses (including reasonable attorneys' fees) arising out of or in connection with their attendance at or participation in any events listed on the App. Users acknowledge that Entr Inc. is not responsible for the acts or omissions of Hosts, Service Providers, or other Users.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">17.2 Limitation of Liability:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> To the extent permitted by applicable laws, Entr Inc. and its officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, goodwill, data, or use, arising from or in connection with the use of the App, participation in events, or interactions with other Users, even if advised of the possibility of such damages. In no event shall Entr Inc. have any liability to any User for all claims arising out of or related to these Terms. </p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 "> Intellectual Property:</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">18.1 Ownership:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Entr Inc. retains all rights, title, and interest in and to the Entr Inc. app, including all intellectual property rights associated with it. Users acknowledge that they do not acquire any ownership or intellectual property rights by using the app.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">18.2 User Content: </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> By posting or submitting any content on the Entr Inc. app, Users grant Entr Inc. a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform the content in connection with the operation and promotion of the app.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">Privacy:</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">19.1 Collection and Use of Personal Information:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Entr Inc. collects and processes personal information of Users in accordance with its Privacy Policy. By using the app, Users consent to the collection, use, and processing of their personal information as described in the Privacy Policy.</p>
         </div>

         <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">Termination:</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 20.1 Termination by Users:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Users may terminate their use of the Entr Inc. app at any time by closing their accounts and ceasing to access the app.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">20.2 Termination by Entr Inc.:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> Entr Inc. reserves the right to suspend, restrict, or terminate access to the app and services, in whole or in part, at its sole discretion, without prior notice or liability. </p>
      </div>

      <div class="mx-40 sm:mx-5 px-4  py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">

        <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Severability:</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">21.1 If any provision of these Terms is found to be invalid, illegal, or unenforceable, the remaining provisions shall remain in full force and effect.</p>
         </div>

         <div class="mx-40 sm:mx-5 px-4  py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
<p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Entire Agreement:</p>
<p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">22.1 These Terms constitute the entire agreement between Users and Entr Inc. regarding the use of the Entr Inc. app and supersede any prior agreements or understandings.</p>
 </div>

 <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
<p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">Contact Information:</p>
<p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">23.1 For any questions or concerns regarding these Terms and Conditions, please contact Entr Inc. at termsandconditions@entr.events.</p>
 </div>

        </section> 
        <Footer/>
    </div>
  )
}

export default Termsandconditions