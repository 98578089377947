import React from 'react'
import img1 from "../../img/landingpageimages/img2.png"
import './userlanding.css'
const Section2 = () => {
    return (
        <div className=' pt-[7rem] sm:pt-4  border-t border-gray-600 '>
            <section class=" px-20pb-20 sm:px-0 sm:py-5">
                <div class="gap-3 flex justify-between items-start gap-24 md:flex-col  sm:py-16 lg:px-6 sm:px-4 sec2userlanding ">
                    <div className="w-[580px] sm:w-[100%] h-[350px] sm:h-[100%] mt-[-3rem] text-left">
                        <img class="mb-4  sm:w-full sm:h-full md:w-full md:h-full lg:mb-0 rounded-lg object-cover" src={img1} alt="feature image" />
                    </div>
                    <div class="text-gray-400 sm:text-lg w-[40%] sm:w-[100%]  sm:ml-0 lg:ml-0">
                        <h2 class="mb-4 text-3xl mt-[1rem] font-['Inter'] tracking-tight font-extrabold text-white sm:text-2xl sm:text-center ">Unlock Monetization Opportunities,
                            Grow Revenue</h2>
                        <p class="mb-8 font-light lg:text-xl sm:text-md sm:text-center">Discover new ways to increase your event's revenue potential with our suite of host-centric tools and resources</p>
                        <div class="py-6 mb-4 border-t border-b border-gray-700">
                            <div class="flex sm:flex-col sm:items-center">
                                <div class="flex justify-center items-center mr-4 w-8 h-8 rounded-full bg-[#060609] dark:bg-primary-900 shrink-0 sm:mb-3">
                                    <svg class="w-5 h-5 text-gray-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div>
                                    <h3 class="mb-2 text-xl font-bold text-white sm:text-center">Ticket Sales and Authentication</h3>
                                    <p class="mb-2 font-light text-gray-400 sm:text-center">Ensure hassle-free ticket sales and attendee authentication</p>

                                </div>
                            </div>
                            <div class="flex sm:flex-col sm:items-center sm:mt-4">
                                <div class="flex justify-center items-center mr-4 w-8 h-8 bg-purple-100 rounded-full dark:bg-purple-900 shrink-0 sm:mb-3">
                                    <svg class="w-5 h-5 text-purple-600 dark:text-purple-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div>
                                    <h3 class="mb-2 text-xl font-bold text-white sm:text-center">Sell Products</h3>
                                    <p class="mb-2 font-light text-gray-400 sm:text-center">Effortlessly sell your products, merch and services at your event</p>

                                </div>
                            </div>
                            <div class="flex sm:flex-col sm:items-center sm:mt-4 ">
                                <div class="flex justify-center items-center mr-4 w-8 h-8 bg-[#C55A42;]  text-teal-100 rounded-full dark:bg-teal-900 shrink-0 sm:mb-3">
                                    <svg class="w-5 h-5 text-teal-600 dark;text-teal-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div>
                                    <h3 class="mb-2 text-xl font-bold text-white sm:text-center">Tables and VIP Packages </h3>
                                    <p class="mb-2 font-light text-gray-400 sm:text-center">Create custom packages to provide your guests with a premium experience that goes above and beyond</p>

                                </div>
                            </div>
                            <div class="flex sm:flex-col sm:items-center sm:mt-4 ">
                                <div class="flex justify-center items-center mr-4 w-8 h-8 rounded-full bg-teal-900 shrink-0 sm:mb-3">
                                    <svg class="w-5 h-5 text-teal-600 dark;text-[#014451] " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </div>
                                <div>
                                    <h3 class="mb-2 text-xl font-bold text-white sm:text-center">Fundraising Simplified</h3>
                                    <p class="mb-2 font-light text-gray-400 sm:text-center">Manage and track your fundraising campaigns effortlessly, making it easier than ever to support your cause</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Section2