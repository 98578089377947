import React from 'react'
import './UnitedStates.css'
import { uniteddata } from './uniteddata';

const UnitedStates = () => {
    return (
        <div className='mb-[4rem] mt-[9rem]'>
            <div className='flex flex-col items-start mb-[3rem] sm:mt-[3rem] sm:mb-[2rem] pl-[3.5rem] mt-[5.5rem] gap-3 sm:pl-4 '>
                <h5 className='text-[32px] text-white w-[25%] leading-12 sm:w-[90%] sm:text-2xl sm:mt-2 '>United States</h5>
            </div>
            <div className='united6main'>
                <div className='united6-container  overflow-x-scroll  whitespace-nowrap scroll-smooth scrollbar-hide united-scroller5 sm:ml-3' >
                    {
                        uniteddata.map((data, index) => (
                            <div className="united6" key={index}>
                                <img src={data.img} alt="" />
                                <div className="u-description">
                                    <h5>{data.heading}</h5>
                                    <p> {data.desc} </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default UnitedStates