import img1 from '../landingpagecomponent/svg/international/img1.jpg'
import img2 from '../landingpagecomponent/svg/international/img2.jpg'
import img3 from '../landingpagecomponent/svg/international/img3.jpg'
import img4 from '../landingpagecomponent/svg/international/img4.jpg'
import img5 from '../landingpagecomponent/svg/international/img5.jpg'
import img6 from '../landingpagecomponent/svg/international/img6.jpg'
import img7 from '../landingpagecomponent/svg/international/img7.jpg'
import img8 from '../landingpagecomponent/svg/international/img8.jpg'
import img9 from '../landingpagecomponent/svg/international/img9.jpg'

export const interdata = [
    {
        img: img1,
        heading: "Tulum",
        desc: "Mexico"
    },
    {
        img: img7,
        heading: "Ibiza",
        desc: "Spain"
    },
    {
        img: img5,
        heading: "Paris",
        desc: "France"
    },
    {
        img: img3,
        heading: "Toronto",
        desc: "Canada"
    },
    {
        img: img2,
        heading: "Dubai",
        desc: "UAE"
    },
    {
        img: img6,
        heading: "Rio De Janeiro ",
        desc: "Brazil"
    },
    {
        img: img9,
        heading: "Tokyo",
        desc: "Japan"
    },
    {
        img: img8,
        heading: "London",
        desc: "UK"
    },
    {
        img: img4,
        heading: "Cairo",
        desc: "Egypt"
    },
]