import React from 'react'
import NavbarService from '../components/NavbarService'
import ServiceFooter from '../components/ServiceFooter'
import arrow from "../img/backarrow.svg"
const ServiceTutorial = () => {
  return (
    <div className=''>
<NavbarService/>

<section class="bg-[#F5F9FF]  dark:bg-gray-900 justify-center py-20 px-20 md:px-10 sm:px-4">
    <div class="py-8 sm:pb-0 w-[80%] md:w-full sm:w-full px-4 justify-center mx-auto  lg:py-16 gap-8 lg:gap-16">
        <div class="flex flex-col justify-center">
        <div className='flex flex-row'>
        <img className=" mr-3 mt-[-8px] items-center justify-center" src={arrow} alt="logo"/>
            <a href='/servicelogin' class=" text-3xl flex flex-row pb-3 font-inter font-medium tracking-tight leading-none text-gray-900 md:text-2xl lg:text-6xl dark:text-white"> Getting started
  </a>
  
              </div>
              <div class="flex  sm:flex-row sm:space-y-0 sm:space-x-4">
             
                <a href="#" class=" text-3xl flex flex-row py-3 font-inter font-medium tracking-tight leading-none text-gray-900 md:text-3xl lg:text-3xl dark:text-white">
                Video<b className='ml-2'> Tutorial</b>
                </a>  
            </div>
        </div>
        <div>
            <iframe class="mx-auto mb-8 w-full sm:w-full lg:max-w-xl h-96 rounded-lg sm:h-64 shadow-xl" src="https://www.youtube.com/embed/KaLxCiilHns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="flex flex-col font-popins justify-center">
            <h1 class="mb-4 text-xl font-bold tracking-tight leading-none text-gray-900  dark:text-white">Document Requirement</h1>
            <p class="mb-8 text-base font-normal text-gray-500  dark:text-gray-400">Here at Flowbite we focus on markets where technology, innovation, and capital can unlock long-term value and drive economic growth.</p>
           </div>
           <div class="flex flex-col w-full justify-center">
    <h1 class=" w-[80%] md:w-full sm:w-full text-4xl flex flex-row pt-3  font-medium tracking-tight leading-none text-gray-900 md:text-3xl lg:text-2xl dark:text-white"> Video Library
                        </h1>
                        </div>
    </div>
   
    <div class="py-4 w-[80%] font-popins md:w-full sm:w-full sm:px-3 px-4 mx-auto max-w-screen-xl lg:py-16 grid grid-cols-4 lg:grid-cols-2 md:grid-cols-1 sm:grid-cols-1 gap-8 sm:gap-3 lg:gap-16">
    
        <div class="flex flex-col gap-3 justify-center">
        <div>
            <iframe class="mx-auto w-full lg:max-w-xl h-56 rounded-lg sm:h-48 shadow-xl" src="https://www.youtube.com/embed/KaLxCiilHns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="flex flex-col  justify-center">
            <h1 class="mb-4 text-base  font-bold tracking-tight leading-none text-gray-900  dark:text-white">Document Requirement</h1>
            <p class="mb-8 text-sm font-normal text-gray-500  dark:text-gray-400">Here at Flowbite we focus on markets where technology,</p>
           </div>
      </div>
      <div class="flex flex-col gap-3 justify-center">
        <div>
            <iframe class="mx-auto w-full lg:max-w-xl h-56  rounded-lg sm:h-48 shadow-xl" src="https://www.youtube.com/embed/KaLxCiilHns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="flex flex-col justify-center">
            <h1 class="mb-4 text-base font-bold tracking-tight leading-none text-gray-900  dark:text-white">Document Requirement</h1>
            <p class="mb-8 text-sm font-normal text-gray-500  dark:text-gray-400">Here at Flowbite we focus on markets where technology,</p>
           </div>
      </div>
      <div class="flex flex-col gap-3 justify-center">
        <div>
            <iframe class="mx-auto w-full lg:max-w-xl h-56  rounded-lg sm:h-48 shadow-xl" src="https://www.youtube.com/embed/KaLxCiilHns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="flex flex-col justify-center">
            <h1 class="mb-4 text-base font-bold tracking-tight leading-none text-gray-900  dark:text-white">Document Requirement</h1>
            <p class="mb-8 text-sm font-normal text-gray-500  dark:text-gray-400">Here at Flowbite we focus on markets where technology,</p>
           </div>
      </div>
      <div class="flex flex-col gap-3 justify-center">
        <div>
            <iframe class="mx-auto w-full lg:max-w-xl h-56  rounded-lg sm:h-48 shadow-xl" src="https://www.youtube.com/embed/KaLxCiilHns" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="flex flex-col justify-center">
            <h1 class="mb-4 text-base font-bold tracking-tight leading-none text-gray-900  dark:text-white">Document Requirement</h1>
            <p class="mb-8 text-sm font-normal text-gray-500  dark:text-gray-400">Here at Flowbite we focus on markets where technology, </p>
           </div>
      </div>
      </div>
</section>

{/* <ServiceFooter/> */}
    </div>
  )
}

export default ServiceTutorial