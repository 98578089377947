import React from 'react'
import { RxCross1 } from 'react-icons/rx'

const Section4Popup = ({ onClose }) => {
    return (
        <div>

            <>
                <div className=" bg-[rgba(0,0,0,0.9)] w-full h-screen flex items-center justify-center absolute z-50 right-0 bottom-0  ">
                    <RxCross1 className='absolute top-[1rem] right-[1rem] text-white text-xl font-extrabold cursor-pointer' onClick={onClose} />
                    <div className="flex flex-col gap-7 justify-center items-center ">
                        <h5 className='text-white text-center text-[30px] font-[400]' >Get early access</h5>

                        <p className='text-white text-center text-[24px] w-[90%] font-[300]'>Be recognized as one of our first users✨</p>

                        <form action="#" className="flex items-center sm:px-4">
                            <div class="items-center mx-auto mb-3 space-y-3  sm:space-y-[10.5px] max-w-screen-sm sm:flex sm:space-y-0 flex items-center">
                                <div class="relative w-full mt-3  sm:w-[70%] ">
                                    <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                                    </div>
                                    <input class="block p-3 pl-10 w-full sm:w-[100%]  text-sm text-gray-900 bg-gray-50 rounded-tl-lg rounded-bl-lg rounded-tr-none  border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Enter your email" type="email" id="email" required="" />
                                </div>
                                <div>
                                    <button type="submit" class="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-tl-none rounded-bl-none rounded-tr-lg rounded-br-lg border cursor-pointer bg-primary-700 border-primary-600 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Subscribe</button>
                                </div>
                            </div>
                            
                        </form>
                    </div>

                </div>
            </>

        </div>
    )
}

export default Section4Popup