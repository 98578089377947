import React from 'react'
import NavbarMain from './NavbarMain'
import { useState } from 'react'
import { Button } from 'flowbite-react';

const ServicePricingCompo = () => {
  const [isopen, setisopen] = useState(true);
  const [monthly, setmonthly] = useState(true);
  const [yearly, setyearly] = useState(false);
  const month = () => {
    setmonthly(true)
    setyearly(false)
  }
  const year = () => {
    setmonthly(false)
    setyearly(true)
  }
  const menuopen = async () => {
    setisopen(!isopen);
  }
  return (
    <div className='w-full bg-[#FCFCFD] pricingcontainer'>
      <section class=" sm:mx-5 mt-10 ">
        <div class="py-8  px-24 sm:px-0 sm:mx-0 mx-auto lg:py-10 lg:px-6 ">
          <h2 className='text-5xl text-black text-center mb-10 font-bold pricheading'>Our Pricing</h2>
          <div class="mx-auto sm:mx-0 max-w-screen-md text-center mb-8 lg:mb-12 flex justify-center gap-0 sm:flex-col sm:mb-7 sm:items-center pricingbtncontainer">
            <p className='mt-3 text-gray-500'>Billed Monthly</p>
            <div className='pricingtoggle w-wrap flex  justify-center text-center mb-8 sm:mb-2 lg:mb-12 w-[17.5%] sm:w-[80%]'>
              <div className='bg-[#83B0FF] flex rounded-full p-1 w-[44%] sm:mt-2 pricingbuttons'>
                <Button onClick={month} className={`${monthly ? "bg-white text-black" : "bg-[#83B0FF]"} w-[30%] px-5 border-none focus:border-none focus:ring-0 py-1 text-sm font-inter font-normal rounded-full  text-black`}></Button>
                <Button onClick={year} className={`${yearly ? "bg-white text-black" : "bg-[#83B0FF]"} w-[30%] px-5 border-none py-1 focus:border-none focus:ring-0 text-sm font-inter font-normal  rounded-full   text-black`}></Button>
              </div>
            </div>
            <p className='mt-3 font-bold sm:mt-0'>Billed Yearly (save 15%)</p>
          </div>
          <div className=" grid grid-cols-3 gap-10 font-inter md:grid-cols-1 sm:grid-cols-1 sm:gap-6 items-start xl:gap-10 ">
            <div class="flex flex-col px-10 py-6 sm:py-3 sm:px-3  max-w-lg text-left  rounded-lg border  shadow-sm  xl:p-8 text-white price-cards">
              <h3 class="mb-2 text-2xl font-bold text-black" >Free</h3>
              {monthly && <div class="flex justify-start items-baseline my-3">
                <span class="mr-2 text-4xl font-extrabold text-black sm:text-3xl">$0</span>
                <span class="text-gray-500 text-black">/month</span>
              </div>}
              {yearly && <div class="flex justify-start items-baseline my-3">
                <span class="mr-2 text-4xl font-extrabold text-black sm:text-3xl">$0</span>
                <span class="text-gray-500 text-black">/Year</span>
              </div>}

              <ul role="list" class="mb-8 space-y-4 text-left">
                <li className='mr-2 text-2xl font-bold text-black mt-2 sm:text-xl'>Unlimited Bookings</li>
                <li className='border-b text-sm pb-3  text-black'>Utilize a full range of our platform features</li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-black'>   Free leads </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-black'  >No upfront costs</span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-black'  >Customer Support</span>
                </li>
              </ul>
              <a href="/usersignin" class=" pricecardbtn bg-white hover:bg-blue-400 text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center   border border-blue-300 hover:text-white">Get started</a>
            </div>
            <div class="flex flex-col px-10 py-6 sm:py-3 sm:px-3  max-w-lg text-left  rounded-lg border  shadow-lg shadow-blue-300 xl:p-8 text-white priceprocard">
              <h3 class="mb-2 text-2xl font-bold text-black" >Pro</h3>
              {monthly && <div class="flex justify-start items-baseline my-3">
                <span class="mr-2 text-4xl font-extrabold text-black sm:text-3xl">$500</span>
                <span class="text-gray-500 text-black">/month</span>
              </div>}
              {yearly && <div class="flex justify-start items-baseline my-3">
                <span class="mr-2 text-4xl font-extrabold text-black sm:text-3xl">$5100</span>
                <span class="text-gray-500 text-black">/Year</span>
              </div>}

              <ul role="list" class="mb-8 space-y-4 text-left ">
                <li className='mr-2 text-2xl font-bold text-black mt-2 sm:text-xl'>The Level Up</li>
                <li className='text-black'>Everything in the Free plan, plus</li>
                <li className='border-b text-sm pb-4  text-black'>Description of the tier list will go here, copy should be concise and impactful.</li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-black'>  A.I assistant</span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-black'  >Priority customer service </span>
                </li>
                <li class="flex items-center space-x-3">

                  <svg class="flex-shrink-0 w-5 h-5 text-green-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                  <span className='text-black'  >Pay 10% commission</span>
                </li>
              </ul>
              <a href="/usersignin" class=" pricecardbtn bg-blue-400  hover:bg-white text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center  border border-blue-300 ">Get started</a>
            </div>

            <div class="flex flex-col px-10 py-6 sm:px-3 sm:py-3  max-w-lg text-left  rounded-sm border xl:p-8 text-white price-cards">
              <h3 class="mb-2 text-2xl font-bold text-black" >Enterprise</h3>
              {monthly && <div class="flex justify-start items-baseline my-3">
                <span class="mr-2 text-2xl font-extrabold text-black">Custom</span>

              </div>}
              {yearly && <div class="flex justify-start items-baseline my-3">
                <span class="mr-2 text-2xl font-extrabold text-black">Custom</span>
              </div>}

              <ul role="list" class="mb-8 space-y-4 text-left">
                <li className='border-b text-sm pb-4  text-black'>Description of the tier list will go here, copy should be concise and impactful.</li>
              </ul>
              <a href="/usersignin" class=" pricecardbtn bg-white  hover:blue-400 text-black font-medium rounded-lg text-sm px-5 py-2.5 text-center border border-blue-300 ">Get started</a>
            </div>


          </div>
        </div>
      </section>
    </div>
  )
}

export default ServicePricingCompo