import React from 'react'
import img1 from '../components/serviceiamges/img1.png'

const ServiceCompo1 = () => {
    return (
        <div>
            <div className='w-full'>
                <section class="bg-white">
                    <div class="service1container gap-8 py-20 sm:my-2 flex flex-row md:flex-col sm:flex-col sm:flex-col-reverse items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-10  sm:py-10 lg:px-6">
                        <div className='w-[35%] flex flex-col md:w-full  sm:w-full mx-10 service1image'>
                            <img class=" w-[100%] sm:w-full ml-4 md:ml-2  justify-center " src={img1} alt="dashboard image" />

                        </div>
                        <div class="service1text w-[60%] md:w-full sm:w-full justify-center items-center  md:mt-0">
                            <h2 class="w-[85%] sm:w-full mb-5 text-5xl font-['Inter'] tracking-tight font-extrabold text-gray-900  sm:text-3xl sm:w-[100%] ">Receive direct bookings
                                from event planners</h2>
                            <p className="text-[#6B7280] w-[80%] text-md sm:w-[100%]">Join us and expand your reach to connect with event planners and hosts in need of your expertise</p>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ServiceCompo1