import React, { useState } from 'react';
import styles from "./services-and-venues-section-style.module.css";
import DemoPopup from '../DemoPopup';
const ServiceAndVenuesTop = () => {

    const [menu, setMenu] = useState(3);
    const [favourite, setFavourite] = useState(0);

    const [demo, setDemo] = useState(false)
    const handleClick = () => {
        setDemo(!demo)
    }

    return (
        <div className='relative'>

            <div className='w-[80%] mx-auto'>
                <div className='mt-10 grid grid-cols-2
                place-content-center md:place-content-between md:grid-cols-1 text-white'>

                    <div className="flex md:justify-center items-center">
                        <h3 className='text-white heading-3-v2'>My Bookings</h3>
                    </div>
                    <div className='flex justify-end md:justify-center pt-1 items-center md:mt-2'>

                        <ul className='flex w-full pt-4   justify-end md:justify-center'>

                            <li className={`mr-4 `} onClick={handleClick}>
                                <span className='heart-span flex justify-center items-center cursor-pointer'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M4.5 1.99756C2.84315 1.99756 1.5 3.3407 1.5 4.99756L1.5 15.9976C1.5 17.6544 2.84315 18.9976 4.5 18.9976L6.5 18.9976L6.5 22.9976C6.5 23.3856 6.72446 23.7386 7.07584 23.9031C7.42723 24.0677 7.8421 24.0142 8.14018 23.7658L13.862 18.9976L20.5 18.9976C22.1569 18.9976 23.5 17.6544 23.5 15.9976L23.5 4.99756C23.5 3.3407 22.1569 1.99756 20.5 1.99756L4.5 1.99756Z" fill="#F9FAFB" />
                                    </svg>
                                </span>
                            </li>
                            <li className={`mr-4 `} onClick={() => setFavourite(favourite === 1 ? 0 : 1)}>
                                <span className='heart-span flex justify-center items-center cursor-pointer'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"

                                        fill={`${favourite === 1 ? "#ff0000" : "#1F2A37"}`}
                                        onClick={handleClick} >
                                        <g clipPath="url(#clip0_3950_53370)">
                                            <path d="M7.60783 2.83844C3.99891 2.8405 1.733 5.78771 1.7316 8.79822C1.73017 11.86 3.36778 14.4782 5.41996 16.5208C7.4753 18.5666 10.0245 20.1167 12.0561 21.0666C12.3316 21.1954 12.656 21.1952 12.9317 21.0661C14.9641 20.1138 17.5148 18.5609 19.572 16.5127C21.6261 14.4678 23.2662 11.8476 23.2676 8.7859C23.269 5.78031 21.0471 2.83075 17.3969 2.83284C15.7083 2.8338 14.512 3.27415 13.5969 3.94361C13.1659 4.2589 12.806 4.62249 12.4988 4.97664C12.1885 4.62209 11.8253 4.25884 11.3907 3.94316C10.4746 3.2778 9.28062 2.83748 7.60783 2.83844Z" stroke="#F9FAFB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3950_53370">
                                                <rect width="23.4939" height="21.9929" fill="white" transform="matrix(0.999996 -0.000572144 -0.000466357 1 0.757812 1.00977)" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </span>
                            </li>

                            <li className={`px-5 py-2 pb-3 dashboard-attending-btn-1 flex justify-center items-center mr-3 cursor-pointer sm:py-1 sm:px-1 sm:text-center ${menu === 2 ? "bg-[#0062FF]" : "bg-[#1F2A37]"}`} onClick={() => setMenu(2)}>
                                <span className='dashboard-attending-btn-txt sm:text-sm ' onClick={handleClick}>My Earnings
                                </span>
                            </li>
                            <li className={` px-5 py-2 pb-3 dashboard-attending-btn-1 flex justify-center items-center cursor-pointer sm:py-1 sm:px-1 sm:text-center ${menu === 3 ? "bg-[#0062FF]" : "bg-[#1F2A37]"}`} onClick={() => setMenu(3)}>
                                <span className='dashboard-attending-btn-txt sm:text-sm ' onClick={handleClick}>
                                    {"Book services or venue"}
                                </span>
                            </li>

                        </ul>

                    </div>

                </div>


            </div>

            {
                demo && (
                    <div className='absolute top-0 z-40 w-[100%] h-[100%]'>
                        <DemoPopup onClose={() => setDemo(null)}  />
                    </div>
                )
            }
        </div>
    )
}

export default ServiceAndVenuesTop;