import React, { useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

function Landingfaq() {
  const accordionData = [
    { title: 'How do I start planning an event?', content: "Simply create a free account, click 'Create an Event' and follow the steps to set up your event details, invite list, and more." },
    { title: 'What types of events can I plan on Entr?', content: 'Entr supports all event types including parties, conferences, corporate events, reunions, weddings, festivals, and more!' },
    { title: 'What is included in each subscription plan?', content: 'The Free plan is limited to 1 event per month. Pro unlocks additional capacity, marketing tools and support. Enterprise adds custom integrations and more.' },
    { title: 'How do I source vendors and services for my event?', content: '  Use our vendor marketplace to browse availability and pricing, then book and pay secured through the platform.' },
    { title: 'Can I integrate my calendar?', content: ' Yes! Entr offers calendar integration with tools like Google Calendar. This keeps your bookings synced with your existing schedule' },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div className="container mx-auto px-24 sm:px-4 pb-20 frequentcontainer bg-transparent mt-3">
      <h1 className="text-3xl font-bold mb-10 sm:text-2xl  text-white">Frequentely asked questions</h1>
      <div className='frequentmain bg-[#F1F2F3]  rounded-xl'>
        {accordionData.map((item, index) => (
          <div
            key={index}
            className={`border-b-2 p-7 border-white  transition-all ${openIndex === index ? 'open' : ''
              }`}
          >
            <button
              className="flex items-center justify-between w-full p-2 sm:pl-0 focus:outline-none"
              onClick={() => toggleAccordion(index)}
            >
              <span className="text-md font-medium  sm:text-md text-black freqtitle">{item.title}</span>
              {openIndex === index ? (
                <FiMinus className="text-xl text-gray-600" />
              ) : (
                <FiPlus className="text-xl text-gray-600" />
              )}
            </button>
            <div className={`mt-2 p-2 text-sm freqcontent ${openIndex === index ? 'block' : 'hidden'}`}>
              {item.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Landingfaq;
