import React from 'react'
import entrlogo from "../img/Entrlogo.svg"
const Servicesignupcomponent = () => {
  return (
    <div>
      <div className=" max-w-md py-12 px-8 lg:h-screen h-screen lg:block bg-blue-500">
        <div className="flex items-center mb-8 space-x-4">
          <a href="/" className="flex items-center text-2xl font-semibold text-white">
            <img className="w-8 h-8 mr-2" src={entrlogo} />
            Entr
          </a>
        </div>
        <div className="block py-8 font-['Nunito'] text-white rounded-lg bg-primary-500">
          <h1 className="mb-1 text-5xl font-semibold">We’re bridging the gap between service providers and event hosts.
          </h1>


        </div>
      </div>
    </div>
  )
}

export default Servicesignupcomponent