import img1 from '../landingpagecomponent/svg/unitedstates/img1.jpg'
import img2 from '../landingpagecomponent/svg/unitedstates/img2.jpg'
import img3 from '../landingpagecomponent/svg/unitedstates/img3.jpeg'
import img4 from '../landingpagecomponent/svg/unitedstates/img4.jpg'
import img5 from '../landingpagecomponent/svg/unitedstates/img5.jpg'
import img6 from '../landingpagecomponent/svg/unitedstates/img6.jpg'
import img7 from '../landingpagecomponent/svg/unitedstates/img7.jpg'
import img8 from '../landingpagecomponent/svg/unitedstates/img8.jpg'

export const uniteddata = [
    {
        img: img1,
        heading: "Los Angeles",
        desc: "California",
    },
    {
        img: img6,
        heading: "Miami",
        desc: "Florida",
    },
    {
        img: img2,
        heading: "Chicago",
        desc: "Illinois",
    },
    {
        img: img3,
        heading: "Detroit",
        desc: "Michigan",
    },
    {
        img: img4,
        heading: "Dallas",
        desc: "Texas",
    },
    {
        img: img7,
        heading: "Las Vegas",
        desc: "Nevada",
    },
    {
        img: img8,
        heading: "New York",
        desc: "New York",
    },
    {
        img: img5,
        heading: "New Orleans",
        desc: "Illinois",
    }
]