//import logo from './logo.svg';
import './App.css';
import LandingPage from './pages/LandingPage';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UserSignup from './pages/UserSignup';
import UserLogin from './pages/UserLogin';
import VerifyingUser from './pages/VerifyingUser';
import UserPasswordreset from './pages/UserPasswordreset';
import UserDashboardMain from './pages/Userdashboardmain';
import ServiceLandingPage from './pages/ServiceLandingPage';
import ServiceLogin from './pages/ServiceLogin';
import ServiceSignup from './pages/ServiceSignup';
import Servicesignupconfirmation from './pages/Servicesignupconfirmation';
import ServiceDashboardmain from './pages/ServiceDashboardmain';
import Servicesignup1 from './pages/Servicesignup1';
import Servicesignupaccount from './pages/Servicesignupaccount';
import ServiceTutorial from './pages/ServiceTutorial';
import ServicePricing from './pages/ServicePricing';
import Features from './pages/Features';
import UserLandingPage from './pages/UserLandingPage';
import { StateProvider } from "./context/StateProvider"
import { initialState } from "./context/initialState"
import reducer from "./context/reducer"
import { useStateValue } from "./context/StateProvider";
//import RequireAuth from "./components/RequireAuth";
import { AuthProvider } from "./context/auth";
import { ServiceAuthProvider } from "./context/serviceauth";
import GoProPage from './pages/GoProPage';
//import RequireServiceAuth from './components/RequireServiceAuth';
//import Section8 from './components/landingpagecomponent/Section8';
import Faq from './components/faq';
import Termsandconditions from './components/landingpagecomponent/Termsandconditions';
import ServiceForgetpassword from './pages/ServiceForgetpassword';
import Serviceforgetpass from './pages/Serviceforgetpass';
import UserPassres from './pages/UserPassreset';
import PrivacyPolicy from './components/landingpagecomponent/PrivacyPolicy';
import Investinentr from './components/landingpagecomponent/Investinentr';
import Aboutus from './components/landingpagecomponent/Aboutus';
function App() {

  const [{ user }, dispatch] = useStateValue();
  return (
    <div className='w-full  '>
      <AuthProvider>
        <ServiceAuthProvider>
          <StateProvider initialState={initialState} reducer={reducer}>
            <Routes>
              {!user ? <Route path="/*" element={<LandingPage />} />
                : <Route path="/*" element={<UserDashboardMain />} />}


              <Route
                path="/usersignup"
                element={

                  <UserSignup />

                }
              />
              <Route
                path="/usersignin"
                element={<UserLogin />}
              />

              <Route
                path="/userverify"
                element={<VerifyingUser />}
              />
              <Route
                path="/userpasswordreset"
                element={<UserPasswordreset />}
              />
              <Route
                path="/userpassres"
                element={<UserPassres />}
              />
              <Route
                path="/servicepasswordreset"
                element={<ServiceForgetpassword />}
              />
              <Route
                path="/servicepassres"
                element={<Serviceforgetpass />}
              />
              <Route
                path="/userdashboardmain"
                element={<UserDashboardMain />}
              />
              <Route
                path="/userfaq"
                element={<Faq />}
              />
              <Route
                path="/servicelandingpage"
                element={<ServiceLandingPage />}
              />
              <Route
                path="/servicelogin"
                element={<ServiceLogin />}
              />
              <Route
                path="/servicesignup"
                element={<ServiceSignup />}
              />
              <Route
                path="/servicesignup1"
                element={<Servicesignup1 />}
              />
              <Route
                path="/servicesignupaccount"
                element={<Servicesignupaccount />}
              />

              <Route
                path="/serviceconfirmation"
                element={<Servicesignupconfirmation />}
              />
              <Route
                path="/servicelandingpage/servicedashboard"
                element={
                  //  <RequireServiceAuth> 

                  <ServiceDashboardmain />
                  //  </RequireServiceAuth> 
                }
              />
              <Route
                path="/servicetutorial"
                element={<ServiceTutorial />}
              />
              <Route
                path="/servicepricing"
                element={<ServicePricing />}
              />
              <Route
                path="/features"
                element={<Features />}
              />
              <Route
                path="/userlandingpage"
                element={
                  <UserLandingPage />
                }
              />
              <Route
                path="/gopro"
                element={<GoProPage />}
              />
              <Route
                path="/aboutus"
                element={<Aboutus />}
              />
              <Route
                path="/termandcondition"
                element={<Termsandconditions />}
              />
              <Route
                path="/investinentr"
                element={<Investinentr />}
              />
              <Route
                path="/privacypolicy"
                element={<PrivacyPolicy />}
              />
            </Routes>
          </StateProvider>
        </ServiceAuthProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
