// Import the functions you need from the SDKs you need
import { getApp, getApps, initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";
import { useNavigate } from "react-router-dom";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAWchIC0efFrscmt93LbOdyhstGo7Ww5FI",
  authDomain: "entr-demo-dev.firebaseapp.com",
  projectId: "entr-demo-dev",
  storageBucket: "entr-demo-dev.appspot.com",
  messagingSenderId: "477579145717",
  appId: "1:477579145717:web:ddbf645032222909c730bc",
  measurementId: "G-NHFP9E25BC"
};

// Initialize Firebase
const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);



const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://entr-demo-dev.web.app/servicesignup',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'enterdemodev.page.link'
};

const actionCodeSetting = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://entr-demo-dev.web.app/usersignup',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'enterdemodev.page.link'
};

const passwordreset = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://entr-demo-dev.web.app/servicelogin',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'enterdemodev.page.link'
};

const userpasswordreset = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: 'https://entr-demo-dev.web.app/usersignin',
  // This must be true.
  handleCodeInApp: true,
  iOS: {
    bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  dynamicLinkDomain: 'enterdemodev.page.link'
};
export { app, firestore, storage, auth, actionCodeSettings, actionCodeSetting, passwordreset, userpasswordreset };

