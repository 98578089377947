import React, { useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import styles from "./attending-bottom-section.module.css";
import { AiOutlineHeart } from 'react-icons/ai'
import DemoPopup from '../DemoPopup';
const AttendingBottomSection = () => {
    const [demo, setDemo] = useState(false)
    const handleClick = () => {
        setDemo(!demo)
    }


    return (
        <div className='relative'>
            <div className=' text-white w-[85%] mx-auto'>
                <div className="grid grid-cols-12 md:grid-cols-1 place-content-between gap-4 my-12 pb-40">

                    <div className="pl-4 md:pl-0 md:col-span-12 col-span-10">
                        <div className="flex justify-between items-center pb-14">
                            <h3 className={`md:text-center py-1 sm:text-sm ${styles.featureHeadingText}`} onClick={handleClick}>Attending</h3>
                            <div className='flex gap-2'>
                                <div className="p-2 rounded-full bg-[#1F2A37] flex justify-center items-center text-2xl"> <AiOutlineHeart className='' onClick={handleClick}/></div>
                                <button className='p-2 bg-[#1F2A37] rounded-full sm:text-sm'onClick={handleClick}>My Tickets</button>
                                <button className='p-2 bg-[#0062FF] rounded-full sm:text-sm'onClick={handleClick}>Find things to do</button>
                            </div>
                        </div>
                        <div className='md:my-2 flex gap-8  sm:flex-col'>
                            <ul className='w-30 md:w-full md:flex md:justify-between'>
                                <li className={`pb-1 border-b-2 border-b-[#0062FF] text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`}>Attending</li>
                                <li className={`pb-1 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`} onClick={handleClick}>Invitations</li>
                                <li className={`pb-1 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`} onClick={handleClick} >Post</li>
                            </ul>

                            <div className=''>
                                <div className='mb-5 mt-2 flex justify-start md:justify-center pt-1 items-center md:mt-2' onClick={handleClick}>
                                    <input className='user-dashboard-search placeholder-gray-400 mr-2 active:border-gray-700 focus:border-gray-700' type="search" name="Search" placeholder='Search' id="" />

                                    <DateRangeIcon
                                        fontSize="large" className='calendar-icon-style' />

                                </div>

                                <div className="my-4 md:w-full w-11/12 grid md:grid-cols-1 grid-cols-2 place-content-between md:gap-4 gap-20">

                                    <div className={`shadow-sm ${styles.featureCard1}`} onClick={handleClick}>
                                        <div className='h-2/5'></div>
                                        <div className={`md:mt-0 lg:mt-0 mt-4 h-3/5 p-3 ${styles.featureCard1Content}`}>
                                            <p className='py-2'>
                                                <span className='px-4 py-2 bg-[#50C6F99C] font-bold'>Today</span>
                                            </p>
                                            <h4 className={`py-1 ${styles.featureCard1Heading}`}>New thread Quarter,  2023</h4>
                                            <p className={`py-1 ${styles.featureCard2Text1}`}>07 OCT, 2023 at 12:00PM</p>
                                            <p className={`py-2 flex ${styles.featureCard1Text2}`} ><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M17.5664 8.3335C17.5664 14.1668 10.4414 19.1668 10.4414 19.1668C10.4414 19.1668 3.31641 14.1668 3.31641 8.3335C3.31641 6.34437 4.06707 4.43672 5.40327 3.0302C6.73947 1.62367 8.55174 0.833496 10.4414 0.833496C12.3311 0.833496 14.1433 1.62367 15.4795 3.0302C16.8157 4.43672 17.5664 6.34437 17.5664 8.3335Z" fill="#313135" stroke="#313135" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.4414 10.8335C11.7531 10.8335 12.8164 9.71421 12.8164 8.3335C12.8164 6.95278 11.7531 5.8335 10.4414 5.8335C9.12973 5.8335 8.06641 6.95278 8.06641 8.3335C8.06641 9.71421 9.12973 10.8335 10.4414 10.8335Z" fill="white" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span> David Geffen Hall</p>
                                        </div>
                                    </div>


                                    <div className={`shadow-sm ${styles.featureCard2}`} onClick={handleClick}>
                                        <div className='h-2/5'></div>
                                        <div className={`md:mt-0 lg:mt-0  mt-4 h-3/5 p-3 ${styles.featureCard1Content}`}>
                                            <p className='py-2'>
                                                <span className='px-4 py-2 bg-[#74359C] font-bold'>Tomorrow</span>
                                            </p>
                                            <h4 className={`py-1 ${styles.featureCard1Heading}`}>Yoga By Leslie </h4>
                                            <p className={`py-1 ${styles.featureCard2Text1}`} >08 OCT, 2023 at 11:00AM</p>
                                            <p className={`py-2 flex ${styles.featureCard2Text2}`}><span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M17.5664 8.3335C17.5664 14.1668 10.4414 19.1668 10.4414 19.1668C10.4414 19.1668 3.31641 14.1668 3.31641 8.3335C3.31641 6.34437 4.06707 4.43672 5.40327 3.0302C6.73947 1.62367 8.55174 0.833496 10.4414 0.833496C12.3311 0.833496 14.1433 1.62367 15.4795 3.0302C16.8157 4.43672 17.5664 6.34437 17.5664 8.3335Z" fill="#313135" stroke="#313135" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M10.4414 10.8335C11.7531 10.8335 12.8164 9.71421 12.8164 8.3335C12.8164 6.95278 11.7531 5.8335 10.4414 5.8335C9.12973 5.8335 8.06641 6.95278 8.06641 8.3335C8.06641 9.71421 9.12973 10.8335 10.4414 10.8335Z" fill="white" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg></span>Downtown Santa Moncia, CA</p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {
                demo && (
                    <div className='absolute top-0 z-40 w-[100%] h-[100%]'>
                        <DemoPopup onClose={() => setDemo(null)} />
                    </div>
                )
            }
        </div>
    )
}

export default AttendingBottomSection;