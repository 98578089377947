import React, { useEffect, useState } from 'react'
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import img from "../img/bg.png"
import { useNavigate, Link } from 'react-router-dom'
import EnterComponent from './EnterComponent';
import entrlogo from "../img/entrlogoblack.svg"
import bcrypt from 'bcryptjs'
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { storage } from "../firebase";
import { useAuth } from "../context/auth";
import { saveUser, getAllUser } from "../utils/firebaseFunctions";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import { onAuthStateChanged, signOut } from 'firebase/auth';
// import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PasswordStrengthBar from 'react-password-strength-bar';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const UserSignup = () => {
  const auth = getAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [getuser, setgetuser] = useState();
  const [fields, setFields] = useState(false);
  const [alertStatus, setAlertStatus] = useState("danger");
  const [msg, setMsg] = useState("danger");
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const { login } = useAuth();
  const [{ user }, dispatch] = useStateValue();
  const [getemail, setgetemail] = useState('');
  const [country, setCountry] = useState('');
  const [passErr, setPassErr] = useState('');
  //const [email, setEmail] = useState('');
  const getemails = [];

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  // const [ dispatch] = useStateValue();
  const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
  const storedphone = window.localStorage.getItem('phoneno');
  const storedpassword = window.localStorage.getItem('password');
  const getAllUser = async () => {
    const addUser = collection(firestore, "User");
    const getData = await getDocs(addUser);
    // //console.log(TableHeader)

    setgetuser(getData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

  };
  const getAllemail = async () => {
    {
      getuser?.map((val, ind) => {
        setgetemail(val.email)
      })
    }

  }

  //console.log(getemail);

  const saveDetails = async (values) => {

    setOpen(true);

    //alert("HI");
    setIsLoading(true);
    const hashedPassword = bcrypt.hashSync(values.password, '$2a$10$CwTycUXWue0Thq9StjUM0u')
    try {

      const userdata = {
        id: `${Date.now()}`,
        name: values.fullname,
        email: values.email,
        password: hashedPassword,
        phone: storedphone,
        country: values.country,
      };
      //console.log("User Data: ", userdata);
      saveUser(userdata);
      setIsLoading(false);
      setFields(true);
      setAlertStatus("success");
      setTimeout(() => {
        setFields(false);
      }, 4000);
      dispatch({
        type: actionType.SET_USER,
        user: userdata,
      });
      login("user");
      localStorage.setItem("user", JSON.stringify(userdata));
      //  //console.log(userdata);

      setOpen(false);

      navigate("/userdashboardmain", { replace: true });

      //console.log("login")

      clearData();

    } catch (error) {
      //console.log(error);
      setFields(true);
      setAlertStatus("danger");
      setTimeout(() => {
        setFields(false);
        setIsLoading(false);
        setOpen(false);
      }, 4000);
    }
    getAllUser();
  };
  const clearData = () => {
    setName("");
    setPhone("");
    setCountry("");
    setPassword("");



  };
  useEffect(() => {

    // fetchuserData();
    getAllUser();
    getAllemail();

  }, []);

  const signin = () => {
    navigate("/usersignin")
  }
  const handlePhoneChange = (value) => {
    setPhone(value);
  };
  //console.log(phone)
  const initialValues = {
    fullname: '',
    email: '',
    password: '',
    confirmpassword: '',
    country: '',

  };
  return (
    <section className=" flex flex-row h-screen sm:bg-white  lg:flex-row md:flex-col max-w-full   sm:flex-col  bg-img bg-no-repeat bg-cover bg-center bg-gray-700 bg-blend-multiply bg-opacity-30 ">
      <div className='flex flex-col ml-[72px]  justify-center w-[50%]  xl:w-[60%] lg:w-[60%] md:hidden sm:hidden '>
        <EnterComponent />

      </div>
      <div className="w-[50%] xl:w-[50%] lg:w-[50%] md:w-[100%] sm:w-[100%]">
        <div className=" flex flex-col  px-6 py-8 mx-auto  pt:mt-0">
          <p className="text-sm font-light mb-[130px] sm:hidden sm:mb-[100px] ml-[68%] xl:ml-[60%] lg:ml-[60%] md:ml-[55%] sm:ml-[45%] text-gray-500 dark:text-gray-300">

            <label for="newsletter" className="font-medium text-sm text-[#F9FAFB] font-['Inter'] dark:text-gray-300">Become a  <a className="font-medium text-primary-600 underline hover:underline dark:text-primary-500" href="/servicelandingpage">Service Provider</a></label>
          </p>
          <div className="w-[80%] md:w-[100%]  sm:w-[100%]  px-8 py-10 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 sm:max-w-xl lg:col-span-6 sm:p-8">
            <a href="/*" class=" my-6 hidden sm:flex">
              <img src={entrlogo} class="h-8 mr-3" alt="Flowbite Logo" />
              <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Entr</span>
            </a>
            <h1 className="mb-2 text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white">
              Create your Account
            </h1>
          
            <Formik
              initialValues={initialValues}
              //  validationSchema={validationSchema}
              //validator={() => ({})}
              validate={(values) => {
                //alert("HI");

                const errors = {};
                if (!values.email) {
                  errors.email = "Required";
                }
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                var test1 = emailRegex.test(values.email);
                if (values.email && !test1) {
                  errors.email = "Invalid email address";
                }
                {
                  getuser?.map((val, ind) => {
                    if (values.email && test1 && val.email === values.email) {
                      errors.email = "Email address already in use";
                    }

                  })
                }

                if (!values.fullname) {
                  errors.fullname = "Required";
                }

                if (!values.password) {
                  errors.password = "Required";

                }
                setInputPassword(values.password);
                var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
                var test = reg.test(values.password);

               ;

                if (values.password && !test) {
                  errors.password = "Weak password";
                  setPassErr("weak passward");
                }
                else {
                  setPassErr("");
                }

                if (!values.confirmpassword) {
                  errors.confirmpassword = "Required";

                }
                if (values.confirmpassword && values.confirmpassword !== values.password) {
                  errors.confirmpassword = "Password do not match";
                }


                if (!values.country || values.country === 'select country') {
                  errors.country = "Required";
                }

                //console.log("Submit Errors ", errors);
                //console.log("Submit Values ", values);

                return errors;
              }}
              onSubmit={(values) => saveDetails(values)}
            >
              <Form   >
                {/* <form className="mt-4 space-y-6 sm:mt-6" action="#"> */}
                <div className="grid gap-6 mb-6 grid-cols-2 sm:grid-cols-1">
                  <div>
                    <label for="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Your Email</label>
                    <Field type="email" name="email" id="email" placeholder="abc@gmail.com " className="bg-gray-50 border  border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600  block  w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" required="" />
                    <ErrorMessage name="email" className='tool_tip' component="div" />

                  </div>
                  <div>
                    <label for="fullname" className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                    <Field type="text" name="fullname" id="fullname" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="e.g. Bonnie Green" required="" />
                    <ErrorMessage name="fullname" className='tool_tip' component="div" />

                  </div>
                  <div>

                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Password <Tooltip className='ml-1' placement='top' title={
                        <div style={{ padding: '10px', width: '500px' }} id="message">
                          <h3 style={{ fontSize: '16px' }}>Password must contain the following:</h3>
                          <ol ttype="A" style={{ fontSize: '14px', paddingTop: '10px' }}>
                            <li style={{ margin: '4px 0px', padding: '1px 0px' }} className='text-md' id="letter" >1. A <b>lowercase</b> letter</li>
                            <li style={{ margin: '4px 0px', padding: '1px 0px' }} id="capital">2. A <b>capital (uppercase)</b> letter</li>
                            <li style={{ margin: '4px 0px', padding: '1px 0px' }} id="number" >3. A <b>number</b></li>
                            <li style={{ margin: '4px 0px', padding: '1px 0px' }} id="length">4. Minimum <b>8 characters</b></li>
                          </ol>
                        </div>
                      }
                      >
                        <HelpOutlineIcon className='cursor-pointer' fontSize='small' />
                        {/* <IconButton>
                        </IconButton> */}
                      </Tooltip>
                    </label>


                    <Field type="password" name="password" id="password" placeholder="Password"

                      className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${passErr.length > 0 ? " bg-red-50 border border-red-500 text-red-900 placeholder-red-700" : "border-gray-300 "
                        }`}

                      required=""
                    />
                    <PasswordStrengthBar className='mt-2' password={inputPassword} />

                    {/* <ErrorMessage name="password" className='tool_tip' component="div" /> */}

                  </div>
                  <div>
                    <label for="confirmpassword" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>

                    <Field type="password" name="confirmpassword" id="confirm-password" placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  border-gray-300 
            `} required="" />


                    <ErrorMessage name="confirmpassword" className='tool_tip' component="div" />


                  </div>
                  <div>
                    <label for="country" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-400">Country</label>
                    <Field as="select" id="country" name="country" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                      <option value="">select country</option>
                      <option value="AF">Afghanistan</option>
                      <option value="AX">Aland Islands</option>
                      <option value="AL">Albania</option>
                      <option value="DZ">Algeria</option>
                      <option value="AS">American Samoa</option>
                      <option value="AD">Andorra</option>
                      <option value="AO">Angola</option>
                      <option value="AI">Anguilla</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AR">Argentina</option>
                      <option value="AM">Armenia</option>
                      <option value="AW">Aruba</option>
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BS">Bahamas</option>
                      <option value="BH">Bahrain</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BB">Barbados</option>
                      <option value="BY">Belarus</option>
                      <option value="BE">Belgium</option>
                      <option value="BZ">Belize</option>
                      <option value="BJ">Benin</option>
                      <option value="BM">Bermuda</option>
                      <option value="BT">Bhutan</option>
                      <option value="BO">Bolivia</option>
                      <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BW">Botswana</option>
                      <option value="BV">Bouvet Island</option>
                      <option value="BR">Brazil</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="BN">Brunei Darussalam</option>
                      <option value="BG">Bulgaria</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BI">Burundi</option>
                      <option value="KH">Cambodia</option>
                      <option value="CM">Cameroon</option>
                      <option value="CA">Canada</option>
                      <option value="CV">Cape Verde</option>
                      <option value="KY">Cayman Islands</option>
                      <option value="CF">Central African Republic</option>
                      <option value="TD">Chad</option>
                      <option value="CL">Chile</option>
                      <option value="CN">China</option>
                      <option value="CX">Christmas Island</option>
                      <option value="CC">Cocos (Keeling) Islands</option>
                      <option value="CO">Colombia</option>
                      <option value="KM">Comoros</option>
                      <option value="CG">Congo</option>
                      <option value="CD">Congo, Democratic Republic of the Congo</option>
                      <option value="CK">Cook Islands</option>
                      <option value="CR">Costa Rica</option>
                      <option value="CI">Cote D'Ivoire</option>
                      <option value="HR">Croatia</option>
                      <option value="CU">Cuba</option>
                      <option value="CW">Curacao</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DK">Denmark</option>
                      <option value="DJ">Djibouti</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="EC">Ecuador</option>
                      <option value="EG">Egypt</option>
                      <option value="SV">El Salvador</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="ER">Eritrea</option>
                      <option value="EE">Estonia</option>
                      <option value="ET">Ethiopia</option>
                      <option value="FK">Falkland Islands (Malvinas)</option>
                      <option value="FO">Faroe Islands</option>
                      <option value="FJ">Fiji</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GF">French Guiana</option>
                      <option value="PF">French Polynesia</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="GA">Gabon</option>
                      <option value="GM">Gambia</option>
                      <option value="GE">Georgia</option>
                      <option value="DE">Germany</option>
                      <option value="GH">Ghana</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GR">Greece</option>
                      <option value="GL">Greenland</option>
                      <option value="GD">Grenada</option>
                      <option value="GP">Guadeloupe</option>
                      <option value="GU">Guam</option>
                      <option value="GT">Guatemala</option>
                      <option value="GG">Guernsey</option>
                      <option value="GN">Guinea</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="GY">Guyana</option>
                      <option value="HT">Haiti</option>
                      <option value="HM">Heard Island and Mcdonald Islands</option>
                      <option value="VA">Holy See (Vatican City State)</option>
                      <option value="HN">Honduras</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HU">Hungary</option>
                      <option value="IS">Iceland</option>
                      <option value="IN">India</option>
                      <option value="ID">Indonesia</option>
                      <option value="IR">Iran, Islamic Republic of</option>
                      <option value="IQ">Iraq</option>
                      <option value="IE">Ireland</option>
                      <option value="IM">Isle of Man</option>
                      <option value="IL">Israel</option>
                      <option value="IT">Italy</option>
                      <option value="JM">Jamaica</option>
                      <option value="JP">Japan</option>
                      <option value="JE">Jersey</option>
                      <option value="JO">Jordan</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="KE">Kenya</option>
                      <option value="KI">Kiribati</option>
                      <option value="KP">Korea, Democratic People's Republic of</option>
                      <option value="KR">Korea, Republic of</option>
                      <option value="XK">Kosovo</option>
                      <option value="KW">Kuwait</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="LA">Lao People's Democratic Republic</option>
                      <option value="LV">Latvia</option>
                      <option value="LB">Lebanon</option>
                      <option value="LS">Lesotho</option>
                      <option value="LR">Liberia</option>
                      <option value="LY">Libyan Arab Jamahiriya</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MO">Macao</option>
                      <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
                      <option value="MG">Madagascar</option>
                      <option value="MW">Malawi</option>
                      <option value="MY">Malaysia</option>
                      <option value="MV">Maldives</option>
                      <option value="ML">Mali</option>
                      <option value="MT">Malta</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="MQ">Martinique</option>
                      <option value="MR">Mauritania</option>
                      <option value="MU">Mauritius</option>
                      <option value="YT">Mayotte</option>
                      <option value="MX">Mexico</option>
                      <option value="FM">Micronesia, Federated States of</option>
                      <option value="MD">Moldova, Republic of</option>
                      <option value="MC">Monaco</option>
                      <option value="MN">Mongolia</option>
                      <option value="ME">Montenegro</option>
                      <option value="MS">Montserrat</option>
                      <option value="MA">Morocco</option>
                      <option value="MZ">Mozambique</option>
                      <option value="MM">Myanmar</option>
                      <option value="NA">Namibia</option>
                      <option value="NR">Nauru</option>
                      <option value="NP">Nepal</option>
                      <option value="NL">Netherlands</option>
                      <option value="AN">Netherlands Antilles</option>
                      <option value="NC">New Caledonia</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NI">Nicaragua</option>
                      <option value="NE">Niger</option>
                      <option value="NG">Nigeria</option>
                      <option value="NU">Niue</option>
                      <option value="NF">Norfolk Island</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="NO">Norway</option>
                      <option value="OM">Oman</option>
                      <option value="PK">Pakistan</option>
                      <option value="PW">Palau</option>
                      <option value="PS">Palestinian Territory, Occupied</option>
                      <option value="PA">Panama</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="PY">Paraguay</option>
                      <option value="PE">Peru</option>
                      <option value="PH">Philippines</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="QA">Qatar</option>
                      <option value="RE">Reunion</option>
                      <option value="RO">Romania</option>
                      <option value="RU">Russian Federation</option>
                      <option value="RW">Rwanda</option>
                      <option value="BL">Saint Barthelemy</option>
                      <option value="SH">Saint Helena</option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="MF">Saint Martin</option>
                      <option value="PM">Saint Pierre and Miquelon</option>
                      <option value="VC">Saint Vincent and the Grenadines</option>
                      <option value="WS">Samoa</option>
                      <option value="SM">San Marino</option>
                      <option value="ST">Sao Tome and Principe</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SN">Senegal</option>
                      <option value="RS">Serbia</option>
                      <option value="CS">Serbia and Montenegro</option>
                      <option value="SC">Seychelles</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SG">Singapore</option>
                      <option value="SX">Sint Maarten</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="SO">Somalia</option>
                      <option value="ZA">South Africa</option>
                      <option value="GS">South Georgia and the South Sandwich Islands</option>
                      <option value="SS">South Sudan</option>
                      <option value="ES">Spain</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="SD">Sudan</option>
                      <option value="SR">Suriname</option>
                      <option value="SJ">Svalbard and Jan Mayen</option>
                      <option value="SZ">Swaziland</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="SY">Syrian Arab Republic</option>
                      <option value="TW">Taiwan, Province of China</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TZ">Tanzania, United Republic of</option>
                      <option value="TH">Thailand</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TG">Togo</option>
                      <option value="TK">Tokelau</option>
                      <option value="TO">Tonga</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="TN">Tunisia</option>
                      <option value="TR">Turkey</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="TC">Turks and Caicos Islands</option>
                      <option value="TV">Tuvalu</option>
                      <option value="UG">Uganda</option>
                      <option value="UA">Ukraine</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="GB">United Kingdom</option>
                      <option value="US">United States</option>
                      <option value="UM">United States Minor Outlying Islands</option>
                      <option value="UY">Uruguay</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VU">Vanuatu</option>
                      <option value="VE">Venezuela</option>
                      <option value="VN">Viet Nam</option>
                      <option value="VG">Virgin Islands, British</option>
                      <option value="VI">Virgin Islands, U.s.</option>
                      <option value="WF">Wallis and Futuna</option>
                      <option value="EH">Western Sahara</option>
                      <option value="YE">Yemen</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                    </Field>
                    <ErrorMessage name="country" className='tool_tip' component="div" />

                  </div>
                 
                </div>


             
                <button type="submit" className="w-full text-white bg-[#374151]  hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Create an account</button>
                {/* </form> */}
              </Form>
            </Formik>
          </div>

        </div>
      </div>

      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </section>
  );
}

export default UserSignup