import React from 'react'

const Searchbar1 = ({handleEvent}) => {
    return (
        <>
               <div class="flex items-stretch sm:w-full justify-center  h-[52px] sm:h-[50px] mb-3 bg-gray-800  rounded-full">
              <input
                  class=" px-3 pl-9 ml-1 sm:pl-4 w-[452px] lg:[80%] md:w-[50%] sm:w-[75%] my-1  rounded-l-full text-sm text-gray-300 bg-gray-800 border-r-2 border-l-0 border-t-0 border-b-0 border-gray-600 focus:border-gray-600 focus:ring-gray-800  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  placeholder="Discover Events"
                  type="text"
                  name="member[email]"
                  id="member_email"
                  required=""
              />
              <hr className=" w-[2px] h-[55px] -z-20 sm:h-[48px] text-gray-600" />

              <input
                  class=" py-3  sm:pl-3 w-[320px] lg-[90%]  md:w-[30%] sm:w-[50%]  my-1 text-sm text-gray-300 rounded-r-full bg-gray-800  border-none"
                  placeholder="Los Angeles"
                  type="text"
                  name="member[email]"
                  id="member_email"
                  required=""
              />
              <a
              onClick={handleEvent}
                  class="py-2 px-3 my-2 mr-3 sm:px-2 sm:py-1 text-sm sm:text-[10px] font-medium text-center text-white rounded-full cursor-pointer  bg-[#111928] hover:bg-gray-600 focus:ring-4 focus:ring-gray-800 dark:bg-gray-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 flex justify-center items-center"
                  name="member_submit flex justify-center items-center"
                  id="member_submit"
              >
                  Search
              </a>
          </div>


        </>
    )
}

export default Searchbar1