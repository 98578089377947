import React from 'react';

const WelcomeSection = ({ user }) => {

    //console.log(user);

    return (
        <div className='container-xl'>
            <div className='grid grid-cols-1
            place-content-center md:place-content-between sm:grid-cols-1 text-white'>
                <div className="">
                    <h3 className='text-white heading-3 text-center'>Welcome
                        {
                            user && user?.name ?
                                " " + user.name : " John"
                        }
                        ,
                    </h3>
                    <p className='text-center'>You don't have any upcoming events right now. <a className='underline' href="">Host one!</a></p>
                </div>

            </div>
        </div>
    )
}

export default WelcomeSection;