import React from 'react'
import NavbarService from '../components/NavbarService'
import ServiceFooter from '../components/ServiceFooter'
const ServicePricing = () => {
  return (
    <div className=' w-full '>
    <NavbarService/>
        <section className="bg-white dark:bg-gray-900">
  <div className="py-32 px-14 mx-auto w-full lg:py-16 lg:px-6 sm:py-16 sm:pt-24 justify-center text-center">
      <div className=" justify-center w-full text-center mb-8 lg:mb-12 mb-12 ml-3">
          <h2 className="mb-4 text-4xl tracking-tight font-inter font-extrabold text-gray-900 dark:text-white">Pricing</h2>
          <p className="mb-5  font-light text-gray-500 font-inter text-xl dark:text-gray-400">Choose the pricing option that best fits your business needs and start elevating your services or venues in the event industry</p>
      </div>
      <div className=" grid grid-cols-3 gap-10 font-inter md:grid-cols-1 sm:grid-cols-1 sm:gap-6 xl:gap-10 ">
        
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 className="mb-4 text-2xl font-semibold">Made for everyone</h3>
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">No Monthly Fee</p>
              <div className="flex flex-col justify-center items-center my-8">
                  <p className="mr-2 text-5xl font-extrabold font-inter">15%</p>
                  <p className="text-gray-500 dark:text-gray-400">Commission</p>
              </div>
           
              <ul role="list" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Utilize the full range of our platform features, including booking management, seamless communication with clients</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>No upfront costs</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Pay a 15% commission on each successful booking </span>
                  </li>
                 
              </ul>
              <a href="/servicelandingpage" className="text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
          </div>
         
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 className="mb-4 text-2xl font-semibold">Professionals</h3>
              <p className="font-light text-[#3F83F8] sm:text-lg dark:text-gray-400">$150 Monthly Fee </p>
              <div className="flex flex-col justify-center items-center my-8">
                  <p className="mr-2 text-5xl font-extrabold font-inter">10%</p>
                  <p className="text-gray-500 dark:text-gray-400">Commission</p>
              </div>
          
              <ul role="list" className="mb-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Utilize the full range of our platform features, including booking management, seamless communication with clients</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>No setup, or hidden fees</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Pay a 10% commission on each successful booking </span>
                  </li>
                 
              </ul>
              <a href="/servicelandingpage" className="text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Get started</a>
          </div>
        
          <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 className="mb-4 text-2xl font-semibold">Enterprise</h3>
              <p className="font-light text-[#3F83F8] sm:text-lg dark:text-gray-400">Custom Solutions</p>
            
            
              <ul role="list" className="mb-8 mt-8 space-y-4 text-left">
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Utilize the full range of our platform features, including booking management, seamless communication with clients</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>No setup, or hidden fees</span>
                  </li>
                  <li className="flex items-center space-x-3">
                    
                      <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                      <span>Pay a custom commission on each successful booking based on business revenue  </span>
                  </li>
                  <li className="flex items-center space-x-3">
                    
                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                    <span>Priority customer service  </span>
                </li>
                 
              </ul>
              <a href="#" className="text-white mt-10 bg-[#1F2A37] hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900">Contact Us</a>
           </div>
      </div>
  </div>
  <div className=" px-4 py-12 mx-auto sm:py-16 lg:px-6 w-[50%] md:w-[80%] sm:w-[90%]">
      <div className="max-w-screen-md mx-auto text-center mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">How can we help you?</h2>
          <p className="mb-8 font-normal text-xl text-gray-500 dark:text-gray-400 sm:text-xl">Here are a few of the questions we get the most. If you don't see what's on your mind, reach out to us anytime on phone, chat, or email.</p>
          <label for="email-adress-icon" class="block mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">Your Email</label>
          <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
              </div>
              <input type="text" id="email-adress-icon" class="block w-full p-4 pl-12 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Type keywords to find answers"/>
          </div>
          <p className="mt-4 text-sm text-gray-500 dark:text-gray-400">You can also browse the topics below to find what you are looking for.</p>
      </div>
      </div>
</section>

 
    
<ServiceFooter/>
    </div>
  )
}

export default ServicePricing