import React from 'react'
import entrlogo from "../img/entrlogoblack.svg"
const Servicelogincomponent = () => {
  return (
    <div>
        <div>
              <a href="/" className="inline-flex items-center font-['Inter']  mb-8 text-2xl font-semibold text-gray-900 lg:mb-10 dark:text-white">
                  <img className="w-8 h-8 mr-2" src={entrlogo} alt="logo"/>
                  Entr    
              </a>
              <div className="flex font-inter">
                  <svg className="w-5 h-5 mr-2 text-primary-600 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                  <div>
                      <h3 className="mb-2 text-xl font-bold leading-none text-blue-500 dark:text-white">Get started quickly</h3>
                      <p className="mb-2 font-normal text-base text-gray-500 dark:text-gray-400">Integrate with developer-friendly APIs or choose low-code or pre-built solutions.</p>
                  </div>
              </div>
              <div className="flex pt-8 font-inter">
                  <svg className="w-5 h-5 mr-2 text-primary-600 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                  <div>
                      <h3 className="mb-2 text-xl font-bold leading-none text-blue-500 dark:text-white">Support any business model</h3>
                      <p className="mb-2 font-normal text-base text-gray-500 dark:text-gray-400">Host code that you don't want to share with the world in private.</p>
                  </div>
              </div>
              <div className="flex pt-8 font-inter">
                  <svg className="w-5 h-5 mr-2 text-primary-600 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"></path></svg>
                  <div>
                      <h3 className="mb-2 text-xl font-bold leading-none text-blue-500 dark:text-white">Join millions of businesses</h3>
                      <p className="mb-2 font-normal text-base text-gray-500 dark:text-gray-400">Flowbite is trusted by ambitious startups and enterprises of every size.</p>
                  </div>
              </div>
          </div>
        
    </div>
  )
}

export default Servicelogincomponent
