import React, { useState } from 'react'
import { MdOutlineMyLocation } from 'react-icons/md'
import img1 from './imgs/img1.jpg'
import { FaLocationDot } from 'react-icons/fa6'
import './Home.css'
import { BiSolidStar } from "react-icons/bi"
import { AiOutlineHeart } from 'react-icons/ai'
import { homedata } from './homedata'
import DemoPopup from '../DemoPopup'
const Home = () => {
    const [demo, setDemo] = useState(false)
    const handleClick = ()=>{
        setDemo(!demo)
    }
    return (
        <div className='relative'>
            <div className=' w-[85%] mx-auto '>
                <div className='mt-10 text-white'>
                    <div className="flex flex-col md:justify-center items-start ">
                        <h3 className='text-white heading-3-v2'>Discover</h3>
                        <div className="bg-[#3D404E] rounded-[45px] flex p-2 py-1 items-center gap-2 mt-2" >
                            Select Location <MdOutlineMyLocation />
                        </div>
                    </div>
                    <div className="flex justify-between mt-7 mb-10 sm:mb-10">
                        <h4>Things to do</h4>
                        <span>View all</span>
                    </div>
                    <div className=" w-[350px] h-[220px] mt-4 relative  rounded-lg">
                        <div className="desc-home rounded-lg" onClick={handleClick}>
                            <img src={img1} alt="" className='object-cover w-full h-full rounded-lg' />
                            <div className="absolute top-24 text-2xl px-1">
                                Entrepreneurship Summit: Igniting Innovation
                            </div>
                            <div className="flex absolute  bottom-0 px-2 pb-5 gap-9">
                                <div className="flex gap-2 text-sm">
                                    <FaLocationDot />
                                    David Geffen Hall
                                </div>

                                <div className="text-sm">
                                    27 OCT, 2023 at 12:00PM
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* services section */}

                    <div className="flex justify-between mt-7">
                        <h4>Things to do</h4>
                        <span>View all</span>
                    </div>

                    <div className="flex  gap-5 justify-between sm:flex-col">
                        {homedata.map((data, index) => (
                            <div className="w-[350px] h-[220px] mt-4 relative rounded-lg" key={index } onClick={handleClick}>
                                <img src={img1} alt="" className='object-cover w-full h-full rounded-lg' />
                                <div className='flex justify-end absolute top-2 rounded-full right-2 bg-[black] p-2 cursor-pointer  '>
                                    <AiOutlineHeart className='text-[white] text-xl cursor-pointer ' />
                                </div>
                                <div className="desc-home rounded-lg ">
                                    <div className="absolute top-[5.8rem] text-2xl px-1 cursor-pointer ">
                                        {data.desc}
                                    </div>
                                    <div className="flex absolute justify-around  bottom-0 px-2 pb-5 gap-9 items-center">
                                        <div className="flex gap-2 text-sm items-center cursor-pointer">
                                            <FaLocationDot />
                                            Los Angeles
                                            <span className='text-[#E27625] flex items-start'><BiSolidStar /> 2.9</span>
                                        </div>

                                        <div className="bg-[#111928]  p-1 ml-11 cursor-pointer">
                                            from $100/hr
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>


                    {/* venues */}

                    <div className="flex justify-between mt-7">
                        <h4>Things to do</h4>
                        <span>View all</span>
                    </div>

                    <div className="flex justify-between gap-5 sm:flex-col pb-6">
                        {homedata.map((data, index) => (
                            <div className="w-[350px] h-[220px] mt-4 relative rounded-lg" key={index}  onClick={handleClick}>
                                <img src={img1} alt="" className='object-cover w-full h-full rounded-lg' />
                                <div className='flex justify-end absolute top-2 rounded-full right-2 bg-[black] p-2 cursor-pointer  '>
                                    <AiOutlineHeart className='text-[white] text-xl cursor-pointer ' />
                                </div>
                                <div className="desc-home rounded-lg">
                                    <div className="absolute top-[5.8rem] text-2xl px-1 ">
                                        {data.desc}
                                    </div>
                                    <div className="flex absolute justify-around  bottom-0 px-2 pb-5 gap-9 items-center">
                                        <div className="flex gap-2 text-sm items-center">
                                            <FaLocationDot />
                                            Los Angeles
                                            <span className='text-[#E27625] flex items-start'><BiSolidStar /> 2.9</span>
                                        </div>

                                        <div className="bg-[#111928]  p-1 ml-11">
                                            from $100/hr
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {
                demo && (
                    <div className='absolute top-0 z-40 w-[100%] h-[100%]'>
                        <DemoPopup  onClose = {()=> setDemo(null)}/>
                    </div>
                )
            }
        </div>
    )
}

export default Home