import React from 'react'
import NavbarService from '../components/NavbarService'
import Servicesignupcomponent from './Servicesignupcomponent'
import { useState, useEffect, useRef } from "react";
import Stepper from "../components/multistep/Stepper";
import StepperControl from "../components/multistep/StepperControl";
import { UseContextProvider } from "../context/StepperContext";
import Servicesignup1 from './Servicesignup1';
import Servicesignupaccount from './Servicesignupaccount';
import Servicesignupotp from './Servicesignupotp';
import Servicesignupconfirmation from './Servicesignupconfirmation';

const ServiceSignup = () => {

  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    "Personal Info",
    "Account Info",
    "Confirmation",
  ];


  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };


  const displayStep = (step, handleClick) => {
    switch (step) {
      case 1:
        return <Servicesignup1 handleClick={handleClick} />;
      case 2:
        return <Servicesignupaccount handleClick={handleClick} />;
      // case 3:
      //   return <Servicesignupotp/>;

      default:

    }
  };


  const [newStep, setNewStep] = useState([]);
  const stepsRef = useRef();

  const updateStep = (stepNumber, steps) => {
    const newSteps = [...steps];
    let count = 0;
    while (count < newSteps.length) {
      //current step
      if (count === stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: true,
          selected: true,
          completed: true,
        };
        count++;
      }

      //step completed
      else if (count < stepNumber) {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: true,
          completed: true,
        };
        count++;
      }
      //step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          highlighted: false,
          selected: false,
          completed: false,
        };
        count++;
      }
    }

    return newSteps;
  };

  
  return (
    <div className=' w-full'>

      <section className="bg-gray-50  dark:bg-gray-900     ">
        <div className="lg:flex flex flex-row lg:flex-row md:flex-col    sm:flex-col ">
          <div className='flex flex-col   justify-center w-[50%]  xl:w-[50%] lg:hidden md:hidden sm:hidden'>
            <Servicesignupcomponent />
          </div>
          <div className='w-full'>
            <div className=" mx-4 w-[70%] mt-20 sm:w-[80%] sm:mx-0 sm:my-10 sm:px-4  p-4 flex justify-between items-center">
              <Stepper steps={steps} currentStep={currentStep} />
              {/* {stepsDisplay} */}
            </div>
            <div className="flex flex-col  justify-center w-full  items-center  md:w-full  md:px-8 sm:px-0 xl:px-0">

              <div className="w-full  ">
                <UseContextProvider>{displayStep(currentStep, handleClick)}</UseContextProvider>
              </div>
            </div>

            <div className=" w-[80%] sm:w-full flex flex-row gap-4 sm:flex-col sm:gap-4 p-10 mx-10 sm:p-6  sm:mx-0  ">
              <button
                onClick={() => handleClick()}
                className={`cursor-pointer w-full text-center items-center px-5 py-2.5 sm:py-3.5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 ${currentStep === 1 ? " cursor-not-allowed opacity-50 hidden " : ""
                  }`}
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ServiceSignup