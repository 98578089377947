import React, { useState, useEffect } from 'react'
import { useStepperContext } from "../context/StepperContext";
//import { motion } from "framer-motion";
import { saveServiceprovider } from "../utils/firebaseFunctions";
import bcrypt from 'bcryptjs'
import { useNavigate } from 'react-router-dom'
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
//import Swal from 'sweetalert2';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import { useStateValue } from "../context/StateProvider";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PasswordStrengthBar from 'react-password-strength-bar';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import IconButton from '@mui/material/IconButton';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


const Servicesignupaccount = () => {
  const auth = getAuth();
  const navigate = useNavigate()
  const [passwoord, setPassword] = useState("");
  

  const [password, setPasswoord] = useState("");
  //const [emaill, setEmaill] = useState("");
  const [phone, setPhone] = useState("");
  //const [email, setEmail] = useState("");
  const { userData, setUserData } = useStepperContext();
  const [confirmpassword, setconfirmpassword] = useState()
  const [alertStatus, setAlertStatus] = useState("");
  const [alert, setAlert] = useState("");
  const [getserviceeprovider, setgetserviceprovider] = useState();
  const [fields, setFields] = useState(false);
  const [fieldss, setFieldss] = useState(false);
  const [field, setField] = useState(false);
  const [passwords, setPasswords] = useState("");
  const [{ serviceprovider }, dispatch] = useStateValue();
  const getemails = [];
  const [inputPassword, setInputPassword] = useState('');

  const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')

  const getServiceprovider = async () => {
    const user = await getDocs(
      query(collection(firestore, "Serviceprovider"), orderBy("id", "desc"))
    );

    setgetserviceprovider(user.docs.map((doc) => doc.data()));
    // //console.log(getuser)
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
    //  }
  };

 

  const validationSchema = Yup.object().shape({
   
    checkbox: Yup.boolean().oneOf([true], 'Checkbox must be checked'),

  });


  const initialValues = {
    fname: '',
    lname: '',
    buisness: '',
    password: '',
    confirmpassword: '',
    country: '',
    phone: '',
    checkbox: "",
  };


  const passwordvalidate = (e) => {
    setPasswoord(e.target.value)
    var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
    var test = reg.test(e.target.value);

    if (test) {
      //console.log("pass")
      setPasswords("Strong")
      setAlertStatus("success");
      setFieldss(true);

      
    } else {
      setPasswords("Weak")
      setAlertStatus("danger");
      setFieldss(true);

     
    }
  }


  const cpassword = (e) => {
    setconfirmpassword(e.target.value)

    if (e.target.value !== password) {
      setPassword("Password did not matched")
      setFields(true);
      setAlert("danger");

    }
    else {
      setPassword("Password  matched")
      setAlert("success");
      setFields(true);

      setTimeout(() => {
        setFields(false);
      }, 4000);
    }

  }

  const [getuser, setgetuser] = useState();



  const storedValue = window.localStorage.getItem('emailForSignIn');
  const storedpassword = window.localStorage.getItem('servicepassword');

  //console.log(storedValue, storedpassword);


  const getAllUser = async () => {
    const addUser = collection(firestore, "Serviceprovider");
    const getData = await getDocs(addUser);
    // //console.log(TableHeader)
    setgetuser(getData.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };


  const confirm = async (values) => {

    //console.log(values.password);
    const hashedPassword = bcrypt.hashSync(values.password, '$2a$10$CwTycUXWue0Thq9StjUM0u')

    try {
      await signOut(auth)
        .then(() => {
        })
        .catch((error) => {
          // Error occurred during logout
        });
      if (serviceprovider) {
        const datacategory = {
          id: `${Date.now()}`,
          profession: userData.profession,
          fname: values.fname,
          lname: values.lname,
          buisness: values.buisness ? values.buisness : "",
          email: serviceprovider.email,
          password: hashedPassword,
          country: values.country,
          phone: phone,

        };
        saveServiceprovider(datacategory);
      }
      else {
        const datacategory = {
          id: `${Date.now()}`,
          profession: userData.profession,
          fname: values.fname,
          lname: values.lname,
          buisness: values.buisness ? values.buisness : "",
          email: storedValue,
          password: hashedPassword,
          country: values.country,
          phone: phone,

        };
        saveServiceprovider(datacategory);
      }
      //console.log("success")

      setUserData(null);


      //console.log("redirectToCheckout");

      navigate("/serviceconfirmation")
    }
    catch (e) {
      //console.log(e);
    }
  }



  const handlePhoneChange = (value) => {
    setPhone(value);
  };


  const handleSubmit = (values, { setSubmitting }) => {
   
  };


  useEffect(() => {
    getServiceprovider();
  }, []);


  return (
    <div className='flex flex-col  w-[80%] sm:w-[90%] sm:mx-5 sm:pr-0 mx-20 pr-20 font-inter'>
      <h1 class="mb-4 text-2xl font-extrabold tracking-tight text-gray-900 sm:mb-6 leding-tight dark:text-white">Account details</h1>
      <Formik
        initialValues={initialValues}
        //  validationSchema={validationSchema}
        validate={(values) => {
          const errors = {};
          setInputPassword(values.password);
          if (!values.fname) {
            errors.fname = "Required";
          }
          if (!values.lname) {
            errors.lname = "Required";
          }
          // if (!values.buisness) {
          //   errors.buisness = "Required";
          // }


          if (!values.password) {
            errors.password = "Required";

          }
          var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
          var test = reg.test(values.password);

          if (values.password && !test) {
            errors.password = "weak";
            setFieldss(true);
          }
          else {
            setFieldss(false);
          }

          if (!values.confirmpassword) {
            errors.confirmpassword = "Required";

          }
          if (values.confirmpassword && values.confirmpassword !== values.password) {
            errors.confirmpassword = "Password do not match";
          }

          if (!phone) {
            errors.phone = "Required";
          }

          const phoneRegex = /^\+[1-9]\d{1,14}$/;
          var phonetest = phoneRegex.test(phone);

          if (phone && !phonetest) {
            errors.phone = "invalid phone number"
          }
          if (!values.country) {
            errors.country = "Required";
          }

          if (!values.checkbox) {
            errors.checkbox = "Required";
          }


          return errors;
        }}
        onSubmit={confirm}
      >
        <Form   >
          <div class="grid gap-5 my-3 grid-cols-3 sm:grid-cols-1">
            <div>
              <label for="fname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
              <Field type="text" name="fname" id="fname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="Bonnie" required="" />
              <ErrorMessage name="fname" className='tool_tip' component="div" />
            </div>
            <div>
              <label for="lname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
              <Field type="text" name="lname" id="lname" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="smith" required="" />
              <ErrorMessage name="lname" className='tool_tip' component="div" />
            </div>
            <div>
              <label for="buisness" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Business <span className='text-xs font-small text-gray-700 dark:text-white'>{" (if any)"}</span></label>
              <Field type="text" name="buisness" id="buisness" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" placeholder="business name" />
              {/* <ErrorMessage name="buisness" className='tool_tip' component="div" /> */}
            </div>
          </div>
          <div class="grid gap-5 my-3 grid-cols-2 sm:grid-cols-1">


            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password
                <Tooltip className='ml-1' placement='top' title={
                  <div style={{ padding: '10px', width: '500px' }} id="message">
                    <h3 style={{ fontSize: '16px' }}>Password must contain the following:</h3>
                    <ol ttype="A" style={{ fontSize: '14px', paddingTop: '10px' }}>
                      <li style={{ margin: '4px 0px', padding: '1px 0px' }} className='text-md' id="letter" >1. A <b>lowercase</b> letter</li>
                      <li style={{ margin: '4px 0px', padding: '1px 0px' }} id="capital">2. A <b>capital (uppercase)</b> letter</li>
                      <li style={{ margin: '4px 0px', padding: '1px 0px' }} id="number" >3. A <b>number</b></li>
                      <li style={{ margin: '4px 0px', padding: '1px 0px' }} id="length">4. Minimum <b>8 characters</b></li>
                    </ol>
                  </div>
                }
                >
                  <HelpOutlineIcon className='cursor-pointer' fontSize='small' />
                  {/* <IconButton>
                        </IconButton> */}
                </Tooltip>
              </label>
              <Field type="password" name="password" id="password" placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${fieldss && alertStatus === "danger"
                ? "  bg-red-50 border border-red-500 text-red-900 placeholder-red-700"
                : "border-gray-300 "
                }`} required=""
                onFocus={() => setFieldss(false)}
              />
              <PasswordStrengthBar className='mt-2' password={inputPassword} />
             

            </div>
            <div>
              <label for="confirmpassword" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
              <Field type="password" name="confirmpassword" id="confirm-password" placeholder="Password" className={`bg-gray-50 border  text-gray-900 sm:text-sm rounded-lg   block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ${fields && alert === "danger"
                ? "  bg-red-50 border border-red-500 text-red-900 placeholder-red-700"
                : "border-gray-300 "
                }`} required="" />

              {fields && (<p class={`mt-2 text-sm ${alert === "danger"
                ? " text-red-800"
                : " text-emerald-800"
                }`}>
                {passwoord}
              </p>)}
              <ErrorMessage name="confirmpassword" className='tool_tip' component="div" />

            </div>
            <div>
              <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone No</label>


              <PhoneInput className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultCountry="US"
                id="phone"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="Enter phone number"
                international
              />

              <ErrorMessage name="phone" className='tool_tip' component="div" />
             
            </div>
            <div>
              <label for="country" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Country</label>
              <Field as="select" id="country" name="country" class="bg-gray-50 border p-5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                {/* <select id="country"  name="country" onChange={e=> setCountry(e.target.value)} class="bg-gray-50 border p-5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"> */}
                <option selected="">Choose your country</option>
                <option value="USA">USA</option>
                <option value="UK">United Kingdom</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
                <option value="ES">Spain</option>
                <option value="JP">Japan</option>
                <option value="AUS">Australia</option>
              </Field>
              {/* </select> */}
              <ErrorMessage name="country" className='tool_tip' component="div" />
            </div>
          
          </div>
          <div class="mb-3 ">
            <div>
              <div class="flex items-start">
                <div class="flex items-center h-5">
                  <Field
                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                    type="checkbox"
                    name="checkbox"

                  />
                  
                </div>
                <div class="ml-3 text-sm">
                  <label for="terms" class="font-light text-gray-500 dark:text-gray-300">By signing up, you are creating a Service account, and you agree to <a class="font-medium text-primary-600 dark:text-primary-500 hover:underline" href="/termandcondition">Terms of Use</a> and <a class="font-medium text-primary-600 dark:text-primary-500 hover:underline" href="/privacypolicy">Privacy Policy</a>.</label>
                </div>

              </div>

              <ErrorMessage name="checkbox" className='tool_tip' component="div" />

            </div>

            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="newsletter" name='newsletter' aria-describedby="newsletter" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
              </div>
              <div class="ml-3 text-sm">
                <label for="newsletter" class="font-light text-gray-500 dark:text-gray-300">Email me about product updates and resources.</label>
              </div>
            </div>


          </div>
          <div class=" w-[100%] sm:w-[100%]  sm:mx-0">
            <button type="submit"
              class="w-full text-white bg-blue-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 sm:py-3.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Verify account</button>
          </div>
        </Form>
      </Formik>

    </div>
  );
}

export default Servicesignupaccount