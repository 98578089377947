import React, { useState } from 'react'
import NavbarMain from '../components/NavbarMain';
import Section3 from '../components/landingpagecomponent/Section3';
import Section4 from '../components/landingpagecomponent/Section4';
import Section5 from '../components/landingpagecomponent/Section5';
import Section6 from '../components/landingpagecomponent/Section6';
import Section7 from '../components/landingpagecomponent/Section7';
import Footer from '../components/landingpagecomponent/Footer';
import Section1 from '../components/landingpagecomponent/Section1';
import Section2 from '../components/landingpagecomponent/Section2';
import UnitedStates from '../components/landingpagecomponent/UnitedStates';
import International from '../components/landingpagecomponent/International';
import ThingsToDo from '../components/landingpagecomponent/ThingsToDo';
import BringEvent from '../components/landingpagecomponent/BringEvent';
import './landingpage.css'
import Landingfaq from './Landingfag';
const LandingPage = () => {

  return (
    <div className='w-full landingpage' >

      <NavbarMain className='fixed top-0 w-full' />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <UnitedStates />
      <International />
      <ThingsToDo />
      <BringEvent />
      <Landingfaq />
      <Footer />
    </div>
  )
}

export default LandingPage