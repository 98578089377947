import React from 'react'
import NavbarMain from '../NavbarMain'
import Footer from './Footer'

const PrivacyPolicy = () => {
  return (
    <div>
    <NavbarMain/>
       <section class="bg-gray-900 py-10 pt-20 w-full sm:px-5">
    <div class="px-4 py-8 mx-auto sm:mx-0  text-center sm:px-0 sm:py-0 lg:py-16 lg:px-12">
        <h1 class="mb-4 text-4xl sm:text-4xl font-extrabold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-6xl text-white ">Privacy Policy</h1>
        <p class="mb-8 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-16 xl:px-48 text-gray-400">Last updated on July 5, 2023</p>
        <div class=" mb-8 lg:mb-16  sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <a href="/usersignin" class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-900">
                Get Started
                <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          
            </a>
            
        </div>
        </div>
        <div class="mx-40 sm:mx-5 px-4 py-8 sm:px-0 sm:py-0 max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-2xl font-extrabold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-6xl text-white ">Our Privacy Policy </h1>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">Thank you for using the services provided by Entr Inc. ("Entr," "we," "us," or "our"). This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you use our event management app ("App"). Please read this Privacy Policy carefully to understand our practices regarding your personal information and how we will treat it. By accessing or using the App, you agree to the terms of this Privacy Policy.</p>
       
     
      </div>
     

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">1. Information We Collect</h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">1.1 Personal Information</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> We may collect personal information that identifies you as an individual, such as your name, email address, phone number, and billing information, when you register an account, purchase tickets, or use our services.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">1.2 Usage and Device Information</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We automatically collect certain information about your use of the App, including your IP address, device information, operating system, and browser type. This information helps us understand how you interact with the App and improve its functionality. </p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">1.3 Cookies and Similar Technologies</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We use cookies and similar technologies to collect information about your browsing activities on the App. Cookies are small data files that are stored on your device. By using cookies, we can remember your preferences and provide a more personalized experience. You have the option to disable cookies through your browser settings, but please note that some features of the App may not function properly without cookies. </p>
    </div>


      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">2. How We Use Your Information </h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 2.1 Provide and Improve Services</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> We use the information we collect to provide and improve our services, personalize your experience, process transactions, communicate with you, and respond to your inquiries. </p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">2.2 Analytics and Research</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We may analyze user behavior and trends to enhance the functionality and performance of the App. This analysis is conducted in an aggregated and anonymized manner to ensure privacy.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">2.3 Marketing and Communication</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We may use your contact information to send you promotional emails, newsletters, and other marketing communications. You can opt out of receiving these communications by following the unsubscribe instructions provided in the emails or by contacting us directly.</p>
       </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">3. How We Share Your Information </h1>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> 3.1 Service Providers </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We may share your personal information with trusted service providers who assist us in operating the App, processing payments, and providing related services. These service providers are obligated to protect your information and can only use it to perform services on our behalf. </p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">3.2 Legal Compliance and Protection</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We may disclose your personal information if required by law, regulation, or legal process, or if we have a good faith belief that disclosure is necessary to protect the rights, property, or safety of Entr, our users, or others.</p>
        <p class=" font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">3.3 Business Transfer</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred as part of the transaction. We will notify you via email and/or a prominent notice on the App of any change in ownership or use of your personal information.</p>
       </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
        <h1 class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400 ">4. Data Retention </h1>
         <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">5. Security</p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or electronic storage system is completely secure, and we cannot guarantee the absolute security of your information.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">6. Your Choices and Rights </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> You have the right to access, correct, or delete your personal information. You can update your account information through the App's settings or by contacting us directly. Please note that we may retain certain information as required by law or for legitimate business purposes. </p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">7. Children's Privacy  </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400">The App is not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected personal information from a child, please contact us, and we will promptly remove the information.</p>
      </div>

      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class=" mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">8. Third-Party Links and Services </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> The App may contain links to third-party websites or services. This Privacy Policy does not apply to those third-party websites or services. We recommend reviewing the privacy policies of those websites or services before providing any personal information.</p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">9. Changes to this Privacy Policy  </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We will notify you of any material changes by posting the revised Privacy Policy on the App or through other communication channels. Your continued use of the App after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.</p>
      </div>
      <div class="mx-40 sm:mx-5 px-4 py-2 sm:px-0 sm:py-0  max-w-screen-xl lg:py-16 lg:px-12">
         <p class="mb-4 text-xl font-bold font-['Inter'] tracking-tight leading-none  md:text-5xl lg:text-xl text-gray-400">10. Contact Us </p>
        <p class="mb-4 font-light font-['Inter'] md:text-lg lg:text-xl sm:px-0 xl:px-48 text-gray-400"> If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us at privacy@entr.events.</p>
      </div>
     
        </section> 
        <Footer/>
    </div>
  )
}

export default PrivacyPolicy