import React from 'react'
import './thingstodo.css'
import { tododata } from './thingstododata';

const ThingsToDo = () => {
    // things to do

    return (
        <div>
            <div className='flex flex-col items-start mb-[3rem] sm:mt-[3rem] sm:mb-[2rem] pl-[4.8rem] gap-3 sm:pl-4 mt-[9rem] '>
                <h5 className='text-4xl text-white w-[25%] leading-12 sm:w-[90%] sm:text-2xl sm:mt-2 '>Things to do</h5>
                <p className='text-[#9FD9FF] flex gap-2 items-center text-[#2563EB]'>View All Categories <svg class="w-5 h-5 ml-2 sm:w-4 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></p>
            </div>

            <div className='thing6main'>
                    <div className='thing6-container overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide thing-scroller6 sm:ml-3' >
                        {
                            tododata.map((data, index) => (
                                <div className="thing6" key={index}>
                                    <img src={data.img} alt="" />
                                    <div className="thing6-description">
                                        <p> {data.desc}</p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
            </div>
        </div>
    )
}

export default ThingsToDo