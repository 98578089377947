import React, { useState, useEffect } from 'react';
import styles from "./service-provider.module.css";

const ServiceProviderTop = () => {

    const [serviceProvider, setServiceProvider] = useState([]);

    useEffect(() => {
        const sP = localStorage.getItem("serviceprovider");
        if (sP) {
            setServiceProvider(JSON.parse(sP));
        }
    }, []);

    //console.log(serviceProvider);

    return (
        <div className='container-xl'>
            <div className='mt-36 py-4 grid grid-cols-2
                place-content-center md:place-content-between sm:grid-cols-1 text-black '>

                <div className="flex md:justify-center items-center">
                    <h3 className={`${styles.servideProviderTopHeading}`}>Welcome Back, <span className={`${styles.servideProviderTopHeadingP2}`}>
                        {serviceProvider && serviceProvider.email ? (serviceProvider.displayName ? serviceProvider.displayName : serviceProvider.fname) : "Vendor"}
                    </span> </h3>
                </div>
                <div className='flex justify-end md:justify-center pt-1 items-center md:mt-2'>
                    <p className={` cursor-pointer ${styles.servideProviderTopTxt1}`}>Details</p>
                </div>

            </div>

            <div className='py-4 grid grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-4 text-white'>

                <div className="shadow bg-white rounded-lg px-4 py-6">
                    <p className={`${styles.servideProviderCardTxt} px-2 pt-1 pb-2`}>Gross Revenue </p>

                    <h5 className={`${styles.servideProviderCardHeading} px-2 pt-2 pb-1`}>$0.00</h5>
                </div>

                <div className="shadow bg-white rounded-lg px-4 py-6">
                    <p className={`${styles.servideProviderCardTxt} px-2 pt-1 pb-2`}>Net Revenue </p>

                    <h5 className={`${styles.servideProviderCardHeading} px-2 pt-2 pb-1`}>$0.00</h5>
                </div>

                <div className="shadow bg-white rounded-lg px-4 py-6">
                    <p className={`${styles.servideProviderCardTxt} px-2 pt-1 pb-2`}>Active Service </p>

                    <h5 className={`${styles.servideProviderCardHeading} px-2 pt-2 pb-1`}>0</h5>
                </div>

                <div className="shadow bg-white rounded-lg px-4 py-6">
                    <p className={`${styles.servideProviderCardTxt} px-2 pt-1 pb-2`}>Service Booked</p>

                    <h5 className={`${styles.servideProviderCardHeading} px-2 pt-2 pb-1`}>0</h5>
                </div>

            </div>
        </div>
    )
}

export default ServiceProviderTop;