import React, { useState } from 'react';
import DateRangeIcon from '@mui/icons-material/DateRange';
import styles from "./hosting-section-style.module.css";
import DemoPopup from '../DemoPopup';

const HostingBottomSection = () => {
    const [demo, setDemo] = useState(false)
    const handleClick = () => {
        setDemo(!demo)
    }
    return (

        <div className='relative'>
            <div className='w-[80%] mx-auto text-white'>
                <div className="grid grid-cols-12 md:grid-cols-1 place-content-between gap-4 my-12 pb-40">
                    <div className='md:my-2 md:col-span-12 col-span-2'>
                        <ul className='w-30  md:w-full md:flex md:justify-between'>

                            <li className={`pb-1 border-b-2 border-b-[#0062FF] text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`}>Upcoming</li>

                            <li className={`pb-1 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`}onClick={handleClick}>Past</li>

                            <li className={`pb-1 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`} onClick={handleClick}>Drafts</li>

                            <li className={`pb-1 border-b-2 border-b-gray-900 hover:border-b-[#0062FF] hover:text-[#0062FF] text-center my-5 cursor-pointer ${styles.attendingSecondaryMenuText}`} onClick={handleClick}>My Bookings</li>
                        </ul>
                    </div>
                    <div className="pl-4 md:pl-0 md:col-span-12 col-span-10">
                        <h3 className={`md:text-center py-1 ${styles.featureHeadingText}`} >My Events</h3>
                        <div className=''></div>
                        <div className='mb-5 mt-2 flex justify-start md:justify-center pt-1 items-center md:mt-2' onClick={handleClick}>
                            <input className='user-dashboard-search placeholder-gray-400 mr-2 active:border-gray-700 focus:border-gray-700' type="search" name="Search" placeholder='Search' id="" />

                            <DateRangeIcon
                                fontSize="large" className='calendar-icon-style' />

                        </div>

                        <div className='md:h-[1rem] h-[3rem]'>

                        </div>

                        <div className="my-4 md:w-full w-11/12 flex justify-center flex-col items-center  ">

                            <h3 className={`py-2 ${styles.hostingSectionMidHeading}`}>Welcome to your dashboard</h3>

                            <p className={`py-1 ${styles.hostingSectionMidText}`} >Get started by creating your first event!</p>

                            <div className='my-4 p-8 bg-[#1F2A37] rounded-md'>
                                <p className={`py-2 ${styles.hostingSectionMidText2}`}
                                >
                                    Your event management dashboard is currently empty because you haven't created any events yet. To get started, click the "Host Event" button to create your first event. Once you've created an event, you'll be able to view and manage it from this dashboard, including ticket sales, attendee data, and more. Don't wait, start creating your events today and make your next event a success!
                                </p>
                                <p className='pt-3 pb-2'>
                                    <span className='flex justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <mask id="mask0_3950_52913" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                                                <rect x="0.0976562" width="24" height="24" fill="#D9D9D9" />
                                            </mask>
                                            <g mask="url(#mask0_3950_52913)">
                                                <path d="M6.49766 19L5.09766 17.6L10.6977 12L5.09766 6.4L6.49766 5L12.0977 10.6L17.6977 5L19.0977 6.4L13.4977 12L19.0977 17.6L17.6977 19L12.0977 13.4L6.49766 19Z" fill="#919DA9" />
                                            </g>
                                        </svg>
                                    </span>
                                </p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {
                demo && (
                    <div className='absolute top-0 z-40 w-[100%] h-[100%]'>
                        <DemoPopup onClose={() => setDemo(null)} />
                    </div>
                )
            }
        </div>
    )
}

export default HostingBottomSection;