import React from 'react'
// import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
const Section8 = () => {
  const [open, setOpen] = useState(1);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  return (
    <div>
      <section className="bg-gray-900 ">
        <div className="py-8 mb-12 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
          <h2 className="mb-6 lg:mb-8 text-3xl lg:text-4xl tracking-tight font-extrabold text-center text-white">Frequently asked questions</h2>
          <div class="mx-auto sm:mx-0 max-w-screen-md justify-center items-center ml-96 lg:ml-80 sm:ml-0 md:ml-0 ">
            <Fragment className="w-[70%] sm:w-full justify-center items-center">
              <Accordion className="w-[70%] sm:w-full justify-center items-center " open={open === 1}>
                <AccordionHeader onClick={() => handleOpen(1)} className='border-gray-700'>
                  <button type="button" class="flex justify-between items-center   py-5 w-full font-medium text-left  text-gray-400" data-accordion-target="#accordion-flush-body-1" aria-expanded="true" aria-controls="accordion-flush-body-1">
                    <span>How can I create an event on ENTR?</span>
                    <ExpandMoreIcon />
                  </button>

                </AccordionHeader>
                <AccordionBody>
                  <div id="accordion-flush-body-1" class="" aria-labelledby="accordion-flush-heading-1">
                    <div class="py-5 ">
                      <p class="mb-2 text-gray-400">To create an event, simply sign up as a host on entr and navigate to the event
                        creation page. Follow the prompts to provide event details, including date, time,
                        location, and description.</p>
                    </div>
                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 2} className="w-[70%] sm:w-full justify-center items-center">
                <AccordionHeader onClick={() => handleOpen(2)} className='border-gray-700'>

                  <button type="button" class="flex justify-between items-center py-5 w-full font-medium text-left text-gray-400" data-accordion-target="#accordion-flush-body-2" aria-expanded="false" aria-controls="accordion-flush-body-2">
                    <span>Can I sell tickets for my event on ENTR?</span>
                    <ExpandMoreIcon />    </button>

                </AccordionHeader>
                <AccordionBody>
                  <div id="accordion-flush-body-2" class="" aria-labelledby="accordion-flush-heading-2">
                    <div class="py-5 ">
                      <p class="mb-2 text-gray-400">Absolutely! ENTR provides a comprehensive ticketing system that allows you to
                        sell tickets for your event directly through the platform. You can set ticket prices,
                        manage sales, and track ticket revenue effortlessly. It’s included in free plan.</p>
                    </div>
                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 3} className="w-[70%] sm:w-full justify-center items-center">
                <AccordionHeader onClick={() => handleOpen(3)} className='border-gray-700'>

                  <button type="button" class="flex justify-between items-center py-5 w-full font-medium text-left text-gray-400" data-accordion-target="#accordion-flush-body-3" aria-expanded="false" aria-controls="accordion-flush-body-3">
                    <span>How do I invite guests and manage RSVPs?</span>
                    <ExpandMoreIcon />  </button>

                </AccordionHeader>
                <AccordionBody>
                  <div id="accordion-flush-body-3" class="" aria-labelledby="accordion-flush-heading-3">
                    <div class="py-5 ">
                      <p class="mb-2 text-gray-400">o I invite guests and manage RSVPs?
                        • ENTR provides an easy-to-use invitation system where you can send invites to
                        your desired guests. You can track RSVPs and manage guest lists through your
                        event dashboard, send live alerts and communicate with the party.</p>

                    </div>
                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 4} className="w-[70%] sm:w-full justify-center items-center">
                <AccordionHeader onClick={() => handleOpen(4)} className='border-gray-700'>

                  <button type="button" class="flex justify-between items-center py-5 w-full font-medium text-left text-gray-400" data-accordion-target="#accordion-flush-body-4" aria-expanded="false" aria-controls="accordion-flush-body-4">
                    <span>Is there a fee for using ENTR as a host?
                    </span>
                    <ExpandMoreIcon />    </button>

                </AccordionHeader>
                <AccordionBody>
                  <div id="accordion-flush-body-4" class="" aria-labelledby="accordion-flush-heading-4">
                    <div class="py-5  ">
                      <p class="mb-2 text-gray-400">We have a free tier that allows hosting up to 2 events per month. For more event
                        capacity, you can choose our paid plans.</p>

                    </div>

                  </div>
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 5} className="w-[70%] sm:w-full justify-center items-center">
                <AccordionHeader onClick={() => handleOpen(5)} className='border-gray-700'>

                  <button type="button" class="flex justify-between items-center py-5 w-full font-medium text-left text-gray-400" data-accordion-target="#accordion-flush-body-4" aria-expanded="false" aria-controls="accordion-flush-body-4">
                    <span>How can I track and manage earnings from my event?
                    </span>
                    <ExpandMoreIcon />    </button>

                </AccordionHeader>
                <AccordionBody>
                  <div id="accordion-flush-body-4" class="" aria-labelledby="accordion-flush-heading-4">
                    <div class="py-5  ">
                      <p class="mb-2 text-gray-400">Login and view your earnings dashboard, where you can track, manage and
                        instantly withdraw your event earnings. You can view detailed earnings reports,
                        track ticket sales, and monitor revenue generated.</p>

                    </div>

                  </div>
                </AccordionBody>
              </Accordion>
            </Fragment>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Section8