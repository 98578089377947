import React from 'react'
import './hover.css'
import { hpopupdata } from './Hpopupdata'
import { Link } from 'react-router-dom'

const Hostpopup = ({ handleHostLeave }) => {
  return (
    <div className='flex bg-white py-20 pt-[10rem] fixed z-[40] overflow-x-0 w-[100%] transition-transform transform scale-100 ' onMouseLeave={handleHostLeave}>
      <ul className='text-[16px] w-[75%] ml-14 flex flex-col gap-2'>
        <li className='text-[25px] text-black font-[500]'>Create an event</li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Benefits</Link> </li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Planning Guide</Link></li>
        <li className='text-[#44454E] text-[16px] pl-3 cursor-pointer'><Link to="/usersignin">Explore A.I features</Link></li>
      </ul>
      <div className="tpopup6main w-[30%]">
        <h5 className='text-black text-[20px] font-[400 ] pb-3 mt-5'>Latest Blogs</h5>
        <div className='tpopup-container  overflow-x-scroll  whitespace-nowrap scroll-smooth scrollbar-hide tpopup-scroller5' >
          {
            hpopupdata.map((data, index) => (
              <div className="tpopup6" key={index}>
                <img src={data.img} alt="" />
                <div className="t-description">
                  <h5 className='desc2'>{data.name}</h5>
                </div>
              </div>
            ))
          } 
        </div>
      </div>
    </div>
  )
}

export default Hostpopup