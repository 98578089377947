import React from 'react';
import calenderImage from "../../img/calendar.svg";

const UserDashboardMidSection = () => {
    return (
        <div className='container-xl'>
            <div className='py-10 mb-30 text-white'>
                <div>
                    <h3 className='user-dashboard-mid-heading   mb-8 mt-2'>Upcoming Events</h3>
                    <div className='flex justify-center flex-col'>
                        <div className='flex justify-center my-2'>
                            <img style={{ width: '66px', height: '48px' }} src={calenderImage} alt="" />
                        </div>

                        <h5 className='text-center user-dashboard-mid-heading my-2'>Upcoming Event</h5>

                        <p className=' text-center user-dashboard-mid-txt my-1'>There are no upcoming events at the moment. Stay tuned for exciting events coming soon!</p>

                    </div>
                </div>

                <div className='py-10'>
                    <h3 className='user-dashboard-mid-heading   mb-8 mt-20'>My Bookings</h3>
                    <div className='flex justify-center flex-col'>



                        <p className=' text-center user-dashboard-mid-txt2 my-1'>Book a venue or service for <br /> your next event</p>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserDashboardMidSection;