import React, { useEffect, useState } from 'react'
import entrlogo from "../img/entrlogoblack.svg"
import NavbarService from '../components/NavbarService'
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAuth } from "../context/serviceauth";
import bcrypt from 'bcryptjs'
import Swal from 'sweetalert2';
import { app } from "../firebase";
import { Link, Navigate } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";
import { NavLink, useNavigate } from "react-router-dom";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import { firestore } from "../firebase";
import Servicelogincomponent from '../components/Servicelogincomponent';
import ServiceFooter from '../components/ServiceFooter';

import makeStyles from '@mui/styles/makeStyles';
import CustomError from '../components/CustomError/CustomError';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));


const ServiceLogin = () => {

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const firebaseAuth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const [getserviceprovider, setgetserviceprovider] = useState(null);
  const [getemailuser, setgetemailuser] = useState([]);
  const { login } = useAuth();
  const [{ serviceprovider }, dispatch] = useStateValue();
  const [isMenu, setIsMenu] = useState(false);

  const [err, setErr] = useState("");

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };


  const getServiceprovider = async () => {
    const user = await getDocs(
      query(collection(firestore, "Serviceprovider"), orderBy("id", "desc"))
    );

    setgetserviceprovider(user.docs.map((doc) => doc.data()));
    // //console.log(getuser)
  };

  useEffect(() => {
    getServiceprovider();
  }, []);

  const login1 = async () => {
    // if (!serviceprovider) {

    setErr("");

    if (getserviceprovider != null) {

      setOpen(true);
      const {
        user: { refreshToken, providerData },
      } =
        await signInWithPopup(firebaseAuth, provider)
      //  .then(() => {
      dispatch({
        type: actionType.SERVICE_PROVIDER,
        serviceprovider: providerData[0],
      });

      //console.log(serviceprovider);
      if (providerData[0]) {
        if (providerData[0].email) {
          var data = getserviceprovider?.filter(val => val.email === providerData[0].email);
          //console.log(data);
          getemail.push(data[0]);
          console.log(serviceprovider, getemail);

          if (providerData[0].email == getemail[0]?.email) {
            login("serviceprovider");
            localStorage.setItem("serviceprovider", JSON.stringify(providerData[0]));
            setOpen(false);
            navigate("/servicelandingpage/servicedashboard", { replace: true });
          }
          else {
            setOpen(false);
            setErr("User not found.");
            
          }
        }
      }
      else {
        setOpen(false);
        setErr("Something Went Wrong, Please Try Again.");
      }

    }
    else {
      setErr("Server busy, please retry");
    }

  };



  const hashedPassword = bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u')

  const getemail = [];
  const signIn = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var test1 = emailRegex.test(email);
    if (!test1) {
      //console.log("Email Format Wrong!");
      setErr("Email Format Incorrect!");
      return;
    }

    var data = getserviceprovider?.filter(val => val.email === email)
    //console.log(data);
    if (data) {
      getemail.push(data[0]);
    }
    //console.log(getemail);
    {
      getserviceprovider.map((val, ind) => {
        if (
          email === val.email &&
          hashedPassword === val.password

        ) {
          // //console.log("true")
          dispatch({
            type: actionType.SERVICE_PROVIDER,
            user: getemail[0],
          });
          login("user");
          localStorage.setItem("serviceprovider", JSON.stringify(getemail[0]));
          // //console.log(getemail[0]);
          navigate("/servicelandingpage/servicedashboard", { replace: true });
          // setErr(false);
          // //console.log("login")
        } else {
          setErr("Email or Password Incorrect!")
          
        }
      })
    }
  };





  return (
    <div className=' w-full  bg-gray-50  '>
      <NavbarService />
      <section className="p-20 sm:p-5 pt-40 sm:pt-20 pb-40 lg:pb-36 dark:bg-gray-900 justify-center items-center flex flex-row lg:flex-row md:flex-col max-w-full   sm:flex-col     ">

        <div className="w-[65%] flex-col sm:hidden justify-center items-center  col-span-6 mr-auto lg:flex xl:mb-0">
          <Servicelogincomponent />
        </div>

        <div className="w-[35%] sm:w-full col-span-6 mx-auto bg-white rounded-lg shadow dark:bg-gray-800 md:mt-0 sm:max-w-lg xl:p-0">
          <div className="p-6 space-y-4 lg:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 sm:text-2xl dark:text-white">
              Welcome back
            </h1>
            <div className="items-center space-y-3 sm:space-x-4 sm:space-y-0 sm:flex" onClick={login1}>
              <a href="#" className="w-full inline-flex items-center justify-center py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                <svg className="w-5 h-5 mr-2" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_13183_10121)"><path d="M20.3081 10.2303C20.3081 9.55056 20.253 8.86711 20.1354 8.19836H10.7031V12.0492H16.1046C15.8804 13.2911 15.1602 14.3898 14.1057 15.0879V17.5866H17.3282C19.2205 15.8449 20.3081 13.2728 20.3081 10.2303Z" fill="#3F83F8" /><path d="M10.7019 20.0006C13.3989 20.0006 15.6734 19.1151 17.3306 17.5865L14.1081 15.0879C13.2115 15.6979 12.0541 16.0433 10.7056 16.0433C8.09669 16.0433 5.88468 14.2832 5.091 11.9169H1.76562V14.4927C3.46322 17.8695 6.92087 20.0006 10.7019 20.0006V20.0006Z" fill="#34A853" /><path d="M5.08857 11.9169C4.66969 10.6749 4.66969 9.33008 5.08857 8.08811V5.51233H1.76688C0.348541 8.33798 0.348541 11.667 1.76688 14.4927L5.08857 11.9169V11.9169Z" fill="#FBBC04" /><path d="M10.7019 3.95805C12.1276 3.936 13.5055 4.47247 14.538 5.45722L17.393 2.60218C15.5852 0.904587 13.1858 -0.0287217 10.7019 0.000673888C6.92087 0.000673888 3.46322 2.13185 1.76562 5.51234L5.08732 8.08813C5.87733 5.71811 8.09302 3.95805 10.7019 3.95805V3.95805Z" fill="#EA4335" /></g><defs><clipPath id="clip0_13183_10121"><rect width="20" height="20" fill="white" transform="translate(0.5)" /></clipPath></defs>
                </svg>
                Sign in with Google
              </a>

            </div>
            <div className="flex items-center">
              <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
              <div className="px-5 text-center text-gray-500 dark:text-gray-400">or</div>
              <div className="w-full h-0.5 bg-gray-200 dark:bg-gray-700"></div>
            </div>
            <form className="space-y-4 lg:space-y-6" action="#" onSubmit={(e) => signIn(e)}>
              <div>
                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                <input type="email" name="email" id="email" value={email} onChange={e => setEmail(e.target.value)}
                  onFocus={() => setErr("")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Enter your email" required="" />
              </div>
              <div>
                <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Password
                </label>
                <input type="password" name="password" id="password" value={password} onChange={e => setPassword(e.target.value)}
                  onFocus={() => setErr("")}
                  placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
              </div>
              {
                err.length > 0 &&
                <div className='w-full flex justify-center items-center'>
                  <CustomError message={err} />
                </div>
              }
              <div className="flex items-center justify-between">
                <div className="flex items-start">
                  <div className="flex items-center h-5">
                    <input id="remember" aria-describedby="remember" type="checkbox"

                      className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                  </div>
                  <div className="ml-3 text-sm">
                    <label for="remember" className="text-gray-500 dark:text-gray-300">Remember me</label>
                  </div>
                </div>
                <a href="/servicepassres" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
              </div>


              <button type="submit" className="w-full text-white bg-blue-700 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in to your account</button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet? <a href="/servicelandingpage" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</a>
              </p>
            </form>
          </div>
        </div>
        <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
          <CircularProgress color="inherit" />
        </Backdrop>

      </section>
      <ServiceFooter className="" />
    </div>
  )
}

export default ServiceLogin