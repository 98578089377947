import React from 'react';
import { AiOutlineSearch } from 'react-icons/ai'
import {IoLocationOutline} from 'react-icons/io5'
import {BsArrowRight} from 'react-icons/bs'
import './servicesearch.css'

const ServiceSearch = () => {
    return (
        <div className=' servicesearch w-[100%] bg-gray-50 shadow shadow-lg py-6 px-4 rounded-lg  '>
            <form className='w-full'>
                <div className="mb-6">
                    <label
                        htmlFor="email"
                        className="searchlabel block mb-2 text-sm font-bold text-gray-900 dark:text-white"
                    >
                        What do you provide?
                    </label>
                    <div className=' service-input flex items-center pl-2  border border-gray-300 block w-full  focus:border-blue-500 focus:ring-blue-500  rounded-lg bg-gray-50 bg-white'>
                        <AiOutlineSearch  className='text-2xl text-blue-700'/>
                        <input
                            type="email"
                            id="email"
                            className=" text-gray-900 text-sm border-0 rounded-lg focus:border-0 focus:ring-0  block w-full  p-2.5 outline-0   dark:placeholder-gray-400 dark:text-white  "
                            placeholder="Service"
                            required=""
                        />
                    </div>
                    <div className=' service-input flex items-center pl-2  border border-gray-300 block w-full  focus:border-blue-500 focus:ring-blue-500  rounded-lg bg-gray-50 bg-white mt-8'>
                        <IoLocationOutline  className='text-2xl text-blue-700'/>
                        <input
                            type="email"
                            id="email"
                            className=" text-gray-900 text-sm border-0 rounded-lg focus:border-0 focus:ring-0  block w-full  p-2.5 outline-0   dark:placeholder-gray-400 dark:text-white  "
                            placeholder="Location"
                            required=""
                        />
                    </div>
                </div>

                <div className='flex justify-end'>
                    <button
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 font-medium rounded-lg text-sm w-[250px] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 flex items-center gap-4"
                    >
                        List your service or venue
                        <BsArrowRight className="text-white text-md"/>
                    </button>
                </div>
            </form>


        </div>
    )
}

export default ServiceSearch