import React from 'react'
import './QuickQuestions.css'

const QuickQuestions = ({onClose}) => {
    return (
        <div className='absolute top-[19%] left-[32%] quickquestion text-white sm:left-[5%]'>
            <h4 className='text-center  text-white mt-4'>Hey!👋 quick question,</h4>

            <div className=" mt-5 ml-9 flex flex-col gap-3">
                <p>How Much events do you plan per month</p>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='first' />
                    <p className='text-sm'>Just for fun , only for celebrations or holidays</p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='first' />
                    <p className='text-sm'>1-2</p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='first' />
                    <p className='text-sm'>2-4</p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='first' />
                    <p className='text-sm'>4+</p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='first' />
                    <p className='text-sm'>10+</p>
                </label>

            </div>
            <div className=" mt-5 ml-9 flex flex-col gap-3">
                <p>How many tickets do you sell per event?</p>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='second' />
                    <p className='text-sm'>I don’t sell tickets </p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='second' />
                    <p className='text-sm'>1-10</p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='second' />
                    <p className='text-sm'>10-50</p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='second' />
                    <p className='text-sm'>50-100</p>
                </label>
                <label htmlFor="" className='flex items-center gap-2'>
                    <input type="radio" name='second' />
                    <p className='text-sm'>+1000</p>
                </label>
                <button onClick={onClose}>Enter</button>
            </div>
        </div>
    )
}

export default QuickQuestions