import React, { useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

function Servicelandingfaq() {
  const accordionData = [
    { title: 'How do I sign up to list my services?', content: "It's easy to sign up! Just go to our website and click on 'Join as service provider'. Then simply enter your contact information and service details to create your provider profile." },
    { title: 'What services can I offer?', content: 'We welcome all types of event services including catering, entertainment, lighting, decor, rentals, photography, videography, transportation, venues, and more.' },
    { title: 'Will it cost anything to join?', content: ' No, never pay for leads. We offer a free subscription with commission rates starting at 15%. Paid monthly subscriptions are also available with commissions as low as 6%.' },
    { title: 'How do I get booked for events?', content: ' Event planners on can search for and book vendors directly through the platform. To increase bookings, provide detailed profiles, reviews, images, competitive and transparent pricing.' },
    { title: 'Can I integrate my calendar?', content: ' Yes! Entr offers calendar integration with tools like Google Calendar. This keeps your bookings synced with your existing schedule' },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (index === openIndex) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div className="container mx-auto px-24 sm:px-4 pb-20 frequentcontainer bg-white mt-14">
      <h1 className="text-3xl font-bold mb-4 sm:text-2xl freqhead text-black">Frequently asked questions</h1>
      <div className='frequentmain bg-[#F1F2F3]  rounded-xl'>
        {accordionData.map((item, index) => (
          <div
            key={index}
            className={`border-b-2 p-7 border-white  transition-all ${openIndex === index ? 'open' : ''
              }`}
          >
            <button
              className="flex items-center justify-between w-full p-2 sm:pl-0 focus:outline-none"
              onClick={() => toggleAccordion(index)}
            >
              <span className="text-md font-medium  sm:text-md text-black freqtitle">{item.title}</span>
              {openIndex === index ? (
                <FiMinus className="text-xl text-gray-600" />
              ) : (
                <FiPlus className="text-xl text-gray-600" />
              )}
            </button>
            <div className={`mt-2 p-2 text-sm freqcontent ${openIndex === index ? 'block' : 'hidden'}`}>
              {item.content}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Servicelandingfaq;
