import React from 'react'
import img from '../landingpagecomponent/svg/sec2.png'
const Section2 = () => {
    return (
        <div>
            <section class=" bg-transparent my-24 pl-12 mr-20 sm:pl-6 sm:my-5 sm:mr-5">
                <div class="w-full flex flex-row gap-16 sm:flex-col-reverse  md:flex-col  sm:pl-0   xl:gap-0 lg:py-16 items-center sm:py-0 sm:p-0 ">
                    <div className='w-[70%] 2xl:w-[100%] flex flex-col sec2img md:w-full  sm:w-full '>
                        <img class=" w-[90%] sm:w-full ml-4 md:ml-2 2xl:w-[100%]  sm:mr-3 sm:ml-0 justify-center dark:block" src={img} alt="dashboard image" />
                    </div>
                    <div class=" ml-10  py-5 sm:mx-0 sm:my-3  sm:justify-center sm:text-center sm:py-3 lansec2 ">
                        <ul class="flex flex-wrap sm:justify-left gap-8 mb-5 text-center mb-2" id="deviceTabs" role="tablist">
                            <li role="presentation" class="inline-flex flex-col items-center gap-2.5">
                                <button id="ios-tab" class="inline-flex items-center justify-center w-12 h-12 rounded-lg  text-gray-400 bg-gray-800  hover:bg-gray-700 aria-selected:bg-gray-800 aria-selected:text-white aria-selected:dark:text-white"
                                    type="button" role="tab" aria-controls="ios" aria-selected="false" >
                                    <svg aria-hidden="true" class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path
                                            d="M19.665 16.811a10.316 10.316 0 0 1-1.021 1.837c-.537.767-.978 1.297-1.316 1.592-.525.482-1.089.73-1.692.744-.432 0-.954-.123-1.562-.373-.61-.249-1.17-.371-1.683-.371-.537 0-1.113.122-1.73.371-.616.25-1.114.381-1.495.393-.577.025-1.154-.229-1.729-.764-.367-.32-.826-.87-1.377-1.648-.59-.829-1.075-1.794-1.455-2.891-.407-1.187-.611-2.335-.611-3.447 0-1.273.275-2.372.826-3.292a4.857 4.857 0 0 1 1.73-1.751 4.65 4.65 0 0 1 2.34-.662c.46 0 1.063.142 1.81.422s1.227.422 1.436.422c.158 0 .689-.167 1.593-.498.853-.307 1.573-.434 2.163-.384 1.6.129 2.801.759 3.6 1.895-1.43.867-2.137 2.08-2.123 3.637.012 1.213.453 2.222 1.317 3.023a4.33 4.33 0 0 0 1.315.863c-.106.307-.218.6-.336.882zM15.998 2.38c0 .95-.348 1.838-1.039 2.659-.836.976-1.846 1.541-2.941 1.452a2.955 2.955 0 0 1-.021-.36c0-.913.396-1.889 1.103-2.688.352-.404.8-.741 1.343-1.009.542-.264 1.054-.41 1.536-.435.013.128.019.255.019.381z">
                                        </path>
                                    </svg>
                                    <span class="sr-only">iOS</span>
                                </button>
                                <span class="text-base font-medium text-gray-500 dark:text-gray-400">
                                    iOS
                                </span>
                            </li>

                            <li role="presentation" class="inline-flex flex-col items-center gap-2.5">
                                <button id="android-tab" class="inline-flex items-center justify-center w-12 h-12  text-gray-400  bg-gray-800 hover:bg-gray-700 rounded-lg aria-selected:bg-gray-800 aria-selected:text-white aria-selected:dark:text-white"
                                    type="button" role="tab" aria-controls="android" aria-selected="false">
                                    <svg aria-hidden="true" class="w-8 h-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                        <path
                                            d="M18.344 8.71c-.707 0-1.279.572-1.279 1.278v4.53a1.278 1.278 0 1 0 2.558 0v-4.53c0-.706-.572-1.278-1.279-1.278zm-12.688 0c-.707 0-1.279.572-1.279 1.278v4.53a1.278 1.278 0 1 0 2.558 0v-4.53A1.277 1.277 0 0 0 5.656 8.71zm8.889-3.809.768-1.388a.343.343 0 0 0-.133-.47.344.344 0 0 0-.471.135l-.789 1.42c-.588-.235-1.236-.368-1.92-.368s-1.332.132-1.92.367l-.789-1.418a.344.344 0 0 0-.471-.135.345.345 0 0 0-.133.47l.768 1.388C8.072 5.69 7.148 7.099 7.148 8.71c0 .011 0 .021.002.034-.002.006-.002.013-.002.019v.001h9.703V8.71c.001-1.611-.923-3.019-2.306-3.809zM9.76 6.869a.346.346 0 1 1 .005-.693.346.346 0 0 1-.005.693zm4.48 0a.347.347 0 0 1 0-.692.346.346 0 0 1 0 .692zM7.148 16.12c0 .646.525 1.171 1.174 1.171h.586v2.401a1.279 1.279 0 0 0 2.558 0v-2.401h1.066v2.401c0 .707.572 1.278 1.277 1.278.709 0 1.281-.571 1.281-1.278v-2.401h.586c.648 0 1.174-.524 1.174-1.171V9.028H7.148v7.092z">
                                        </path>
                                    </svg>
                                </button>
                                <span class="text-base font-medium text-gray-500 dark:text-gray-400">
                                    Android
                                </span>
                            </li>
                        </ul>
                        <h1 class=" sec3heading mb-4 text-5xl font-['Inter']  tracking-tight leading-none md:text-5xl xl:text-5xl text-white sm:text-3xl sm:text-left">Don't just plan an event, create an experience</h1>
                        <p class=" lan2p mb-6 font-normal w-[90%] sm:w-full font-['Inter']  lg:mb-8 md:text-md text-md text-gray-400 sm:text-sm sm:text-left">Discover amazing venues, book services,  manage guests and tickets, promote your event, and execute unique experiences -
                            all in one place</p>
                        <a href="/usersignin" class="inline-flex sm:w-[100%] items-center justify-center py-3 mr-3 sm:m-0 text-base font-medium text-center text-white rounded-lg sm:p-0 sm:text-sm link2 sm:justify-left sm:text-left">
                            Learn what makes Entr different
                            <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </a>

                    </div>

                </div>
            </section>
        </div>
    )
}

export default Section2