import React from 'react'

const Servicelandingsec1 = () => {
  return (
    <section class="bg-[#F5F9FF] py-10 w-full servicelanding2">
      <div class=" flex flex-col py-8 md:py-4 sm:py-4 px-4 justify-center  text-center lg:py-16 ">
        <div class="mx-auto  sm:ml-0 md:w-full text-center sm:w-full mb-8 sm:mb-4 lg:mb-16 mt-6 md:mt-3 sm:mt-3 justify-center items-center flex flex-col items-center">
          <h2 class="mb-4 w-[60%] sm:w-full sm:text-4xl justify-center text-center text-5xl font-['Inter'] tracking-tight font-extrabold text-gray-900 ">Increase sales,
            Grow your business</h2>
          <p class="font-normal w-[60%] sm:w-full font-['Inter'] text-gray-500 sm:text-md">Receive booking requests directly from event hosts, ensuring a seamless and cost-effective process to grow your business</p>
        </div>
        <div class="flex w-full justify-center pt-10 md:pt-5 sm:pt-3 items-center divide-x divide-primary-500">
          <div class="flex pr-3  -space-x-4 sm:pr-3">
            <img class="w-10 h-10 border-2 border-white rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/bonnie-green.png" alt="bonnie avatar" />
            <img class="w-10 h-10 border-2 border-white rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/jese-leos.png" alt="jese avatar" />
            <img class="w-10 h-10 border-2 border-white rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/roberta-casas.png" alt="roberta avatar" />
            <img class="w-10 h-10 border-2 border-white rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/thomas-lean.png" alt="thomas avatar" />
          </div>
          <div>
            <div class="pl-3 flex items-center">
              <svg aria-hidden="true" class="w-5 h-5 text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>First star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            
              <p className="ml-2 text-sm font-medium text-gray-500 ">Trustpilot</p>
            </div>
            <a href="#" class="pl-3 text-white sm:pl-5">
              <span class="text-sm text-gray-500">Rated Best Over <span class="font-medium text-black">15.7k</span> Reviews</span>
            </a>
          </div>

        </div>
      </div>
    </section>
  )
}

export default Servicelandingsec1