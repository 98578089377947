import React, { useState, useEffect } from 'react';
import entrlogo from "../img/entrlogoblack.svg";
import { useNavigate } from 'react-router-dom';
import avatar from "../img/Avatar.svg";
import { useStateValue } from "../context/StateProvider";
import { actionType } from "../context/reducer";


const NavbarService = () => {
  const [isMenu, setIsMenu] = useState(false);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [isUserMenu, setIsUserMenu] = useState(false);
  const [{ user }, dispatch] = useStateValue();
  const [isevent, setisevent] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const sP = localStorage.getItem("serviceprovider");
    if (sP) {
      setServiceProvider(JSON.parse(sP));
    }
    //console.log("USER MAN: ", user);
  }, []);

  const event = () => {
    setisevent(!isevent)
  }

  const menu = async () => {
    setIsMenu(!isMenu);
  }
  const servicelogin = () => {
    navigate("/servicelogin");
  }


  const userlogin = () => {
    // if (!user) {
    //    } else {
    setIsUserMenu(!isUserMenu);
    // }
  };

  const logout = () => {
    console.log("Logout Clicked!");
    setIsUserMenu(false);
    localStorage.clear();
    navigate("/servicelandingpage");
    dispatch({
      type: actionType.SET_USER,
      user: null,
    });
    window.location.reload(true);
  };

  return (

    <nav class="bg-white w-full border-gray-200 dark:bg-gray-900 fixed px-14 sm:py-4 md:py-4 md:px-5 sm:px-3">
      <div class=" flex flex-row items-center justify-between   md:px-0 sm:px-0">
        <div class="items-center justify-between font-['Inter'] flex  md:w-auto " id="navbar-sticky" >
          <ul class="flex flex-row font-medium font-['Inter'] text-base py-4 md:p-0 xl:py-6  border-gray-100 rounded-lg  md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            <a href="/" class="flex  md:ml-0 sm:ml-0 items-center">
              <img src={entrlogo} class="h-8 mr-3" alt="Flowbite Logo" />
              <span class="self-center font-['Inter']  text-2xl font-semibold whitespace-nowrap text-[#111928]">Entr</span>
            </a>
            {
              serviceProvider && serviceProvider.email &&
              <li>
                <a href="/servicelandingpage/servicedashboard" class="block py-2 px-3 font-['Inter'] pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Dashboard</a>
              </li>
            }
           
          </ul>
        </div>


        <div class="flex flex-row  ">
          {
            serviceProvider && serviceProvider.email ?
              <button type="button" onClick={userlogin} class="flex mr-5 sm:mr-5 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                <span class="sr-only">Open user menu</span>
                <img class="w-8 h-8 rounded-full" src={serviceProvider?.photoURL ? serviceProvider.photoURL :
                  avatar} alt="user photo" />
              </button>
              :
              <button type="button" onClick={servicelogin} class="text-white sm:hidden bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3 text-center mr-3 md:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Login</button>
          }

          <div class=" md:order-3 hidden md:flex sm:flex ">
            <button data-collapse-toggle="navbar-sticky" onClick={menu} type="button" class="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg   focus:outline-none  dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <svg class="w-6 h-6 " aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
          </div>
        </div>


      </div>


      {isMenu && (
        <div class="items-center justify-between font-['Inter'] md:w-auto " id="navbar-sticky" >
          <ul class="flex flex-col gap-3 w-full justify-center items-center text-center font-medium font-['Inter'] text-base py-4 md:p-0   border-gray-100 rounded-lg   md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">

            <li>
              <a href="/servicelandingpage" class="block py-2 pl-3 font-['Inter'] pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Home</a>
            </li>
            <li>
              <a href="/servicetutorial" class="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Tutorial</a>
            </li>
            <li>
              <a href="/servicepricing" class="block py-2 pl-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Pricing</a>
            </li>
            <li>
              <button onClick={servicelogin} class="block py-2 pl-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Login</button>
            </li>
          </ul>
        </div>
      )}


      {
        isUserMenu && (
          <div className={`  absolute top-12 sm:top-9 right-2  mr-10 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600`} id="user-dropdown" style={{ minWidth: '230px' }}>
            <div className=" px-4 py-3 flex justify-between items-center ">
              <div className='mr-2'>
                <img class="w-8 h-8 rounded-full" src={serviceProvider?.photoURL ? serviceProvider.photoURL :
                  avatar} alt="user photo" />
              </div>
              <div>
                {
                  serviceProvider?.displayName ?
                    <>
                      <span class="block text-sm text-gray-900 ">{serviceProvider?.fname} {" "} {serviceProvider?.lname}</span>
                      <span class="block text-sm   truncatetext-gray-400">{serviceProvider?.email}</span>
                    </>

                    :

                    serviceProvider?.fname ?
                      <>
                        <span class="block text-sm text-gray-900 ">{serviceProvider?.fname} {" "} {serviceProvider?.lname}</span>
                        <span class="block text-sm   truncatetext-gray-400">{serviceProvider?.email}</span>

                      </>
                      :
                      <></>


                }
              </div>
            </div>
            <ul class="pl-2 pr-12 py-3 py-2" aria-labelledby="user-menu-button">
              <li>
                <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Edit Profile</p></a>
              </li>
              <li>
                <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Reviews</p></a>
              </li>
              <li>
                <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Manage Staff</p></a>
              </li>
              <li>
                <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"><p>Account Settings</p></a>
              </li>
              <li>
                <a href="#" className="flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"> <p> Support</p></a>
              </li>

            </ul>

            <ul class="py-2" aria-labelledby="user-menu-button">
              <li aria-labelledby="user-menu-button" onClick={() => logout()}>
                <p className="cursor-pointer flex flex-row gap-4 px-4 py-2 text-gray-900 text-sm 
                                        hover:text-[#002FA8]
                                        hover:bg-[#ECF1FF] 
                                        dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                >Sign Out</p>
              </li>
            </ul>
          </div>
        )

      }

    </nav>



  )
}

export default NavbarService