import React from 'react'
import img1 from './svg/bringevent/img2.svg'
import './BringEvent.css'
import { Link } from 'react-router-dom'

const BringEvent = () => {
    const btnstyle = {
        borderRadius: '90px',
        border: ' 1px solid rgba(128, 149, 204, 0.65)',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25);',
        width: ' 160px',
        height: '40px',
        padding: ' 14px 16px',
        color: '#F9FAFB',
        fontSize: '16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    return (
        <>
            <div className='mt-[5rem] pb-14 bigevent'>
                <p className='text-center text-white sm:text-left mt-3 sm:px-4'>The possibilities are endless </p>
                <h2 className='text-center bigeventtext sm:text-left text-white font-medium text-3xl w-[30%] mx-auto sm:w-[100%] sm:px-4 sm:text-2xl'>Bring your event vision to life
                    on our platform</h2>

                <div className='flex justify-center w-[55%] mx-auto mt-10 sm:w-[100%]'>
                    <img src={img1} className='object-fill w-full' />
                </div>
                <div className="flex justify-center mt-8 bringbutton">
                    <button style={btnstyle}><Link to="/usersignin">View more</Link></button>
                </div>
            </div>
        </>
    )
}

export default BringEvent