import img1 from '../landingpagecomponent/svg/sec6/img1.jpg'
import img2 from '../landingpagecomponent/svg/sec6/img2.jpg'
import img3 from '../landingpagecomponent/svg/sec6/img3.jpg'
import img4 from '../landingpagecomponent/svg/sec6/img4.png'
import img5 from '../landingpagecomponent/svg/sec6/img5.jpg'
import img53 from '../landingpagecomponent/svg/sec6/img5.3.jpg'
import img6 from '../landingpagecomponent/svg/sec6/img6.jpg'
import img7 from '../landingpagecomponent/svg/sec6/img7.jpg'
import img8 from '../landingpagecomponent/svg/sec6/img8.jpg'
import img9 from '../landingpagecomponent/svg/sec6/img9.jpg'
import img10 from '../landingpagecomponent/svg/sec6/img10.jpg'
export const sec6data = [
    {
        img: img1,
        desc: "Banquet Halls"
    },
     {
        img: img8,
        desc: "Trending Venues"
    },
    
    {
        img: img3,
        desc: "Bars and Nightclubs "
    },
     {
        img: img9,
        desc: "Rooftops"
    },
    {
        img: img4,
        desc: "Private Estates"
    },
    {
        img: img53,
        desc: "Outdoors"
    },
    {
        img: img2,
        desc: "Boats and Yachts "
    },
    {
        img: img10,
        desc: "Amazing Views"
    },
    {
        img: img5,
        desc: "Theaters"
    },
   
   
]