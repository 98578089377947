import React from 'react'
import entrlogo from "../img/Entrlogo.svg"
import avt1 from "../img/Avatar.svg"
import avt2 from "../img/Avatar1.svg"
import avt3 from "../img/Avatar2.svg"
import avt4  from "../img/Avatar3.svg"
const EnterComponent = () => {
  return (
    <div>
         <div className=' w-full' >
      <a href='/*' className=' gap-[10px] flex flex-row items-center'>
        <img src={entrlogo}/>
        <h1 className='font-["Inter"] text-2xl text-white'>Entr</h1>
      </a>
      <div className=' pt-7 gap-[10px] flex flex-col justify-center w-[70%]'>
       
        <h1 className='font-["Inter"] text-4xl font-bold text-white'>Unleash the Party Within</h1>
        <p className='text-base font-normal font-["Inter"] text-gray-200'>Join a vibrant community of party enthusiasts, event planners, and service providers, where unforgettable experiences are just a click away. Discover, create, and celebrate like never before on Entrée, the ultimate platform for bringing your wildest party dreams to life.</p>
      </div>

      <div className='flex flex-row pt-8 gap-8'>
        <div className='flex flex-row -space-x-4'>
        <img src={avt1}/>
        <img src={avt2}/>
        <img src={avt4}/>
        <img src={avt3}/>
        </div>
        <div className='h-[30px] w-[2px] bg-[#6B7280]  '> </div>
          <p className='text-gray-300  font-["Inter"] text-sm font-semibold'>Rated Best Over 15.7k Reviews</p>
        
       
      </div>
    </div>
    </div>
  )
}

export default EnterComponent