import React from 'react'
import './Section6.css'

import { sec6data } from './Section6data';
import LazyLoad from 'react-lazyload';

const Section6 = () => {

   
    return (
        <div>
            <div className='flex flex-col items-start  ml-[3rem] mb-[3rem] sm:mt-[3rem] sm:mb-[2rem] pl-[1rem] mt-[5.5rem] gap-3 sm:pl-0 sm:pt-10 sm:ml-4 '>
                <h5 className='text-[32px] text-white w-[25%] leading-12 sm:w-[90%] sm:text-2xl sm:mt-2 '>Venue categories</h5>
                <p className='text-[#2563EB] flex gap-2 items-center text-[16px]'>View All Categories <svg class="w-5 h-5 ml-2 sm:w-4 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></p>
            </div>
            <div className='section6main'>
                    <div className='main6-container overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide media-scroller6 sm:ml-3' >
                        {
                            sec6data.map((data, index) => (
                                <LazyLoad height={200} className="main6" key={index}>
                                    <img src={data.img} alt="" />
                                    <div className="main6-description">
                                        <p> {data.desc}</p>
                                    </div>
                                </LazyLoad>
                            ))
                        }
                    </div>
            </div>
        </div>
    )
}

export default Section6