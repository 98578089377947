import React from 'react';
import serviceImage from "../../img/service-provider-dashboard-mid.png";
import styles from "./service-provider.module.css";

const ServiceProviderMid = () => {

    return (
        <div className='container-xl'>

            <div className=' py-4 grid grid-cols-2
                place-content-center md:place-content-between sm:grid-cols-1 text-black '>

                <div className="flex md:justify-center items-center">
                    <h3 className={`${styles.servideProviderMidHeading}`}>Upcoming, <span className={`${styles.servideProviderMidHeadingP2}`}> This week </span> </h3>
                </div>
                <div className='flex justify-end md:justify-center pt-1 items-center md:mt-2'>
                    <p className={` cursor-pointer ${styles.servideProviderTopTxt1}`}>See More</p>
                </div>

            </div>

            <div className='py-4 flex justify-center flex-col'>
                <div className='flex justify-center mt-2'>
                    <img src={serviceImage} alt="" />
                </div>

                <h5 className={`text-center ${styles.servideProviderMidTitle} `}>Welcome to your dashboard</h5>

                <p className={`text-center ${styles.servideProviderMidTxt1} my-1`}>Your one-stop-shop for managing your services and orders</p>

                <div className='flex justify-center py-5'>
                    <button className={`bg-[#002FA8] hover:bg-[#001f6e] active:text-gray-300 active:bg-[#001f6e] btn px-4 py-2 ${styles.serviceProviderMidBtn}`}>Launching 2024</button>
                </div>

            </div>

        </div>
    )
}

export default ServiceProviderMid;