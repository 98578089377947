import React from 'react'
import './International.css'

import { interdata } from './InternationalData';
import HorizontalScroll from 'react-scroll-horizontal';

const International = () => {
    return (
        <div>
            <div className='flex flex-col items-start mb-[2rem] sm:mt-[3rem] sm:mb-[2rem] pl-[3rem] gap-3 sm:pl-4  mt-[9rem]'>
                <h5 className='text-4xl text-white w-[25%] leading-12 sm:w-[90%] sm:text-2xl sm:mt-2 '>International </h5>
            </div>

            <div className='inter6main'>
                <div className='inter6-container  overflow-x-scroll scroll whitespace-nowrap scroll-smooth scrollbar-hide inter-scroller6 sm:ml-3' >
                    {
                        interdata.map((data, index) => (
                            <div className="inter6" key={index}>
                                <img src={data.img} alt="" />
                                <div className="inter6-description">
                                    <h5>{data.heading}</h5>
                                    <p> {data.desc} </p>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default International