import React, { useState } from 'react'
import NavbarMain from '../components/NavbarMain'
import WelcomeSection from '../components/UserDashboardMain/WelcomeSection';
import AttendingSection from '../components/UserDashboardMain/AttendingSection/AttendingSection';
import AttendingBottomSection from '../components/UserDashboardMain/AttendingSection/AttendingBottomSection';
import HostingTopSection from '../components/UserDashboardMain/HostingSection/HostingTopSection';
import HostingBottomSection from '../components/UserDashboardMain/HostingSection/HostingBottomSection';
import ServiceAndVenuesTop from '../components/UserDashboardMain/ServiceAndVenues/ServiceAndVenuesTop';
import ServiceAndVenuesBottom from '../components/UserDashboardMain/ServiceAndVenues/ServiceAndVenuesBottom';
import UserDashboardMidSection from '../components/UserDashboardMain/UserDashboardMidSection';
import { useStateValue } from "../context/StateProvider";
import Home from '../components/UserDashboardMain/Home/Home';
import QuickQuestions from './QuickQuestions';

const UserDashboardMain = () => {

  const [quickques, setQuickques] = useState(true);

  const [menu, setMenu] = useState(0);
  const [{ user }, dispatch] = useStateValue();

  return (
    <div className='w-full bg-gray-900 relative'>
      <NavbarMain />
      <div className='mt-20'></div>
      <WelcomeSection user={user} />

      {/* Middle Menu Section  */}
      <div className='w-[ ] flex justify-between '>
        <div className='mt-12 w-full'>
          <ul className='flex w-full pt-4 gap-4  justify-center md:justify-center'>
            <li className={` mr-4 ${menu === 0 ? "border-b pb-1" : ""}`} onClick={() => setMenu(0)}>
              <span className='dashboard-menu-btn-txt cursor-pointer'>Home</span>
            </li>
            <li className={` mr-4 ${menu === 1 ? "border-b pb-1" : ""}`} onClick={() => setMenu(1)}>
              <span className='dashboard-menu-btn-txt cursor-pointer'>Attending </span>
            </li>
            <li className={` mr-4 ${menu === 2 ? "border-b pb-1" : ""}`} onClick={() => setMenu(2)}>
              <span className='dashboard-menu-btn-txt cursor-pointer'>Hosting </span>
            </li>
            <li className={` ${menu === 3 ? "border-b pb-1" : ""}`} onClick={() => setMenu(3)}>
              <span className='dashboard-menu-btn-txt cursor-pointer'>Service and Venues </span>
            </li>

          </ul>
        </div>

        <div className='w-none md:w-1/2 sm:w-2'>

        </div>

      </div>

      {
        menu === 0 ?
          <>
            <Home />
          </> :
          menu === 1 ?
            <>

              <AttendingBottomSection />
            </>
            : menu === 2 ?
              <>

                <HostingBottomSection />

              </>
              : menu === 3 ?
                <>
                  <ServiceAndVenuesTop />
                  <ServiceAndVenuesBottom />
                </>
                :
                <>
                  <UserDashboardMidSection />
                </>
      }

      {quickques && (
        <QuickQuestions className="absolute top-0 left-0 right-0 bottom-0" onClose={() => setQuickques(false)} />
      )}


    </div>
  )
}

export default UserDashboardMain