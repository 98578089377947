import React from 'react'
import NavbarMain from '../NavbarMain'
import Footer from './Footer'

const Aboutus = () => {
  return (
    <div className='bg-gray-900'>
    <NavbarMain/>
    <section class="bg-gray-900 antialiased">
    <div class="w-[50%] md:w-full sm:w-full px-4 pt-40 mx-auto lg:px-6 sm:py-16 lg:py-24">
        <div class="text-center">
            <div >
                <h2
                    class="text-6xl font-extrabold font-inter leading-none tracking-tight text-white sm:text-5xl lg:text-6xl">
                    Designing Interfaces: 
                </h2>
                <h2
                    class="text-6xl font-extrabold font-inter leading-none tracking-tight text-white sm:text-5xl lg:text-6xl">
                   A User-Centered Approach
                </h2>
                <p
                    class="mt-4 text-xl font-normal font-inter text-gray-400 md:max-w-3xl md:mx-auto sm:text-xl">
                    This book covers the latest design principles and techniques, including responsive design, mobile
                    interface design, and
                    user research methodologies.
                </p>
            </div>

            <div class="flex items-center justify-center gap-4 mt-8 max-w-sm mx-auto">
                <a href="#" title=""
                    class="inline-flex items-center justify-center w-full px-2 sm:px-4 py-3 text-left text-white  rounded-lg sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-gray-700 focus:outline-none focus:ring-4 "
                    role="button">
                    <svg aria-hidden="true" class="h-8 w-8 sm:w-10 sm:h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                        fill="currentColor">
                        <path
                            d="M19.665 16.811a10.316 10.316 0 0 1-1.021 1.837c-.537.767-.978 1.297-1.316 1.592-.525.482-1.089.73-1.692.744-.432 0-.954-.123-1.562-.373-.61-.249-1.17-.371-1.683-.371-.537 0-1.113.122-1.73.371-.616.25-1.114.381-1.495.393-.577.025-1.154-.229-1.729-.764-.367-.32-.826-.87-1.377-1.648-.59-.829-1.075-1.794-1.455-2.891-.407-1.187-.611-2.335-.611-3.447 0-1.273.275-2.372.826-3.292a4.857 4.857 0 0 1 1.73-1.751 4.65 4.65 0 0 1 2.34-.662c.46 0 1.063.142 1.81.422s1.227.422 1.436.422c.158 0 .689-.167 1.593-.498.853-.307 1.573-.434 2.163-.384 1.6.129 2.801.759 3.6 1.895-1.43.867-2.137 2.08-2.123 3.637.012 1.213.453 2.222 1.317 3.023a4.33 4.33 0 0 0 1.315.863c-.106.307-.218.6-.336.882zM15.998 2.38c0 .95-.348 1.838-1.039 2.659-.836.976-1.846 1.541-2.941 1.452a2.955 2.955 0 0 1-.021-.36c0-.913.396-1.889 1.103-2.688.352-.404.8-.741 1.343-1.009.542-.264 1.054-.41 1.536-.435.013.128.019.255.019.381z">
                        </path>
                    </svg>

                    <div class="ml-2.5">
                        <span class="block text-xs font-normal leading-none">
                            Download on
                        </span>
                        <span class="block text-lg font-bold leading-tight">
                            AppStore
                        </span>
                    </div>
                </a>

                <a href="#" title=""
                    class="inline-flex items-center justify-center w-full px-2 sm:px-4 py-3 text-left text-white  rounded-lg sm:w-auto bg-gray-800 hover:bg-gray-700 focus:ring-gray-700 focus:outline-none focus:ring-4 "
                    role="button">
                    <svg aria-hidden="true" class="h-8 w-8 sm:w-10 sm:h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                        fill="currentColor">
                        <path
                            d="m12.954 11.616 2.957-2.957L6.36 3.291c-.633-.342-1.226-.39-1.746-.016l8.34 8.341zm3.461 3.462 3.074-1.729c.6-.336.929-.812.929-1.34 0-.527-.329-1.004-.928-1.34l-2.783-1.563-3.133 3.132 2.841 2.84zM4.1 4.002c-.064.197-.1.417-.1.658v14.705c0 .381.084.709.236.97l8.097-8.098L4.1 4.002zm8.854 8.855L4.902 20.91c.154.059.32.09.495.09.312 0 .637-.092.968-.276l9.255-5.197-2.666-2.67z">
                        </path>
                    </svg>

                    <div class="ml-2.5">
                        <span class="block text-xs font-normal leading-none">
                            Download on
                        </span>
                        <span class="block text-lg font-bold leading-tight">
                            Google Play
                        </span>
                    </div>
                </a>
            </div>
        </div>

        <div class="my-8 sm:my-16">
            <div class="relative mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] h-[600px] w-[300px]">
                <div class="h-[32px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[72px] rounded-l-lg"></div>
                <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                <div class="h-[46px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                <div class="h-[64px] w-[3px] bg-gray-800 dark:bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                <div class="rounded-[2.5rem] overflow-hidden w-[272px] h-[572px] bg-gray-800">
                    <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-light.png" class="hidden w-[272px] h-[572px]" alt=""/>
                    <img src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/mockup-1-dark.png" class="block w-[272px] h-[572px]" alt=""/>
                </div>
            </div>
        </div>

        
    </div>
</section>
<Footer/>
</div>
  )
}

export default Aboutus