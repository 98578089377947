import React from 'react';
import NavbarService from '../components/NavbarService';
import NavbarServiceDashboard from '../components/NavbarServiceDashboard';
import ServiceProviderTop from '../components/ServiceProviderDashboard/ServiceProviderTop';
import ServiceProviderMid from '../components/ServiceProviderDashboard/ServiceProviderMid';
import ServiceProviderBottom from '../components/ServiceProviderDashboard/ServiceProviderBottom';

const ServiceDashboardmain = () => {
  return (
    <div className='bg-[#F5F9FF]'>
      {/* <NavbarService /> */}
      <NavbarServiceDashboard />

      <div>
        <ServiceProviderTop />
        <ServiceProviderMid />
        <ServiceProviderBottom />
      </div>


    </div>
  )
}

export default ServiceDashboardmain;