import React from 'react'

const Searchbar3 = ({ handleEvent }) => {
    return (
        <div>
            <div class="flex items-stretch sm:w-full justify-center h-[52px] sm:h-[50px] mb-3 bg-[#1F2A37]  rounded-full">
                <input
                    class=" px-3 pl-9 ml-1 h-content sm:pl-4 w-[270px] lg:[80%] md:w-[50%] sm:w-[75%]   rounded-l-full text-sm text-gray-300 bg-gray-800 border-[#374151] border-r-2 border-l-0 border-t-0 border-b-0 border-gray-600 focus:border-gray-600 focus:ring-gray-800  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Discover Services"
                    type="text"
                    name="member[email]"
                    id="member_email"
                    required=""
                />

                <input
                    class=" px-3  ml-1 sm:pl-4 w-[170px] lg:[80%] md:w-[50%] sm:w-[75%]  text-sm text-gray-300 bg-gray-800 border-r-2 border-l-0 border-[#374151] border-t-0 border-b-0 border-gray-600 focus:border-gray-600 focus:ring-gray-800  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Location"
                    type="text"
                    name="member[email]"
                    id="member_email"
                    required=""
                />

                <input
                    class=" px-3  ml-1 sm:pl-4 w-[170px] lg:[80%] md:w-[50%] sm:w-[75%]   text-sm text-gray-300 bg-gray-800 border-r-2 border-l-0 border-[#374151] border-t-0 border-b-0 border-gray-600 focus:border-gray-600 focus:ring-gray-800  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Start-time"
                    type="text"
                    name="member[email]"
                    id="member_email"
                    required=""
                />

                <input
                    class="px-3  ml-1 sm:pl-4 w-[170px] lg:[80%] md:w-[50%] sm:w-[75%]   text-sm text-gray-300 bg-gray-800 border-r-0 border-l-0 border-[#374151] border-t-0 border-b-0 border-gray-600 focus:border-gray-600 focus:ring-gray-800  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 "
                    placeholder="End-time"
                    type="text"
                    name="member[email]"
                    id="member_email"
                    required=""
                />
                <a
                    onClick={handleEvent}
                    class="py-2 px-3 my-2 mr-3 sm:px-2 sm:py-1 text-sm sm:text-[10px] font-medium text-center text-white rounded-full cursor-pointer  bg-[#111928] hover:bg-gray-600 focus:ring-4 focus:ring-gray-800 dark:bg-gray-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 flex justify-center items-center"
                    name="member_submit"
                    id="member_submit"
                >
                    Search
                </a>
            </div>
        </div>
    )
}

export default Searchbar3