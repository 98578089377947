import img1 from '../svg/unitedstates/img1.jpg'
import img2 from '../svg/unitedstates/img2.jpg'
import img6 from '../svg/unitedstates/img6.jpg'
import img8 from '../svg/unitedstates/img8.jpg'
import img13 from '../svg/unitedstates/img13.jpg'
import img11 from '../svg/unitedstates/img11.jpg'
import img15 from '../svg/unitedstates/img15.jpg'
import img4 from '../svg/unitedstates/img4.jpg'


export const thingspdata = [
    {
        img: img1,
        name: "Los Angeles"
    },
    {
        img: img6,
        name: "Miami"
    },
    {
        img: img8,
        name: "New York"
    },
    {
        img: img2,
        name: "Chicago"
    },
    {
        img: img13,
        name: "Toronto"
    },
    {
        img: img11,
        name: "Tulum"
    },
    {
        img: img4,
        name: "Dallas"
    },
    {
        img: img15,
        name: "Paris"
    },
]